import { createContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import clienteAxios from "../config/clienteAxios";
import { products as myProducts } from "../assets/fake/products";

const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [productsLinea, setProductsLinea] = useState([]);
  const [productsEspecial, setProductsEspecial] = useState([]);
  const [resetFlag, setResetFlag] = useState();
  const [product, setProduct] = useState({});
  const [subProduct, setSubProduct] = useState({});
  const [fetchError, setFetchError] = useState(false);

  const navigate = useNavigate();

  const getDetalleProductoById = async (title) => {
    try {
      const { data } = await clienteAxios.post(`/subproduct/getbyparams`, title);
      setSubProduct(data.subproductById[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getProductById = async (title) => {
    try {
      const { data } = await clienteAxios.post(`/product/getbyparams`, title);
      setProduct(data.productById);
    } catch (error) {
      setFetchError(true);
      console.log(error);
    }
  };

  const getAllProducts = async () => {
    try {
      return myProducts;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts().then((res) => {
      setProducts(
        res?.sort(
          (a, b) =>
            a.position?.find((obj) => obj)?.product - b.position?.find((obj) => obj)?.product
        )
      );
    });
  }, []);

  useEffect(() => {
    setProductsLinea(
      products?.filter((prod) => prod?.deleted !== true && prod?.type === "productoLinea")
    );

    setProductsEspecial(
      products?.filter((prod) => prod?.deleted !== true && prod?.type === "productoEspecial")
    );
  }, [products]);

  const getProductsByBody = async (title, _id) => {
    try {
      const { data } = await clienteAxios.post("/product/getbybody", title, _id);
      setProduct(data.productsByBody[0]);
    } catch (error) {
      toast.error(error);
      navigate("/error404");
      window.location.reload();
    }
  };

  const handleVisibility = (product) => {
    setProducts(
      products.map((item) => {
        if (item._id === product._id) {
          if (item.visible) {
            item.visible = false;
          } else {
            item.visible = true;
          }
        }
        return item;
      })
    );
  };

  const handlePublishButton = (id) => {
    const productsEdit = products.map((product) => {
      if (product._id === id) {
        return { ...product, publish: true };
      }
      return product;
    });
    setProducts(productsEdit);
  };

  const deleteProduct = async (id) => {
    const productEdit = products?.map((producto) =>
      producto._id === id ? { ...producto, deleted: true } : producto
    );
    try {
      await Promise.all(productEdit.map((item) => clienteAxios.put("/product/update", item)));
    } catch (error) {
      toast.error("error");
    }
    setProducts(productEdit);
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        productsLinea,
        productsEspecial,
        resetFlag,
        setResetFlag,
        setProducts,
        getProductById,
        getDetalleProductoById,
        getProductsByBody,
        getAllProducts,
        handleVisibility,
        handlePublishButton,
        product,
        setProduct,
        deleteProduct,
        subProduct,
        fetchError
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export { ProductProvider };

export default ProductContext;
