import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isVideo } from '../../utilities/format'

const ViewerGallery = ({
  clickedImg,
  handleRotationRight,
  handleRotationLeft,
  setClickedImg,
  setCurrentindex,
  setActiveModal,
  currentindex,
  images
}) => {

  const handleNewImage = (item, index) => {
    setClickedImg(item)
    setCurrentindex(index)
  }

  const prevIndices = [
    (currentindex - 2 + images.length) % images.length,
    (currentindex - 1 + images.length) % images.length
  ];

  const nextIndices = [
    (currentindex + 1) % images.length,
    (currentindex + 2) % images.length
  ];
  const handleClick = (e) => {
    if (e.target.classList.contains('dismiss')) {
      setActiveModal(false)
    }
  }

  useEffect(() => {

  }, [currentindex])

  const currentImageTitle = images[currentindex]?.title;

  return (
    <div className="overlay dismiss">
      <div
        className="container-main-modal-img"
      >
        <div className='button-close-container'>
          <button type="button" className='button-close' onClick={handleClick}>
            <span className="dismiss">X</span>
          </button>
        </div>

        {isVideo(clickedImg.thumbnail) ? (
          <video
            src={clickedImg.src}
            alt="Video"
            muted
            controls
          />
        ) : (
          <img
            src={`${clickedImg.src}`}
            alt="bigger_picture"
          />
        )}
        <div className='border-image'>
          <p>{currentImageTitle}</p>
          <p>{currentindex + 1} de {images.length}</p>
        </div>


        <div className="overlay-arrows_left">
          <button type="button" onClick={handleRotationLeft}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="overlay-arrows_right">
          <button type="button" onClick={handleRotationRight}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="preview-container">
        <div className="prev-images">
          {prevIndices.map((index) => (
            isVideo(images[index]?.thumbnail) ? (
              <video
                key={index}
                src={images[index]?.src}
                alt={`PreviousImage ${index}`}
                onClick={() => handleNewImage(images[index], index)}
                muted
                controls
              />) : (
              <img
                key={index}
                src={images[index]?.src}
                alt={`PreviousImage ${index}`}
                onClick={() => handleNewImage(images[index], index)}
              />
            )
          ))}
        </div>

        <div className="current-image">
          {isVideo(clickedImg.thumbnail) ? (
            <video
              src={clickedImg.src}
              alt="Video"
              muted
              controls
            />
          ) : (
            <img
              src={`${clickedImg.src}`}
              alt="bigger_picture"
            />
          )}
        </div>

        <div className="next-images">
          {nextIndices.map((index) => (
            isVideo(images[index]?.thumbnail) ? (
              <video
                key={index}
                src={images[index]?.src}
                alt={`PreviousImage ${index}`}
                onClick={() => handleNewImage(images[index], index)}
                muted
                controls
              />) : (
              <img
                key={index}
                src={images[index]?.src}
                alt={`PreviousImage ${index}`}
                onClick={() => handleNewImage(images[index], index)}
              />
            )
          ))}
        </div>
      </div>
    </div>
  )
}

ViewerGallery.propTypes = {
  clickedImg: PropTypes.object.isRequired,
  handleRotationRight: PropTypes.func.isRequired,
  handleRotationLeft: PropTypes.func.isRequired,
  setClickedImg: PropTypes.func.isRequired,
  setCurrentindex: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  currentindex: PropTypes.number.isRequired,
  images: PropTypes.array.isRequired,
}

export default ViewerGallery
