import React, { useState, useEffect } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BasicModal from "./basicModal/BasicModal";
import EditProductsForm from "./forms/EditProductsForm";
import DeleteProductsForm from "./forms/DeleteProductsForm";
import clienteAxios from "../config/clienteAxios";

function ProductsTable() {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    setProducts([]);
    clienteAxios
      .get("/products")
      .then(({ data }) => {
        setProducts(data?.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [reload]);

  const openModal = (content, title) => {
    setShowModal(true);
    setContentModal(content);
    setModalTitle(title);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="container-products-table">
      <div className="title-section">
        <div className="title-section-add">
          <HelpOutlineIcon sx={{ mr: 1 }} />
          <h1>Listado de Productos</h1>
        </div>
        <button
          type="button"
          onClick={() =>
            openModal(
              <EditProductsForm
                handleCloseModal={handleCloseModal}
                reload={reload}
                setReload={setReload}
              />,
              "Nuevo Producto"
            )
          }
        >
          <AddIcon />
        </button>
      </div>
      <div className="responsive-table SliderForm_table_container">
        <table className="products-table">
          <thead>
            <tr>
              <th>Nº</th>
              <th>
                <span>Nombre Producto</span>
              </th>
              <th></th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product._id}>
                <td>{index + 1}</td>
                <td>
                  <span>{product.product}</span>
                </td>
                <td></td>
                <td>
                  <div className="actions-buttons">
                    <button
                      type="button"
                      onClick={() =>
                        openModal(
                          <EditProductsForm
                            editProduct={product}
                            handleCloseModal={handleCloseModal}
                            reload={reload}
                            setReload={setReload}
                          />,
                          "Editar Producto"
                        )
                      }
                    >
                      <EditOutlinedIcon />
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        openModal(
                          <DeleteProductsForm
                            product={product}
                            handleCloseModal={handleCloseModal}
                            reload={reload}
                            setReload={setReload}
                          />,
                          "Eliminar Producto"
                        )
                      }
                    >
                      <DeleteOutlineOutlinedIcon />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <BasicModal showModal={showModal} handleCloseModal={handleCloseModal} modalTitle={modalTitle}>
        {contentModal}
      </BasicModal>
    </div>
  );
}

export default ProductsTable;
