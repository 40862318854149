import { useState } from "react";
import toast from "react-hot-toast";

const useForm = (initialState) => {
  const [values, setValues] = useState(initialState);
  const [categoria, setCategoria] = useState("");
  const [emailError, setEmailError] = useState(true);
  const [passwordError, setPasswordError] = useState(true);
  const [errorEmailInput, setErrorEmailInput] = useState(false);
  const [errorPasswordInput, setErrorPasswordInput] = useState(false);
  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = ({ target }) => {
    switch (target.name) {
      case "email":
        if (
          !(
            typeof target.value !== "undefined" &&
            target.value !== null &&
            target.value !== "" &&
            target.value.match(/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i)
          )
        ) {
          setEmailError(true);
          setErrorEmailInput(true);
        } else {
          setEmailError(false);
          setErrorEmailInput(false);
        }
        break;
      case "password":
        if (
          !(
            typeof target.value !== "undefined" &&
            target.value !== null &&
            target.value !== "" &&
            target.value.length >= 8
          )
        ) {
          setPasswordError(true);
          setErrorPasswordInput(true);
        } else {
          setPasswordError(false);
          setErrorPasswordInput(false);
        }
        break;
      default:
    }
    setValues({
      ...values,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value
    });
  };
  const handleInputChangeProduct = ({ target }) => {
    let galleryEnabled;
    let faqsEnabled;
    let instalationStepsEnabled;

    switch (target.name) {
      case "galleryEnabled":
        galleryEnabled = target.checked;
        break;
      case "faqsEnabled":
        faqsEnabled = target.checked;
        break;
      case "instalationStepsEnabled":
        instalationStepsEnabled = target.checked;
        break;
      default:
    }
    setValues({
      ...values,
      [target.name]: target.value,
      galleryEnabled: galleryEnabled,
      faqsEnabled: faqsEnabled,
      instalationStepsEnabled: instalationStepsEnabled
    });
  };

  const handleFileChange = ({ target }) => {
    const reader = new FileReader();
    target.files && reader.readAsDataURL(target.files[0]);
    reader.onload = (e) => {
      const file = target.files[0];
      if (file.type.startsWith('image/')) {
        let image = new Image();
        image.src = e.target.result;

        image.onload = function () {
          const width = this.width;

          if (width <= 2048) {
            setValues({
              ...values,
              [target.name]: [...target.files]
            });
          } else {
            toast.error("La imagen no debe superar los 2048px de ancho");
            setValues({
              ...values,
              [target.name]: [...target.files]
            });
          }
        };
      } else if (file.type.startsWith('video/')) {
        setValues({
          ...values,
          [target.name]: [...target.files]
        });
      } else {
        toast.error("Formato de archivo no compatible")
      }

    };
  };

  const handleSectionSelectChange = (event) => {
    setCategoria({ title: event.value, state: true });
    setValues({
      ...values,
      section: event.value
    });
  };
  const handleCategorySelectChange = (event) => {
    setCategoria({ title: event.value, state: true });
    setValues({
      ...values,
      category: event.value
    });
  };

  const handleSelectChange = (event, setter) => {
    setValues({
      ...values,
      [setter]: event.value
    });
  };

  const handleTagSelectchange = (event) => {
    const tags = event.map((item) => {
      return item.value;
    });
    setValues({
      ...values,
      tags: tags
    });
  };

  const handleImageUrlChange = ({ target }) => {
    setValues({
      ...values,
      imagesUrl: [target.src]
    });
  };

  const handleRadioChange = ({ target }) => {
    setValues({
      ...values,
      align: target.value
    });
  };

  return {
    values,
    setValues,
    categoria,
    setCategoria,
    handleInputChange,
    handleInputChangeProduct,
    handleFileChange,
    handleSectionSelectChange,
    handleTagSelectchange,
    handleImageUrlChange,
    handleCategorySelectChange,
    handleSelectChange,
    handleRadioChange,
    errorEmailInput,
    errorPasswordInput,
    emailError,
    passwordError,
    reset
  };
};

export default useForm;
