export const images = [
  {
    src: "/assets/gallery-01.jpg",
    url: "/assets/gallery-01.jpg",
    thumbnail: "/assets/gallery-01.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-01",
    id: "6453cc39fc6fa65927bdd86f",
    title: "gallery-01",
    description: "gallery-01",
    alt: "gallery-01",
    category: "undefined",
    product: "undefined",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: [],
    isSelected: false
  },
  {
    src: "/assets/gallery-02.jpg",
    url: "/assets/gallery-02.jpg",
    thumbnail: "/assets/gallery-02.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-02",
    id: "64243e916b8f2119a245286b",
    title: "gallery-02",
    description: "gallery-02",
    alt: "gallery-02",
    category: "undefined",
    product: "undefined",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: [],
    isSelected: false
  },
  {
    src: "/assets/gallery-03.jpg",
    url: "/assets/gallery-03.jpg",
    thumbnail: "/assets/gallery-03.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-03",
    id: "63b2ecbd6c384c93fc33ab88",
    title: "gallery-03",
    description: "gallery-03",
    alt: "gallery-03",
    category: "undefined",
    product: "undefined",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: [],
    isSelected: false
  },
  {
    src: "/assets/gallery-04.jpg",
    url: "/assets/gallery-04.jpg",
    thumbnail: "/assets/gallery-04.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-04",
    id: "63b2ec876c384c93fc33ab86",
    title: "gallery-04",
    description: "gallery-04",
    alt: "gallery-04",
    category: "undefined",
    product: "undefined",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: [],
    isSelected: false
  },
  {
    src: "/assets/gallery-05.jpg",
    url: "/assets/gallery-05.jpg",
    thumbnail: "/assets/gallery-05.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-05",
    id: "637e02130031279b2466dcba",
    title: "gallery-05",
    description: "gallery-05",
    alt: "gallery-05",
    category: "undefined",
    product: "VIGAS",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: [],
    isSelected: false
  },
  {
    src: "/assets/gallery-06.jpg",
    url: "/assets/gallery-06.jpg",
    thumbnail: "/assets/gallery-06.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-06",
    id: "637e01b40031279b2466dcb4",
    title: "gallery-06",
    description: "gallery-06",
    alt: "gallery-06",
    category: "undefined",
    product: "TRIBUNAS",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: ["Íconos Azules"],
    isSelected: false
  },
  {
    src: "/assets/gallery-07.jpg",
    url: "/assets/gallery-07.jpg",
    thumbnail: "/assets/gallery-07.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-07",
    id: "637e018d0031279b2466dcb2",
    title: "gallery-07",
    description: "gallery-07",
    alt: "gallery-07",
    category: "undefined",
    product: "PASARELAS",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: [],
    isSelected: false
  },
  {
    src: "/assets/gallery-08.jpg",
    url: "/assets/gallery-08.jpg",
    thumbnail: "/assets/gallery-08.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-08",
    id: "637e01700031279b2466dcb0",
    title: "gallery-08",
    description: "gallery-08",
    alt: "gallery-08",
    category: "undefined",
    product: "PASARELAS",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: ["Íconos Azules"],
    isSelected: false
  },
  {
    src: "/assets/gallery-09.jpg",
    url: "/assets/gallery-09.jpg",
    thumbnail: "/assets/gallery-09.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-09",
    id: "637e00fe0031279b2466dcac",
    title: "gallery-09",
    description: "gallery-09",
    alt: "gallery-09",
    category: "undefined",
    product: "OTROS",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: ["Íconos Azules"],
    isSelected: false
  },
  {
    src: "/assets/gallery-10.jpg",
    url: "/assets/gallery-10.jpg",
    thumbnail: "/assets/gallery-10.jpg",
    thumbnailWidth: 320,
    thumbnailHeight: 212,
    caption: "gallery-10",
    id: "637e00e20031279b2466dcaa",
    title: "gallery-10",
    description: "gallery-10",
    alt: "gallery-10",
    category: "undefined",
    product: "ESTRUCTURAS",
    state: true,
    tags: [
      {
        value: "undefined",
        title: "undefined"
      }
    ],
    album: [],
    isSelected: false
  }
];
