import React, { useEffect, useState } from 'react';
import axios from 'axios';
import useForm from '../../hooks/useForm';
import { WithContext as ReactTags } from 'react-tag-input';
import toast from 'react-hot-toast';
import useImage from '../../hooks/useImage';

const RecursoGallery = ({ selectedImage, allImages, closeForm }) => {
  const { setImagesAct, imagesAct } = useImage();
  const { values, setValues, handleInputChange, handleFileChange, reset } =
    useForm({
      title: '',
      description: '',
      alt: '',
      category: '',
      product: '',
      tags: [],
      files: [],
      state: true,
    });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyFormData = new FormData();
    bodyFormData.append('filename', values.title);
    bodyFormData.append('description', values.description);
    bodyFormData.append('alt', values.alt);
    if (selectedImage) {
      bodyFormData.append('_id', values.id);
    }
    bodyFormData.append('category', values.category);
    bodyFormData.append('product', values.product);
    bodyFormData.append('tags', values.tags);
    bodyFormData.append('state', true);
    values?.files.map((item) => bodyFormData.append('file', item));
    try {
      if (selectedImage !== null) {
        const { data } = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_BACKEND_API}/image/editFile/${values.id}`,
          data: {
            title: values.title,
            description: values.description,
            alt: values.alt,
            category: values.category,
            product: values.product,
            tags: values.tags,
            state: true,
          },
          headers: {
            Authorization: JSON.parse(localStorage.getItem('Usuario'))?.token,
          },
        });
        toast.success('Recurso actualizado');
        setImagesAct(!imagesAct);
      } else {
        const { data } = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_BACKEND_API}/images`,
          data: bodyFormData,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: JSON.parse(localStorage.getItem('Usuario'))?.token,
          },
          withCredentials: true,
        });
        toast.success('Recurso creado');
        if (closeForm) closeForm();
        setImagesAct(!imagesAct);
        reset();
        setValues({
          files: [],
        });
        setTags([]);
        selectedImage = null;
      }
    } catch (error) {
      console.error(error);
      toast.error('Hubo un error en el envío de formulario');
    }
  };

  const [tags, setTags] = useState(
    selectedImage
      ? selectedImage.tags.map((item) => {
          return { id: item.value, text: item.value };
        })
      : []
  );
  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  useEffect(() => {
    const map = tags.map((item) => {
      return item.text;
    });
    setValues({
      ...values,
      tags: map,
    });
  }, [tags]);

  useEffect(() => {
    setValues({
      ...selectedImage,
      id: selectedImage?.id,
      title: selectedImage?.title,
      description: selectedImage?.description,
      alt: selectedImage?.alt,
      category: selectedImage?.category,
      product: selectedImage?.product,
      state: true,
      tags: selectedImage?.tags.map((item) => {
        return item.title;
      }),
      files: [],
    });
  }, [selectedImage, setValues]);

  const handleTagClick = (index) => {
    console.error('The tag at index ' + index + ' was clicked');
  };

  const [filePreviews, setFilePreviews] = useState([]);

  useEffect(() => {
    if (!values.files.length) {
      setFilePreviews([]);
      return;
    }

    const previews = values.files.map((file) => {
      const objectUrl = URL.createObjectURL(file);

      if (file.type.startsWith('image/')) {
        return (
          <img
            key={file.name}
            src={objectUrl}
            alt=''
            style={{ width: '100%', height: 'auto' }}
          />
        );
      } else {
        return (
          <video
            key={file.name}
            src={objectUrl}
            alt=''
            style={{ width: '100%', height: 'auto' }}
          />
        );
      }
    });

    setFilePreviews(previews);

    return () => {
      previews.forEach((preview) => {
        URL.revokeObjectURL(preview.props.src);
      });
    };
  }, [values.files]);

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className='pgi__sidebar-container-items form-group'>
        {selectedImage ? null : (
          <div className='pgi__thumbpreview pgi__archivo gallery_upload_input'>
            {filePreviews.length > 0 && (
              <div className='file-previews'>
                {filePreviews.map((preview, index) => (
                  <div key={index} className='file-preview'>
                    {preview}
                  </div>
                ))}
              </div>
            )}
            <input
              type='file'
              accept='image/*, video/*'
              name='files'
              id='files'
              onChange={handleFileChange}
            />
          </div>
        )}
        <p className='basePgi__comments'>
          Máximo de 25MB para imágenes y 50MB para videos
        </p>
        <p className='basePgi__comments'>
          Formatos compatibles: .png .jpg .webp .svg .mp4 .webm .mkv
        </p>
        <div className='form-floating'>
          <input
            type='text'
            required
            className='form-control'
            name='title'
            id='title'
            placeholder='...'
            value={values.title}
            onChange={handleInputChange}
            maxLength={140}
            minLength={2}
          />
          <label htmlFor='title'>Título </label>
        </div>
        <div className='form-floating'>
          <textarea
            required
            type='text-area'
            className='form-control'
            name='description'
            id='description'
            rows='4'
            htmlFor='bloque-txt'
            placeholder='hola'
            value={values.description}
            onChange={handleInputChange}
            maxLength={280}
            minLength={2}
          />
          <label htmlFor='description'>Descripción</label>
        </div>
        <div className='form-floating'>
          <textarea
            required
            type='text-area'
            className='form-control'
            name='alt'
            id='alt'
            rows='4'
            htmlFor='bloque-txt'
            placeholder=''
            value={values.alt}
            onChange={handleInputChange}
            maxLength={280}
            minLength={2}
          />
          <label htmlFor='alt'>ALT de la imagen</label>
        </div>
        <div className='form-tags'>
          <ReactTags
            tags={tags}
            placeholder='Tags'
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition='top'
            allowUnique
            allowDragDrop
            autocomplete
            classNames={{ tags: 'pgi__tags' }}
          />
        </div>
        <button className='settings_button-pleno overlay-replica-button'>
          {selectedImage ? 'Guardar Cambios' : 'Cargar'}
        </button>
      </fieldset>
    </form>
  );
};

export default RecursoGallery;
