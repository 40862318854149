import { useState } from "react";
import {
  imageValidator,
  textLengthValidator,
  descriptionValidator
} from "../validation/validators/validators";

const touchErrors = errors =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true
    };
    return acc;
  }, {});

const useProductsValidator = form => {
  const [errors, setErrors] = useState({
    product: {
      dirty: false,
      error: false,
      message: ""
    },
    coverItem: {
      dirty: false,
      error: false,
      message: ""
    },
    description: {
      dirty: false,
      error: false,
      message: ""
    },
    images: {
      dirty: false,
      error: false,
      message: ""
    }
  });

  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { product, coverItem, description } = form;

    if (nextErrors.product.dirty && (field ? field === "product" : true)) {
      const productMessage = textLengthValidator(product, 80);
      nextErrors.product.error = !!productMessage;
      nextErrors.product.message = productMessage;
      if (productMessage) isValid = false;
    }

    if (nextErrors.coverItem.dirty && (field ? field === "coverItem" : true)) {
      const coverItemMessage = imageValidator(coverItem);
      nextErrors.coverItem.error = !!coverItemMessage;
      nextErrors.coverItem.message = coverItemMessage;
      if (coverItemMessage) isValid = false;
    }

    if (nextErrors.description.dirty && (field ? field === "description" : true)) {
      const descriptionMessage = descriptionValidator(description);
      nextErrors.description.error = !!descriptionMessage;
      nextErrors.description.message = descriptionMessage;
      if (descriptionMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors
    };
  };

  const onBlurField = e => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError?.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true
      }
    };

    validateForm({
      form,
      field,
      errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField
  };
};

export default useProductsValidator;
