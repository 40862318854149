import { createContext, useState } from "react";
import clienteAxios from "../config/clienteAxios";
import toast from "react-hot-toast";
import { images as myImages } from "../assets/fake/images";

const ImageContext = createContext();

const ImageProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [imagesAct, setImagesAct] = useState(false);

  const getImagesByBody = async (body) => {
    try {
      const { data } = await clienteAxios.get('/images')
      return data.data.reverse();
    } catch (error) {
      console.log(error);
    }
  };

  const addAlbumToImages = async (event, multiSelect, closeModal) => {
    event.preventDefault();

    const value = document.querySelector("#newAlbum").value;
    try {
      multiSelect.map(async (id) => {
        const { data } = await clienteAxios.put(`/images/${id.id}`, {
          album: [...id.album, value]
        });
        setImagesAct(!imagesAct);
        toast.success('Imagen añadida al album');
        if (closeModal) closeModal();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addExistingAlbumToImages = async (id, multiSelect, closeModal) => {
    try {
      multiSelect.map(async (item) => {
        const { data } = await clienteAxios.put(`/images/${item.id}`, {
          album: [...item.album, id]
        });
        toast.success('Imagen añadida al album');
        if (closeModal) closeModal();
        setImagesAct(!imagesAct);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleStateToDelete = (event, multiSelect, closeModal) => {
    event.preventDefault()
    try {
      multiSelect.map(async (id) => {
        const { data } = await clienteAxios.delete(`/images/${id.id}`)
        toast.success('Imagen eliminada')
        setImagesAct(!imagesAct)
        if (closeModal) closeModal()
      })
    } catch (error) {
      toast.error('Error al eliminar imagen')
    }
  }

  const handleAlbumDelete = (id, multiSelect, closeModal) => {
    try {
      multiSelect.map(async (item) => {
        const { data } = await clienteAxios.put(`/images/${item.id}`, {
          album: item.album.filter((item) => item !== id)
        });
        setImagesAct(!imagesAct);
        toast.success(data.message);
        if (closeModal) closeModal();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const eliminarCategoriaDuplicados = (arr) => {
    const categorias = images
      .filter((item) => item.category !== "undefined")
      .map((item) => {
        return item.category;
      });
    return [...new Set(categorias)].map((item) => {
      return { label: item, value: item };
    });
  };

  return (
    <ImageContext.Provider
      value={{
        images,
        setImages,
        getImagesByBody,
        addAlbumToImages,
        addExistingAlbumToImages,
        handleStateToDelete,
        eliminarCategoriaDuplicados,
        handleAlbumDelete,
        filteredImages,
        setFilteredImages,
        setImagesAct,
        imagesAct
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};

export { ImageProvider };

export default ImageContext;
