import React, { useState } from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  AddPhotoAlternateOutlined,
  Factory,
  HomeOutlined,
  Inventory2Outlined
} from "@mui/icons-material";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    setOpen(!open);
  };

  return (
    <div className={open ? `navBarPgi abierto` : `navBarPgi cerrado`}>
      <button className="menuPgibutton" onClick={handleClick}>
        <MenuOpenIcon />
      </button>
      <nav >
        {open ? <ul>
          <li className="itemContent">
            <a href="/edit-home">
              <HomeOutlined />
              Home
            </a>
          </li>
          <li className="itemContent">
            <a id="edit-products" href="/edit-products">
              <Inventory2Outlined />
              Productos
            </a>
          </li>
          <li className="itemContent">
            <a id="works" href="/edit-works">
              <Factory />
              Obras
            </a>
          </li>
          <hr />
          <li className="itemContent">
            <a id="edit-galeria" href="/gallery">
              <AddPhotoAlternateOutlined />
              Galería
            </a>
          </li>
        </ul> : ""}
      </nav>
    </div>
  );
};

export default Sidebar;
