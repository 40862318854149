import React from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import WorksTable from "../../components/WorksTable";
import MainForm from "../../components/works/MainForm";

function Index() {
  
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="main-edit-works">
        <MainForm/>
        <div className="container-table-works-list">
          <WorksTable />
        </div>
      </div>
    </>
  );
}

export default Index;
