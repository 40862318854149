import axios from 'axios'
import { UNAUTHORIZED_STATUS_CODE } from '../utilities/constants';
import { logoutService } from '../services/authService';

const clienteAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_API}`,
  withCredentials: true
})

clienteAxios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === UNAUTHORIZED_STATUS_CODE) {
    localStorage.setItem('isAuth', JSON.stringify(false));
    logoutService();
    window.location = '/log'
  }
  return Promise.reject(error);
});

export default clienteAxios
