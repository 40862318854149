const SUCCESS_STATUS_CODE = 200
const UNAUTHORIZED_STATUS_CODE = 401
const PRODUCT_SECTION = 'productos';
const WORKS_SECTION = 'obras';

export {
    SUCCESS_STATUS_CODE,
    UNAUTHORIZED_STATUS_CODE,
    PRODUCT_SECTION,
    WORKS_SECTION
}
