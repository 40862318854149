export const products = [
  { id: 1, productName: "Lorem ipsum dolor", category: "Interior", imageUrl: "" },
  { id: 2, productName: "Consectetur adipiscing elit", category: "Theaters", imageUrl: "" },
  { id: 3, productName: "Risus commodo viverra", category: "Residential", imageUrl: "" },
  { id: 4, productName: "Mattis molestie a iaculis", category: "Interior", imageUrl: "" },
  { id: 5, productName: "Ut consequat semper", category: "Theaters", imageUrl: "" },
  { id: 6, productName: "Ornare lectus", category: "Residential", imageUrl: "" },
  { id: 7, productName: "In dictum non consectetur", category: "Interior", imageUrl: "" },
  { id: 8, productName: "Magna etiam tempor", category: "Theaters", imageUrl: "" }
];
