import React from "react";

function Cards() {
  return (
    <section className="container-card-home">
      <div className="row our_products">
        <div className="our_products_container">
          <a className="our_products_elements home-back-home" href="/edit-home">
            <div className="our_products_text--container">
              <h6 className="our_products_title"> Editar Home </h6>
            </div>
          </a>
          <a className="our_products_elements home-back-product" href="/edit-products">
            <div className="our_products_text--container">
              <h6 className="our_products_title"> Editar Productos </h6>
            </div>
          </a>
        </div>
        <div className="our_products_container">
          <a className="our_products_elements home-back-works" href="/edit-works">
            <div className="our_products_text--container">
              <h6 className="our_products_title"> Editar Obras </h6>
            </div>
          </a>
          <a className="our_products_elements home-back-gallery" href="/gallery">
            <div className="our_products_text--container">
              <h6 className="our_products_title"> Galeria </h6>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Cards;
