import { useState } from "react";
import {
  textLengthValidator,
  descriptionValidator,
} from "../validation/validators/validators";

const touchErrors = errors =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true
    };
    return acc;
  }, {});

const useWorksValidator = form => {
  const [errors, setErrors] = useState({
    work: {
      dirty: false,
      error: false,
      message: ""
    },
    description: {
      dirty: false,
      error: false,
      message: ""
    }
  });

  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { work, description } = form;

    if (nextErrors?.work?.dirty && (field ? field === "work" : true)) {
      const workMessage = textLengthValidator(work, 80);
      nextErrors.work.error = !!workMessage;
      nextErrors.work.message = workMessage;
      if (workMessage) isValid = false;
    }

    if (nextErrors?.description?.dirty && (field ? field === "description" : true)) {
      const descriptionMessage = descriptionValidator(description);
      nextErrors.description.error = !!descriptionMessage;
      nextErrors.description.message = descriptionMessage;
      if (descriptionMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors
    };
  };

  const onBlurField = e => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError?.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true
      }
    };

    validateForm({
      form,
      field,
      errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField
  };
};

export default useWorksValidator;
