import React, { useState, useEffect } from 'react';
import LogoVidral from '../../../assets/img/Vidrial-Logo.png';
import ChangePasswordForm from '../../../components/recoveryPassword/ChangePasswordForm/ChangePasswordForm'
import { verifyUserTokenService } from '../../../services/recoveryPasswordService';
import toast from 'react-hot-toast';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router';
import { changePasswordService } from '../../../services/recoveryPasswordService';

function ChangePassword() {
  const queryParams = new URLSearchParams(window.location.search);
  const resetToken = queryParams.get('resetToken');
  const [loading, setLoading] = useState(true);
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await verifyUserTokenService(resetToken);
        setUserEmail(response?.data?.data);
      } catch (error) {
        toast.error('Recupero de contraseña no permitido');
        return navigate('/log');
      }
      finally {
        setLoading(false);
      }
    };
    verifyToken();
  }, [resetToken, navigate]);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    const form = new FormData(e.currentTarget)
    const formData = Object.fromEntries(form.entries())
    const password = formData.password;
    const passwordRepeated = formData.passwordRepeated;
    if (password !== passwordRepeated) {
      setLoading(false);
      return toast.error('Las contraseñas no coinciden')
    }
    if (password.length < 8) {
      setLoading(false);
      return toast.error('La contraseña debe tener al menos 8 caracteres')
    }
    try {
      await changePasswordService(userEmail, password);
      toast.success('Contraseña actualizada con exito!');
      setLoading(false);
      navigate('/log');
    } catch (error) {
      toast.success(`Ocurrio un error: ${error}`);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='main-log'>
      {
        loading ?
          <Spinner animation="border" role="status" variant="light">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          :
          <form onSubmit={handleChangePassword}>
            <img src={LogoVidral} alt="" className='logo-vidrial' />
            <h5 className='titleInfo'>Cambio de contraseña de usuario <b>{userEmail}</b></h5>
            <ChangePasswordForm />
            <div className='buttonLinkContainer'>
              <button className='btn-enter' type='submit' disabled={loading}>Cambiar</button>
            </div>
          </form>
      }
    </div>
  )
}

export default ChangePassword