import { useState } from "react";
import { textValidator, imageValidator } from "../validation/validators/validators";

const touchErrors = errors =>
  Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true
    };
    return acc;
  }, {});

const usePageProductsValidator = form => {
  const [errors, setErrors] = useState({
    title: {
      dirty: false,
      error: false,
      message: ""
    },
    coverItem: {
      dirty: false,
      error: false,
      message: ""
    },
    body: {
      dirty: false,
      error: false,
      message: ""
    }
  });

  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;

    let nextErrors = JSON.parse(JSON.stringify(errors));

    if (forceTouchErrors) {
      nextErrors = touchErrors(errors);
    }

    const { title, coverItem, body } = form;

    if (nextErrors.title.dirty && (field ? field === "title" : true)) {
      const titleMessage = textValidator(title, form);
      nextErrors.title.error = !!titleMessage;
      nextErrors.title.message = titleMessage;
      if (titleMessage) isValid = false;
    }

    if (nextErrors.coverItem.dirty && (field ? field === "coverItem" : true)) {
      const coverItemMessage = imageValidator(coverItem);
      nextErrors.coverItem.error = !!coverItemMessage;
      nextErrors.coverItem.message = coverItemMessage;
      if (coverItemMessage) isValid = false;
    }

    if (nextErrors.body.dirty && (field ? field === "body" : true)) {
      const bodyMessage = textValidator(body);
      nextErrors.body.error = !!bodyMessage;
      nextErrors.body.message = bodyMessage;
      if (bodyMessage) isValid = false;
    }

    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors
    };
  };

  const onBlurField = e => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError?.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true
      }
    };

    validateForm({
      form,
      field,
      errors: updatedErrors
    });
  };

  return {
    errors,
    validateForm,
    onBlurField
  };
};

export default usePageProductsValidator;
