import React, { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useBasicForm from '../../../hooks/useBasicForm';
import SaveIcon from '@mui/icons-material/Save';
import clienteAxios from '../../../config/clienteAxios';
import Gallery from '../../Gallery/Index';
import { WallpaperOutlined } from '@mui/icons-material';
import ImagesModal from '../../../../src/components/imagesModal/ImagesModal';
import toast from 'react-hot-toast';
import WrapTextIcon from '@mui/icons-material/WrapText';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const FormA = ({ sec }) => {
  const { _id, section, category, description, title, subtitle, link } = sec;
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [modalTitle, setModalTitle] = useState('');

  const openModal = (content, title) => {
    setShowModal(true);
    setContentModal(content);
    setModalTitle(title);
  };

  const { formState, onInputChange, setSpecificField } = useBasicForm({
    category: category ? category : '',
    title: title ? title : '',
    subtitle: subtitle ? subtitle : '',
    description: description ? description : '',
    link: link ? link : '',
  });

  const validateLink = (link) => {
    const urlRegex = /^[a-zA-Z]+$/;
    return urlRegex.test(link);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleImageSelection = (selectedImagePath) => {
    let images;
    if (
      typeof selectedImagePath?.value === 'string' &&
      selectedImagePath?.value
    ) {
      images = [
        `images/${
          selectedImagePath?.value?.split('images/')?.length > 0
            ? selectedImagePath?.value?.split('images/')[1]
            : selectedImagePath?.value
        }`,
      ];
    }
    if (Array.isArray(selectedImagePath?.value) && selectedImagePath?.value) {
      images = selectedImagePath?.value?.map((imagen) => `images/${imagen.id}`);
    }
    setSpecificField({ name: 'images', value: images });
    handleCloseModal();
  };

  const insertTextAtCursor = (textToInsert) => {
    const textarea = document.getElementById('descriptionHome');
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const text = textarea.value;
    const newText = text.substring(0, startPos) + textToInsert + text.substring(endPos);
    setSpecificField({ name: 'description', value: newText });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const modifiedFormState = {
      ...formState,
    };

    if (!validateLink(formState.link)) {
      toast.error('¡La URL no es válida!');
      return;
    }

    clienteAxios
      .put(`/sectionTypeA/${_id}`, modifiedFormState)
      .then(() => {
        toast.success('Cambios guardados con exito');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const TooltipMessage = () => {
    return (
      <Tooltip id="tooltip-top">{'Para colocar salto de linea hacer click en el icono y colocara un texto "<br/>" donde deseas hacer un salto de linea, si deseas quitar el salto de linea quitar "<br/>"'}</Tooltip>
    )
  } 
  return (
    <div className='sec-nosotros'>
      <div className='title-sections'>
        <div className='title-detail'>
          <HelpOutlineIcon sx={{ mr: 1 }} />
          <h1>Sección {section}</h1>
        </div>
        <button onClick={handleSubmit}>
          <SaveIcon />
        </button>
      </div>
      <h3>Título</h3>
      <input
        name='title'
        type='text'
        placeholder='Titulo de ejemplo'
        onChange={onInputChange}
        value={formState.title}
      />
      <div className='aboutUsTitleHome'>
        <h3>Descripción</h3>
        <OverlayTrigger
          placement="top"
          overlay={TooltipMessage()}
        >
          <WrapTextIcon className='iconWrapper' onClick={() => insertTextAtCursor('<br />')} />
        </OverlayTrigger>
      </div>
      <div className='editFormA-textarea custom-textarea'>
        <textarea
          id='descriptionHome'
          name='description'
          type='text'
          placeholder='Subtitulo de ejemplo'
          onChange={onInputChange}
          value={formState.description}
        />
        <div>
          <span></span>
        </div>
      </div>
      <h3>Enlace a...</h3>
      <select name='link' value={formState.link} onChange={onInputChange}>
        <option value='about'>Nosotros</option>
        <option value='products'>Productos</option>
        <option value='works'>Obras</option>
        <option value='contact'>Contacto</option>
      </select>
      <div className='form-field'>
        <label htmlFor=''>Imagen</label>
        <div className='images-section'>
          <button
            type='button'
            onChange={onInputChange}
            className='btn-gallery-picker-bg'
            name='coverItem'
            onClick={() =>
              openModal(
                <>
                  <Gallery
                    editProductsView={true}
                    maxQuantity={2}
                    formState={formState}
                    onInputChange={onInputChange}
                    setSpecificField={handleImageSelection}
                    flagRender={false}
                  />
                </>,
                'Seleccionar imágenes'
              )
            }
          >
            Elegir Imagen
            <WallpaperOutlined />
          </button>
          <p>
            {formState?.coverItem?.value
              ? formState?.coverItem?.value
              : formState?.coverItem}
          </p>
        </div>
      </div>
      <ImagesModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        modalTitle={modalTitle}
      >
        {contentModal}
      </ImagesModal>
    </div>
  );
};

export default FormA;
