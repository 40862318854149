import React from "react";
import LogoVidrial from "../assets/img/logo_color.svg";
import { useNavigate } from 'react-router';
import { logoutService } from "../services/authService";

const Navbar = () => {
  const navigate = useNavigate();

  const cerrarSesion = () =>{
    localStorage.setItem('isAuth', JSON.stringify(false));
    logoutService();
    navigate('/log');
  }

  return (
    <>
      <header className="container-navBar-pgi">
            <a href="/">
              <img src={LogoVidrial} alt="" />
            </a>
        <button type="button" onClick={cerrarSesion}>CERRAR SESIÓN</button>
      </header>
    </>
  );
};

export default Navbar;
