import React, { useEffect } from 'react'
import LoginForm from '../../components/login/LoginForm'
import LogoVidral from '../../assets/img/Vidrial-Logo.png'
import clienteAxios from '../../config/clienteAxios'
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import useForm from '../../hooks/useForm';
import { Link } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const { values, setValues, handleInputChange } = useForm({
    email: "",
    password: "",
    rememberAuth: false,
  });

  useEffect(() => {
    const validateToken = async () => {
      const isAuth = JSON.parse(localStorage.getItem('isAuth'));
      if (isAuth) {
        navigate('/');
      }
      return;
    }
    validateToken();
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    const formChilderns = Object.values(e.currentTarget);
    sendLogin(values, formChilderns);
  }

  const sendLogin = async (userData, formChilderns) => {
    try {
      await clienteAxios.post('/auth/login', userData);
      localStorage.setItem('isAuth', JSON.stringify(true));
      navigate('/');
    } catch (error) {
      toast.error('Contraseña o nombre incorrecto')
      setValues(previous => ({
        ...previous,
        password: '',
      }))
      const inputPassword = formChilderns.find((children) => children.name === 'password')
      inputPassword.value = ''
      localStorage.setItem('isAuth', false);
    }
  }

  return (
    <div className='main-log'>
      <form onSubmit={handleSubmit}>
        <img src={LogoVidral} alt="" className='logo-vidrial' />
        <LoginForm handleInputChange={handleInputChange} />
        <div className='buttonLinkContainer'>
          <button className='btn-enter' type='submit'>Ingresar</button>
          <Link to={'/recovery-password'} className='linkToRecoveryPassword'>¿Olvidaste la contraseña?</Link>
        </div>
      </form>
    </div>
  )
}

export default Login
