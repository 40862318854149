import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import ProductsTable from "../../components/ProductsTable";
import { products } from "../../assets/fake/productsFakeData";
import PageProductsForm from "../../components/forms/PageProductsForm";
import { fetchData } from "../../utilities/fetchData";
import { PRODUCT_SECTION } from "../../utilities/constants";

function Index() {
  const [productPageInfo, setProductPageInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    fetchData(setLoading, setProductPageInfo, `/sectionTypeC`, PRODUCT_SECTION)
  }, [reload]);  

  return  (
    <>
      <Navbar />
      <Sidebar />
      <div className="main-edit-works">
        <div className="container-form-edit-products">
        {loading ? (
            <Skeleton count={2} height={250} width={600} />
          ) : (
            <PageProductsForm
              productPageInfo={productPageInfo}
              setReload={() => setReload(!reload)}
              sectionId={productPageInfo?._id}
            />
          )}
        </div>
        <div className="container-table-products-list">
        {loading ? (
            <Skeleton count={10} />

          ) : (
            <ProductsTable products={products} /> 
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
