import React, { useState } from "react";
import ViewerGallery from "./ViewerGallery";
import useImage from "../../hooks/useImage";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { isVideo } from "../../utilities/format";

const Gallery = ({ onSelectImage, selectedImages, filteredImages }) => {
  const [clickedImg, setClickedImg] = useState(null);
  const [currentindex, setCurrentindex] = useState(null);
  const [activeModal, setActiveModal] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);

  const { images } = useImage();

  const handleClick = (item, index) => {
    setCurrentindex(index);
    setClickedImg(item);
    setActiveModal(!activeModal);
  };

  const handleRotationRight = () => {
    const totalLength = images.length;
    if (currentindex + 1 >= totalLength) {
      setCurrentindex(0);
      const newUrl = images[0];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentindex + 1;
    const newUrl = images.filter(item => images.indexOf(item) === newIndex);
    if (newUrl.length > 0) {
      const newItem = newUrl[0];
      setClickedImg(newItem);
      setCurrentindex(newIndex);
    }
  };

  const handleRotationLeft = () => {
    const totalLength = images.length;
    if (currentindex - 1 >= totalLength) {
      setCurrentindex(0);
      const newUrl = images[0];
      setClickedImg(newUrl);
      return;
    }
    const newIndex = currentindex - 1;
    const newUrl = images.filter(item => images.indexOf(item) === newIndex);
    if (newUrl.length > 0) {
      const newItem = newUrl[0];
      setClickedImg(newItem);
      setCurrentindex(newIndex);
    }
  };

  return (
    <>
      <div className="img-config--container">
        <div className="container-mini-img-scroll">
          {activeModal && (
            <ViewerGallery
              clickedImg={clickedImg}
              currentindex={currentindex}
              images={images}
              handleRotationRight={handleRotationRight}
              handleRotationLeft={handleRotationLeft}
              setActiveModal={setActiveModal}
              setClickedImg={setClickedImg}
              setCurrentindex={setCurrentindex}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center"
        }}
      >
        {images &&
          filteredImages.map((i, index) => (
            <div
              key={i.id}
              style={{
                width: "265px",
                height: "180px",
                margin: "2px",
                background: "#f0f0f0",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                filter: hoveredImage === i.id ? "brightness(85%)" : "none"
              }}
              onMouseEnter={() => setHoveredImage(i.id)}
              onMouseLeave={() => setHoveredImage(null)}
            >
              {(hoveredImage === i.id || selectedImages.some(item => item.id === i.id)) && (
                <div
                  className="checklist-button"
                  onClick={() => onSelectImage(index, i)}
                  style={{
                    backgroundColor: selectedImages.some(item => item.id === i.id)
                      ? "#0d6efd"
                      : "transparent"
                  }}
                >
                  <CheckCircleOutlineIcon style={{ color: "white" }} />
                </div>
              )}
              {isVideo(i.thumbnail) ? (
                <video
                  src={i.src}
                  alt={i.title}
                  muted
                  controls
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                    objectPosition: "center"
                  }}
                  onClick={() => handleClick(i, index)}
                />
              ) : (
                <img
                  src={i.src}
                  alt={i.title}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto",
                    objectFit: "contain",
                    objectPosition: "center"
                  }}
                  onClick={() => handleClick(i, index)}
                />
              )}
            </div>
          ))}
      </div>
    </>
  );
};

export default Gallery;
