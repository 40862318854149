import React, { useState } from "react";
import { WallpaperOutlined } from "@mui/icons-material";
import useBasicForm from "../../hooks/useBasicForm";
import usePageProductsValidator from "../../hooks/usePageProductsValidator";
import toast from "react-hot-toast";
import clienteAxios from "../../config/clienteAxios";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ImagesModal from "../imagesModal/ImagesModal";
import Gallery from "../../pages/Gallery/Index";

function PageProductsForm({ productPageInfo, setReload, sectionId }) {
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const openModal = (content, title) => {
    setShowModal(true);
    setContentModal(content);
    setModalTitle(title);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleImageSelection = (selectedImagePath) => {
    console.log("coverItem - selectedImagePath", selectedImagePath)
    setSpecificField({ name: "coverItem", value: selectedImagePath });
    handleCloseModal();
  };

  const { formState, onInputChange, setSpecificField } = useBasicForm({
    title: productPageInfo ? productPageInfo.title : "",
    coverItem: productPageInfo ? productPageInfo.coverItem : "",
    body: productPageInfo ? productPageInfo.body : "",
    category: "Categoria"
  });

  const { errors, validateForm, onBlurField } = usePageProductsValidator(formState);

  const clearForm = () => {
    setReload(prevReload => !prevReload);
  };

  const handleFormSubmit = async e => {
    e.preventDefault();
    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true
    });

    if (!isValid) return toast.error("Hay campos obligatorios sin completar");

    const url = formState.coverItem.value ? formState.coverItem.value : formState.coverItem

    const pathSegments = url.split('/');
    const id = pathSegments[pathSegments.length - 1];
    const imagePath = `images/${id}`;

    const modifiedFormState = {
      ...formState,
      coverItem: imagePath,
    };

    try {
      await clienteAxios.put(`/sectionTypeC/${sectionId}`, modifiedFormState);

      clearForm();
      toast.success("Se han guardado los cambios con éxito");
    } catch (error) {
      error?.response?.data?.data?.errors?.map(error => console.log(error));
      toast.error("Error al guardar los cambios");
    }

  };

  return (
    <>
      <form className="form main-edit-products" onSubmit={handleFormSubmit}>
        <div className="submit-field main-button">
          <button type="submit" className="btn-edit-products">
            GUARDAR LOS CAMBIOS
          </button>
        </div>

        <div className="sec-page-title">
          <div className="title-sections">
            <HelpOutlineIcon sx={{ mr: 1 }} />
            <h1>Banner Superior</h1>
          </div>

          <div className="form-field">
            <h3>* Título de la página</h3>
            <input
              type="text"
              name="title"
              onChange={onInputChange}
              onBlur={onBlurField}
              value={formState.title}
              className={errors.title.error ? "red-warning" : ""}
            />
            <div className="error-validation">
              {errors.title.dirty && errors.title.error ? (
                <p className="error-validation-message">{errors.title.message}</p>
              ) : null}
            </div>
          </div>

          <div className="form-field">
            <h3>* Imagen destacada</h3>
            <div className="images-section">
              <button
                type="button"
                onBlur={onBlurField}
                onChange={onInputChange}
                name="coverItem"
                className={errors.coverItem.error ? "red-warning btn-gallery-picker-bg" : "btn-gallery-picker-bg"}
                onClick={() =>
                  openModal(
                    <>
                      <Gallery
                        editProductsView={true}
                        maxQuantity={1}
                        formState={formState}
                        onInputChange={onInputChange}
                        setSpecificField={handleImageSelection}
                        flagRender={false}
                      />
                    </>,
                    "Seleccionar imágenes"
                  )
                }
              >
                Elegir fondo
                <WallpaperOutlined />
              </button>
              <p>{formState?.coverItem?.value ? formState?.coverItem?.value : formState?.coverItem}</p>
            </div>
            <div className="error-validation">
              {errors?.coverItem?.dirty && errors?.coverItem?.error ? (
                <p className="error-validation-message">{errors?.coverItem?.message}</p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="sec-page-description">
          <div className="title-sections">
            <HelpOutlineIcon sx={{ mr: 1 }} />
            <h1>Descripción</h1>
          </div>

          <div className="form-field">
            <h3>* Observaciones</h3>
            <textarea
              name="body"
              onChange={onInputChange}
              onBlur={onBlurField}
              value={formState.body}
              className={errors.body.error ? "red-warning" : ""}
            ></textarea>
            <div className="error-validation">
              {errors?.body?.dirty && errors?.body?.error ? (
                <p className="error-validation-message">{errors?.body?.message}</p>
              ) : null}
            </div>
          </div>
        </div>
      </form>

      <ImagesModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        modalTitle={modalTitle}
      >
        {contentModal}
      </ImagesModal>
    </>
  );
}

export default PageProductsForm;
