import React, { useState } from "react";
import { WallpaperOutlined } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import useBasicForm from "../../hooks/useBasicForm";
import useProductsValidator from "../../hooks/useProductsValidator";
import clienteAxios from "../../config/clienteAxios";
import ImagesModal from "../imagesModal/ImagesModal";
import Gallery from "../../pages/Gallery/Index";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditProductsForm = ({ editProduct, setReload, handleCloseModal }) => {
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  
  const categoryOptions = [
    { id: 1, value: "Interior", name: "Interior" },
    { id: 3, value: "Residential", name: "Residential" }
  ];

  const openModal = (content, title) => {
    setShowModal(true);
    setContentModal(content);
    setModalTitle(title);
  };

  const handleCloseModalGallery = () => setShowModal(false);

  const handleImageSelection = (selectedImagePath) => {
    setSpecificField({ name: "coverItem", value: selectedImagePath });
    handleCloseModalGallery();
  };
  
  const handleArraySelection = (arrayImages) => {
    setSpecificField({ name: "images", value: arrayImages });
    handleCloseModalGallery();
  };

  const { formState, setFormState, resetForm, setSpecificField } = useBasicForm({
    product: editProduct ? editProduct.product : "",
    coverItem: editProduct ? editProduct.coverItem : "",
    description: editProduct ? editProduct.description : "",
    images: editProduct ? editProduct.images : ""
  });

  const { errors, validateForm, onBlurField } = useProductsValidator(formState);

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value
    };

    setFormState({
      ...formState,
      [name]: value
    });

    if (errors[name]?.dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name
      });
    }
  };

  const clearForm = () => {
    setReload(prevReload => !prevReload);
    handleCloseModal()
    resetForm();
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    const { isValid, errors: errorsFound } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true
    });

    if (!isValid && typeof errorsFound === "object") {
      const errorsGrouped = Object.values(errorsFound)
      const errorFound = errorsGrouped?.find((error) => error.error)
      const errorMessage = errorFound?.message
      return toast.error(errorMessage || "Hay campos con errores");
    }

    const payloadData = { ...formState }

    const pathStartIndex = payloadData?.coverItem?.value?.indexOf("/api/images/");
    const imagePath = payloadData?.coverItem?.value?.substring(pathStartIndex + 5); 

    if (!(Array.isArray(payloadData?.images?.value)) && payloadData?.images?.value) {
      const regex = /\/([^\/]+)\/?$/;
      const match = payloadData?.images?.value?.match(regex)
      const id = match ? match[1] : ''
      payloadData.images = {
        name: 'images',
        value: [{id, album: []}]
      }
    }

    const arrayImages = payloadData?.images?.value?.map(image => `images/${image.id}`)

    const modifiedFormState = {
      ...payloadData,
      coverItem: imagePath,
      images: arrayImages
    };

    try {
      editProduct
        ? await clienteAxios.put(`/products/${editProduct._id}`, modifiedFormState)
        : await clienteAxios.post(`/products`, modifiedFormState);

      clearForm();
      toast.success("Se han guardado los cambios con éxito");
    } catch (error) {
      error?.response?.data?.data?.errors?.map(error => console.log(error));
      toast.error("Error al guardar los cambios");
    }

    handleCloseModal();
  };

  return (
    <>
    <form className="form" onSubmit={handleFormSubmit}>
      <div className="submit-field">
        <button type="submit" className="form-btn save">
          GUARDAR
        </button>
      </div>

      <div className="form-body">
        <div className="form-field">
          <h3>* Nombre del producto</h3>
          <input
            type="text"
            name="product"
            onBlur={onBlurField}
            onChange={onInputChange}
            value={formState.product}
            className={errors.product.error ? "red-warning" : ""}
          />
          <div className="error-validation">
            {errors.product.dirty && errors.product.error ? (
              <p className="error-validation-message">{errors.product.message}</p>
            ) : null}
          </div>
        </div>

        <div className="form-field">
          <h3>* Imagen destacada</h3>
            <div className="images-section">
              <button
                type="button"
                onBlur={onBlurField}
                onChange={onInputChange}
                name="coverItem"
                className="btn-gallery-picker-bg"
                onClick={() =>
                  openModal(
                    <>
                      <Gallery
                        editProductsView={true}
                        maxQuantity={1}
                        formState={formState}
                        onInputChange={onInputChange}
                        setSpecificField={handleImageSelection}
                        flagRender={false}
                      />
                    </>,
                    "Seleccionar imágenes"
                  )
                }
              >
                Elegir fondo
                <WallpaperOutlined />
              </button>
              <p>{formState.coverItem.value ? formState.coverItem.value : formState.coverItem}</p>
            </div>
        </div>

        <div className="form-field">
          <h3>Descripción</h3>
          <ReactQuill
            value={formState.description}
            theme="snow"
            onChange={(value) => onInputChange({target: {name: 'description', value}})}
            modules={{
              toolbar: {
                container: [
                  [{ header: '1' }, { header: '2' }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  ['link'],
                  [{ align: [] }],
                ],
              },
            }}
          />
        </div>

        <div className="form-field">
          <h3>Imágenes de galería</h3>
          <div className="images-section">
              <button
                type="button"
                onBlur={onBlurField}
                onChange={onInputChange}
                name="images"
                className={errors.images.error ? "red-warning btn-gallery-picker-bg" : "btn-gallery-picker-bg"}
                onClick={() =>
                  openModal(
                    <>
                      <Gallery
                        editProductsView={true}
                        maxQuantity={10}
                        formState={formState}
                        onInputChange={onInputChange}
                        setSpecificField={handleArraySelection}
                        flagRender={false}
                      />
                    </>,
                    "Seleccionar imágenes"
                  )
                }
              >
                Elegir fondo
                <WallpaperOutlined />
              </button>
            </div>
        </div>
      </div>
    </form>
    <ImagesModal
    showModal={showModal}
    handleCloseModal={handleCloseModalGallery}
    modalTitle={modalTitle}
  >
    {contentModal}
  </ImagesModal>
  </>
  );
};

export default EditProductsForm;
