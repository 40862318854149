import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { UNAUTHORIZED_STATUS_CODE } from "../../utilities/constants"
import { logoutService } from "../../services/authService"

const PrivateRoute = (props) => {
  const navigate = useNavigate()
  const [jwtValidated, setJwtValidated] = useState(false)

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const isAuth = JSON.parse(localStorage.getItem('isAuth'));
        if (isAuth) {
          setJwtValidated(true);
        } else {
          navigate('/log');
        }
      } catch (error) {
        logoutService();
        localStorage.setItem('isAuth', JSON.stringify(false));
        if (error?.response?.status !== UNAUTHORIZED_STATUS_CODE) navigate('/log')
      }
    }

    verifyToken()
  }, [])
  
  return (
    <>
      {jwtValidated ? props.children : <></>}
    </>
  )
}

export default PrivateRoute;
