import React, { useEffect, useState } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteWorksForm from "./forms/DeleteWorksForm";
import BasicModal from "./basicModal/BasicModal";
import EditWorksForm from "./forms/EditWorksForm";
import clienteAxios from "../config/clienteAxios";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import { fetchData } from "../utilities/fetchData";

function WorksTable() {
  const [works, setWorks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contentModal, setContentModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [reload, setReload] = useState(false);

  const notifyDelete = () => toast.success("Se eliminó correctamente")

  const openModal = (content, title) => {
    setShowModal(true);
    setContentModal(content);
    setModalTitle(title);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setWorks([]);
        setLoading(true);
        const response = await clienteAxios.get("/works");
        setWorks(response?.data?.data);
      } catch {
        notifyDelete();
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [reload]);

  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      {loading ? (
          <Skeleton className="skeleton-main" containerClassName="skeleton-main" />
      ) : (
        <div className="container-works-table">
          <div className="title-section">
            <div className="title-section-add">
              <HelpOutlineIcon sx={{ mr: 1 }} />
              <h1>Listado de Obras</h1>
            </div>
            <button
              type="button"
              onClick={() => openModal(
                <EditWorksForm
                  handleCloseModal={handleCloseModal}
                  setReload={setReload}
                  setShowModal={setShowModal}
                  fetchData={() => fetchData(setLoading, setWorks, "/works")}
                />,
                "Nueva Obra")}
            >
              <AddIcon />
            </button>
          </div>
          <div className="responsive-table WorksTable_overflow-x">
            <table className="works-table">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>
                    <span>Nombre Obra</span>
                  </th>
                  <th>Descripción</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {works.map((work, index) => (
                  <tr key={work._id}>
                    <td>{index + 1}</td>
                    <td>
                      <span>{work.work}</span>
                    </td>
                    <td>{work?.description}</td>
                    <td>
                      <div className="actions-buttons">
                        <button
                          type="button"
                          onClick={() =>
                            openModal(
                              <EditWorksForm
                                handleCloseModal={handleCloseModal}
                                setReload={setReload}
                                setShowModal={setShowModal}
                                work={work}
                                fetchData={() => fetchData(setLoading, setWorks, "/works")}
                              />,
                              "Editar Obra"
                            )
                          }
                        >
                          <EditOutlinedIcon />
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            openModal(
                              <DeleteWorksForm
                                work={work}
                                handleCloseModal={handleCloseModal}
                                reload={reload}
                                setReload={setReload}
                              />,
                              "Eliminar Obra"
                            )
                          }
                        >
                          <DeleteOutlineOutlinedIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>

          <BasicModal showModal={showModal} handleCloseModal={handleCloseModal} modalTitle={modalTitle}>
            {contentModal}
          </BasicModal>
        </div>)
      }
    </>
  );
}

export default WorksTable;
