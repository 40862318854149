import React from "react";
import { ErrorOutline } from "@mui/icons-material";
import { Modal } from "react-bootstrap";

const ConfirmModal = ({
  handleConfirm,
  handleCancel,
  message,
  confirmLabel,
  cancelLabel,
  showModal,
  handleCloseModal,
  modalTitle,
}) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex-column">
          <div className="text-center error_icon">
            <ErrorOutline />
          </div>
          <div className="text-center my-2">{message}</div>
          <div className="d-flex justify-content-around">
            <button className="button-success" onClick={handleConfirm}>
              {confirmLabel}
            </button>
            <button className="button-error" onClick={handleCancel}>
              {cancelLabel}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
