import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Layout from "../pages/Layout";
import Home from "../pages/Home/Index";
import Login from "../pages/Login/Login";
import EditHome from "../pages/Edit-Home/Index";
import EditProducts from "../pages/Edit-Products/Index";
import EditObras from "../pages/Edit-Obras";
import Gallery from "../pages/Gallery/Index";
import RecoveryPassword from "../pages/RecoveryPasswordPages/RecoveryPassword/RecoveryPassword";
import ChangePassword from "../pages/RecoveryPasswordPages/ChangePassword/ChangePassword";
import { ImageProvider } from "../contexts/ImageProvider";
import { ProductProvider } from "../contexts/ProductProvider";
import PrivateRoute from "../components/privateRoute/PrivateRoute";
import Footer from '../components/Footer';

const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <ImageProvider>
          <ProductProvider>
            <Routes>
              <Route path="/log" element={<Login />} />
              <Route path="/recovery-password" element={<RecoveryPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
                <Route index element={<PrivateRoute><Home /></PrivateRoute>} />
              </Route>
              <Route path="/edit-home" element={<PrivateRoute><EditHome /></PrivateRoute>}></Route>
              <Route path="/edit-products" element={<PrivateRoute><EditProducts /></PrivateRoute>}></Route>
              <Route path="/gallery" element={<PrivateRoute><Gallery /></PrivateRoute>}></Route>
              <Route path="/edit-works" element={<PrivateRoute><EditObras /></PrivateRoute>}></Route>
            </Routes>
            <Footer/>
          </ProductProvider>
        </ImageProvider>
      </BrowserRouter>
      <Toaster />
    </>
  );
};

export default AppRouter;
