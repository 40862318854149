import toast from "react-hot-toast";
import clienteAxios from "../config/clienteAxios";

const notify = () => toast.error("No se pudo completar la carga");

export const fetchData = async (setLoading, setData, path, worksOrProducts) => {
    try {
      setLoading(true);
      const { data } = await clienteAxios.get(path);
      if (worksOrProducts) {
        const worksInfo = data?.data?.find((page) => page?.section?.toLowerCase() === worksOrProducts)
        return setData(worksInfo)
      }
      setData(data?.data);
    } catch {
      notify();
    } finally {
      setLoading(false);
    }
  };
  