import React, { useEffect, useState } from "react";
import { Offcanvas, Form, Modal, Collapse, Button } from "react-bootstrap";
import UploadImage from "../../components/gallery/UploadImage";
import Gallery from "../../components/gallery/Gallery";
import toast from "react-hot-toast";
import {
  DoneOutlined,
  AddPhotoAlternateOutlined,
  CheckCircleOutline,
  DeleteForeverOutlined,
  PhotoOutlined,
  WallpaperOutlined
} from "@mui/icons-material";
import RecursoGallery from "../../components/gallery/RecursoGallery";
import useImage from "../../hooks/useImage";
import useProduct from "../../hooks/useProduct";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";

function Index({ editProductsView, maxQuantity, formState, onInputChange, setSpecificField, flagRender = true }) {
  const [showEditGaleria, setShowEditGaleria] = useState(false);
  const handleClose = () => setShowEditGaleria(false);
  const handleShow = () => setShowEditGaleria(true);
  const [openAlbums, setOpenAlbums] = useState(false);

  const [showAlbum, setShowAlbum] = useState(false);
  const handleCloseAlbum = () => setShowAlbum(false);
  const handleShowAlbum = () => setShowAlbum(true);

  const [deleteAlbum, setDeleteAlbum] = useState(false);
  const handleCloseDeleteAlbum = () => setDeleteAlbum(false);
  const handleDeleteAlbum = () => setDeleteAlbum(true);

  const [showNewResourceContent, setShowNewResourceContent] = useState(false);
  const handleNewResourceClick = () => {
    setShowNewResourceContent(!showNewResourceContent);
  };

  const {
    images,
    setImages,
    getImagesByBody,
    addAlbumToImages,
    handleStateToDelete,
    addExistingAlbumToImages,
    handleAlbumDelete,
    setFilteredImages,
    filteredImages,
    imagesAct
  } = useImage();

  const { products, setProducts } = useProduct();
  const [selectedImage, setSelectedImage] = useState(null);
  const [multiSelect, setMultiSelect] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  const [albumes, setAlbumes] = useState([]);
  const [albumesSelected, setAlbumesSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProducts(
      products?.sort(
        (a, b) => a.position?.find(obj => obj)?.product - b.position?.find(obj => obj)?.product
      )
    );
  }, [products]);

  const handleImageEdit = ({ target }) => {
    setSelectedImage(...images.filter(image => image.src === target.src));
    handleShow();
  };

  const handleAlbumFilter = ({ target }) => {
    const { value, checked } = target;

    if (checked) {
      setFilterArray([...filterArray, value]);
    } else {
      setFilterArray(filterArray.filter(item => item !== value));
    }
  };

  useEffect(() => {
    if (filterArray.length > 0) {
      const data = images
        .filter(
          data =>
            filterArray.includes(data.product) ||
            filterArray.includes(data.category) ||
            data.album.some(item => filterArray.includes(item))
        )
        .map(filtered => {
          return filtered;
        });

      setFilteredImages(data);
    } else {
      const data2 = images;
      setFilteredImages(data2);
    }
  }, [filterArray]);

  useEffect(() => {
    getImagesByBody().then(res => {
      const mapeo = res.map(res => {
        return {
          src: `${process.env.REACT_APP_BACKEND_URL}/api/images/${res._id}`,
          thumbnail: res.path,
          thumbnailWidth: 320,
          thumbnailHeight: 212,
          caption: res.filename,
          id: res._id,
          title: res.filename,
          description: res.description,
          alt: res.alt,
          category: res.category,
          product: res.product,
          state: true,
          tags: res.tags
            ? res.tags.map(item => {
                return {
                  value: item,
                  title: item
                };
              })
            : [],
          album: res.album,
          isSelected: false
        };
      });
      setImages(mapeo);
      setFilteredImages(mapeo);
      setFilterArray([]);
      setMultiSelect([]);
    });
  }, [imagesAct]);

  useEffect(() => {
    let albumes = [];
    let array = [];

    images.map(image => {
      if (image.album !== undefined) {
        image.album.map(album => {
          albumes.push(album);
          return albumes;
        });
      }
      return image;
    });
    array = [...new Set(albumes)];
    setAlbumes(array);
  }, [images]);

  useEffect(() => {
    loading === true &&
      toast.success("Recursos Obtenidos", {
        duration: 5000
      });
  }, [loading]);

  const onSelectImage = (index, image) => {
    const img = images.find(img => {
      return img.id === image.id;
    });
    if (img.isSelected) {
      img.isSelected = false;
      setMultiSelect(
        multiSelect.filter(item => item !== multiSelect.find(obj => obj.id === image.id))
      );
    } else {
      img.isSelected = true;
      const multiSelectData = [...multiSelect, { id: img.id, album: img.album }];
      if (!maxQuantity) {
        setMultiSelect(multiSelectData);
      } else if (multiSelectData.length <= maxQuantity) {
        setMultiSelect(multiSelectData);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    document.title = "Galería de Recursos";
  }, []);

  useEffect(() => {
    const collectionGaleryImg = Array.from(
      document.getElementsByClassName("ReactGridGallery_tile")
    );

    collectionGaleryImg.map(item => {
      const childItem = item.children[4].children[0];
      childItem.addEventListener("mousedown", e => {
        if (e.button === 2) {
          const aux = `${e.target.currentSrc}`;
          navigator.clipboard.writeText(aux);
          toast.success("URL copiada con exito");
        }
      });
    });
  }, [images]);

  useEffect(() => {
    const selectedAlbums = multiSelect.reduce((accumulator, image) => {
      return accumulator.concat(image.album);
    }, []);

    const uniqueAlbums = [...new Set(selectedAlbums)];
    setAlbumesSelected(uniqueAlbums);
  }, [multiSelect]);


  const saveProducts = () => {

    if (multiSelect.length === 1) {
      setSpecificField({
        name: "coverItem",
        value: `${process.env.REACT_APP_BACKEND_URL}/api/images/${multiSelect[0].id}`
      });
    } else if (multiSelect.length > 1) {
      setSpecificField({ name: "images", value: multiSelect });
    }

  };

  return (
    <>
      {flagRender ? <Navbar /> : null}
      {flagRender ? <Sidebar /> : null}
      <div className="container-pgi-gallery">
        <div className="my-5 sec-gallery">
          {!showNewResourceContent ? (
            <>
              <header>
                <h1>Galería Multimedia</h1>
              </header>
              <section className={`wrapperViewGalery ${flagRender ? '' : 'wrapperViewGalerySmallBreakpoint'}`}>
                <div className="form-floating">
                  <div id="pgi__galeria-filter">
                  {!editProductsView ? (
                      <Button
                        className="btn-form-pgi pgi__head-modules"
                        onClick={handleNewResourceClick}
                      >
                        Nuevo recurso
                        <WallpaperOutlined />
                      </Button>
                    ) : (
                      <Button className="btn-form-pgi pgi__head-modules" onClick={saveProducts}>
                        Guardar
                      </Button>
                    )}
                    <section id="albumesGalery d-flex">
                      <h3
                        onClick={() => setOpenAlbums(!openAlbums)}
                        aria-controls="products-collapse"
                        aria-expanded={openAlbums}
                        className="item-galeria-pgi"
                      >
                        Álbumes ({albumes.length})
                      </h3>
                      <Collapse in={openAlbums}>
                        <div id="products-collapse">
                          <Form>
                            {albumes.map((id, index) => (
                              <Form.Check
                                className="pgi__editAlbum"
                                type="checkbox"
                                id={`id${id}`}
                                key={index}
                                label={`${id}`}
                                name={"album"}
                                value={id}
                                checked={filterArray.includes(id)}
                                onChange={handleAlbumFilter}
                              />
                            ))}
                          </Form>
                        </div>
                      </Collapse>
                    </section>
                  </div>
                </div>
                <div id="pgi__galeria-view">
                  {!editProductsView &&
                    (multiSelect.length > 0 ? (
                      <div id="pgi__galeria-album">
                        <div className="pgi__info-second">
                          <CheckCircleOutline fontSize="small" /> {multiSelect.length} elementos
                          seleccionados
                        </div>
                        <button type="button" onClick={handleShowAlbum}>
                          <AddPhotoAlternateOutlined />{" "}
                          Añadir al álbum
                        </button>
                        <button type="button" onClick={handleDeleteAlbum}>
                          <DeleteForeverOutlined /> 
                          Eliminar
                        </button>
                      </div>
                    ) : null)}
                  <article className="pgi__album-category">
                    <h2>
                      Todos <i>({filteredImages.length})</i>
                    </h2>
                    <Gallery
                      filteredImages={filteredImages}
                      selectedImages={multiSelect}
                      imageCountSeparator=" de "
                      enableImageSelection={true}
                      onSelectImage={onSelectImage}
                      backdropClosesModal={true}
                      showLightboxThumbnails={true}
                      onClickImage={handleImageEdit}
                    />
                  </article>
                </div>
              </section>
            </>
          ) : (
            <UploadImage
              selectedImage={selectedImage ? selectedImage : null}
              allImages={images}
              onNewResourceClick={handleNewResourceClick}
              showNewResourceContent={showNewResourceContent}
            />
          )}
        </div>

        <Offcanvas
          show={showEditGaleria}
          onHide={handleClose}
          placement="end"
          scroll
          enforceFocus
          backdrop={false}
          className="basePgi__pgi"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Información de Recurso</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <RecursoGallery
              selectedImage={selectedImage ? selectedImage : null}
              allImages={images}
            />
          </Offcanvas.Body>
        </Offcanvas>

        <Modal className="pgi__gallery-modal" show={showAlbum} onHide={handleCloseAlbum}>
          <Modal.Header closeButton>
            <Modal.Title>Añadir a...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="addPhotoTo" className="pgi__info-second">
              <CheckCircleOutline fontSize="small" /> {multiSelect.length} elementos seleccionados
            </div>
            <ul className="pgi__addToAlbum">
              <div className="pgi__album-category">
                <h2>Nuevo Álbum</h2>
              </div>
              <li>
                <form
                  id="addNewAlbum"
                  onSubmit={event => addAlbumToImages(event, multiSelect, handleCloseAlbum)}
                >
                  <input
                    type="text"
                    required
                    className="form-control"
                    name="newAlbum"
                    id="newAlbum"
                    placeholder="Añade un título"
                  />
                  <button type="submit">
                    <DoneOutlined />
                  </button>
                </form>
              </li>
              {albumes.length > 0 && (
                <div className="pgi__album-category">
                  <h2>Álbumes</h2>
                </div>
              )}
              {albumes.map((id, index) => (
                <li key={index} className="pgi__addToAlbums">
                  <form id="addToAlbum">
                    <figure>
                      <div className="pgi__thumb">
                        <video src={images[1]?.thumbnail} alt="" />
                      </div>
                      <figcaption>
                        <span>
                          <PhotoOutlined />
                        </span>
                        {id}{" "}
                      </figcaption>
                    </figure>
                    <button
                      type="button"
                      onClick={() => addExistingAlbumToImages(id, multiSelect, handleCloseAlbum)}
                    >
                      <DoneOutlined />
                    </button>
                  </form>
                </li>
              ))}
            </ul>
          </Modal.Body>
        </Modal>

        <Modal
          className="pgi__gallery-modal"
          id="deleteRecursos"
          show={deleteAlbum}
          onHide={handleCloseDeleteAlbum}
        >
          <Modal.Header closeButton>
            <Modal.Title>Eliminar de...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div id="addPhotoTo" className="pgi__info-second">
              <CheckCircleOutline fontSize="small" /> {multiSelect.length} elementos seleccionados
            </div>
            <ul className="pgi__addToAlbum">
              <div className="pgi__album-category">
                <h2>Recursos Multimedia </h2>
              </div>
              <li>
                <form
                  id="deleteRecursoAll"
                  onSubmit={event =>
                    handleStateToDelete(event, multiSelect, handleCloseDeleteAlbum)
                  }
                >
                  <div>
                    <DeleteForeverOutlined /> Base de Datos
                  </div>
                  <button type="submit">
                    <DoneOutlined />
                  </button>
                </form>
              </li>
              {albumesSelected.length > 0 && (
                <div className="pgi__album-category">
                  <h2>Álbumes</h2>
                </div>
              )}
              {albumesSelected.map((id, index) => (
                <li key={index} className="pgi__addToAlbums">
                  <form id="addToAlbum">
                    <figure>
                      <div className="pgi__thumb">
                        <video src={images[1]?.thumbnail} alt="" />
                      </div>
                      <figcaption>
                        {id}{" "}
                        <span>
                          <PhotoOutlined />
                        </span>
                      </figcaption>
                    </figure>
                    <button
                      type="button"
                      onClick={() => handleAlbumDelete(id, multiSelect, handleCloseDeleteAlbum)}
                    >
                      <DoneOutlined />
                    </button>
                  </form>
                </li>
              ))}
            </ul>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Index;
