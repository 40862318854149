import React, { useState } from "react";
import { toast } from "react-hot-toast";
import useBasicForm from "../../hooks/useBasicForm";
import clienteAxios from "../../config/clienteAxios";
import Gallery from "./../../pages/Gallery/Index";
import { WallpaperOutlined } from "@mui/icons-material";
import ImagesModal from "../imagesModal/ImagesModal";
import useWorksValidator from "../../hooks/useWorksValidator";

const EditWorksForm = ({ work, setReload, handleCloseModal, fetchData }) => {
  const { formState, resetForm, setSpecificField, setFormState } = useBasicForm({
    work: work ? work?.work : "",
    description: work ? work?.description : "",
    updated_at: work ? work?.updated_at?.slice(0, 10) : "",
    coverItem: work ? work?.coverItem : "arrayImg",
  });
  const { errors, validateForm, onBlurField } = useWorksValidator(formState);

  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const onInputChange = ({ target }) => {
    const { name, value } = target;

    const nextFormState = {
      ...formState,
      [name]: value
    };

    setFormState({
      ...formState,
      [name]: value
    });

    if (errors[name]?.dirty) {
      validateForm({
        form: nextFormState,
        errors,
        field: name
      });
    }
  };

  const clearForm = () => {
    setReload((prevReload) => !prevReload);
    handleCloseModal();
    resetForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { isValid, errors: errorsFound } = validateForm({
        form: formState,
        errors,
        forceTouchErrors: true
      });
  
      if (!isValid && typeof errorsFound === "object") {
        const errorsGrouped = Object.values(errorsFound)
        const errorFound = errorsGrouped?.find((error) => error.error)
        const errorMessage = errorFound?.message
        return toast.error(errorMessage || "Hay campos con errores");
      }
  
      work
        ? await clienteAxios.put(`/works/${work._id}`, formState)
        : await clienteAxios.post(`/works`, formState);

      fetchData()
      clearForm();
      toast.success("Se han guardado los cambios con éxito");
    } catch (error) {
      error?.response?.data?.errors?.map((error) => console.log(error));
      toast.error("Error al guardar los cambios");
    }

    handleCloseModal();
  };

  const openModal = (content, title) => {
    setShowModal(true);
    setContentModal(content);
    setModalTitle(title);
  };

  const handleImageSelection = (selectedImagePath) => {
    setSpecificField({ name: "coverItem", value: selectedImagePath.value });
    setShowModal(false)
  };

  return (
    <>
      <form className="form">
        <div className="submit-field">
          <button
            onClick={handleSubmit}
            type="submit"
            className="form-btn save"
          >
            GUARDAR
          </button>
        </div>
        <div className="form-body">
          <div className="form-field">
            <h3>Nombre de la obra</h3>
            <input
              type="text"
              placeholder="Obra 1"
              name="work"
              onChange={onInputChange}
              value={formState.work}
              onBlur={onBlurField}
              className={errors?.work?.error ? "red-warning" : ""}
            />
            <div className="error-validation">
              {errors?.work?.dirty && errors?.work?.error ? (
                <p className="error-validation-message">{errors?.work?.message}</p>
              ) : null}
            </div>
          </div>
          <div className="form-field">
            <h3>Descripción</h3>
            <div className='custom-textarea'>
              <textarea
                type="text"
                name="description"
                onChange={onInputChange}
                value={formState.description}
                onBlur={onBlurField}
                className={errors?.description?.error ? "red-warning" : ""}
              />
            </div>
            <div className="error-validation">
              {errors?.description?.dirty && errors?.description?.error ? (
                <p className="error-validation-message">{errors?.description?.message}</p>
              ) : null}
            </div>
          </div>
          <div className="form-field">
            <button
              type="button"
              onChange={onInputChange}
              name="coverItem"
              className="btn-gallery-picker-bg"
              onBlur={onBlurField}
              onClick={() =>
                openModal(
                  <>
                    <Gallery
                      editProductsView={true}
                      maxQuantity={1}
                      formState={formState}
                      onInputChange={onInputChange}
                      setSpecificField={handleImageSelection}
                      flagRender={false}
                    />
                  </>,
                  "Seleccionar imágenes"
                )
              }
            >
              Elegir fondo
              <WallpaperOutlined />
            </button>
          </div>
        </div>
      </form>
      <ImagesModal
        showModal={showModal}
        handleCloseModal={() => setShowModal(false)}
        modalTitle={modalTitle}
      >
        {contentModal}
      </ImagesModal>
    </>
  );
};

export default EditWorksForm;
