import React, { useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { grey, teal } from "@mui/material/colors";

function LoginForm({ handleInputChange }) {
  const [VisibilityPass, setVisibilityPass] = useState(false);

  const toggleBoton = () => {
    setVisibilityPass(!VisibilityPass);
  };

  return (
    <div className="Formulario-log-main">
      <label htmlFor="" className="label-log">
        Correo:
        <input onChange={handleInputChange} name="email" type="text"/>
      </label>
      <label htmlFor="" className="label-log">
        Contraseña:
        <div className="input-pass">
          <input onChange={handleInputChange} name="password" type={VisibilityPass ? "text" : "password"}/>
          <button type="button" onClick={toggleBoton} className="btn-visibility">
            {VisibilityPass ? <RemoveRedEyeIcon sx={{color: teal[100]}}/> : <VisibilityOffIcon sx={{color: grey[50]}}   />}
          </button>
        </div>
      </label>
    </div>
  );
}

export default LoginForm;
