import React from 'react'
import Introduction from '../../components/home/Introduction'
import Cards from '../../components/home/Cards'

function Home() {
  return (
    <div className='bg-pgi'>
        <Introduction />
        <Cards />
    </div>
  )
}

export default Home