import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import useBasicForm from '../../hooks/useBasicForm';
import clienteAxios from '../../config/clienteAxios';
import Gallery from "../../pages/Gallery/Index";
import { WallpaperOutlined } from "@mui/icons-material";
import ImagesModal from "../imagesModal/ImagesModal";


const ModalSlider = ({ closeModalSlider, slider, setReload, setSelectedSlider }) => {

    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [modalTitle, setModalTitle] = useState("");

    const openModal = (content, title) => {
        setShowModal(true);
        setContentModal(content);
        setModalTitle(title);
    };
    
    const { formState, onInputChange, resetForm, setSpecificField } = useBasicForm({
        title: slider ? slider.title : "",
        coverItem: slider ? slider.coverItem : "",
        subtitle: slider ? slider.subtitle : "",
        firstTitle: slider ? slider.firstTitle : "",
        link: slider ? slider.link : "",
        linkText: slider ? slider.linkText : "",
    })
    
    const handleClickInner = (e) => {
        e.stopPropagation();
    };

    const validateLink = (link) => {
        const urlRegex = /^[a-zA-Z]+$/;
        return urlRegex.test(link);
    };
    
    const handleCloseModal = () => setShowModal(false);

    const handleImageSelection = (selectedImagePath) => {
        setSpecificField({ name: "coverItem", value: selectedImagePath });
        handleCloseModal();
    };

    useEffect(() => {
        if (slider) {
            const { title, subtitle, firstTitle ,coverItem, link, linkText } = slider;
            formState.title = title || '';
            formState.subtitle = subtitle || '';
            formState.firstTitle = firstTitle || '';
            formState.coverItem = coverItem || '';
            formState.link = link || '';
            formState.linkText = linkText || '';
        }
    }, [slider])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!validateLink(formState.link)) {
            toast.error("¡La URL no es válida!");
            return;
        }

        try {
            const url = formState.coverItem.value ? formState.coverItem.value : formState.coverItem

            const pathSegments = url.split('/');
            const id = pathSegments[pathSegments.length - 1];
            const imagePath = `images/${id}`;

            const modifiedFormState = {
                ...formState,
                coverItem: imagePath,
            };
            const request =
                slider ?
                    await clienteAxios.put(`/sliders/${slider._id}`, modifiedFormState) :
                    await clienteAxios.post('/sliders', modifiedFormState)

            console.log(request)
            setReload()
            clearForm()
        } catch (error) {
            toast.error("Hubo un error al procesar la solicitud.");
            error?.response?.data?.data?.errors?.map((error) =>
                console.log(error)
            )
        }
    }

    const clearForm = () => {
        closeModalSlider()
        resetForm()
        setSelectedSlider()
    }
    return (
        <div className='main-modal' onClick={clearForm}>
            <form className='form-modal-slider' onClick={handleClickInner}>
                <label htmlFor="">Titulo</label>
                <input name='title' value={formState.title} onChange={onInputChange} type="text" />
                <label htmlFor="">Subtitulo</label>
                <input name='subtitle' value={formState.subtitle} onChange={onInputChange} type="text" />
                <label htmlFor="">Primer titulo</label>
                <input name='firstTitle' value={formState.firstTitle} onChange={onInputChange} type="text" />
                <div className="form-field">
                    <label htmlFor="">Imagen</label>
                    <div className="images-section">
                    <button
                        type="button"
                        onChange={onInputChange}
                        name="coverItem"
                        onClick={() =>
                        openModal(
                            <>
                            <Gallery
                                editProductsView={true}
                                maxQuantity={1}
                                formState={formState}
                                onInputChange={onInputChange}
                                setSpecificField={handleImageSelection}
                                flagRender={false}
                            />
                            </>,
                            "Seleccionar imágenes"
                        )
                        }
                    >
                        Elegir Imagen
                        <WallpaperOutlined />
                    </button>
                    <p>{formState?.coverItem?.value ? formState?.coverItem?.value : formState?.coverItem}</p>
                </div>
                </div>
                <label htmlFor="">Enlace</label>
                <select name='link' value={formState.link} onChange={onInputChange}>
                    <option value='about'>Nosotros</option>
                    <option value='products'>Productos</option>
                    <option value='works'>Obras</option>
                    <option value='contact'>Contacto</option>
                </select>
                <label htmlFor="">texto Enlace</label>
                <input name='linkText' value={formState.linkText} onChange={onInputChange} type="text" />
                <button className='btn-submit' onClick={handleSubmit}>Guardar</button>
            </form>
            <div onClick={handleClickInner}>
                <ImagesModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    modalTitle={modalTitle}
                >
                    {contentModal}
                </ImagesModal>
            </div>
        </div>
    )
}

export default ModalSlider
