import React from "react";
import { Modal } from "react-bootstrap";

const BasicModal = ({ showModal, handleCloseModal, modalTitle, children }) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default BasicModal;
