export const textLengthValidator = (value, max) => {
  if (!value) {
    return "El campo no debe ir vacío";
  }
  if (value.length > max) {
    return `El campo debe tener como maximo ${max} caracteres`;
  }
  return "";
};

export const descriptionValidator = value => {
  if (!value) {
    return "La descipcón no debe ir vacía";
  }
  return "";
};

export const textValidator = value => {
  if (!value) {
    return "El campo no debe ir vacío";
  }
  return "";
};

export const selectValidator = value => {
  if (!value) {
    return "Debe seleccionar una opción";
  }
  return "";
};

export const imageValidator = value => {
  if (!value) {
    return "Debe seleccionar una imagen";
  }
  return "";
};

export const editorValidator = value => {
  if (!value) {
    return "El editor debe tener contenido";
  }
  return "";
};
