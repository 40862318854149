import React from "react";
import { Modal } from "react-bootstrap";

const ImagesModal = ({ showModal, handleCloseModal, modalTitle, children }) => {
  return (
    <Modal size="xl" show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};

export default ImagesModal;
