export const products = [
  {
    technicalSheet: null,
    _id: "62c44d28a2d699d736aefc60",
    title: "TENSOBLOCK",
    subtitle: "SISTEMAS",
    subdescription:
      '<p>Mi texto de prueba</p>\n<p>Hola hola</p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 49.8728%;"><col style="width: 49.8728%;"></colgroup>\n<tbody>\n<tr>\n<th>Hola</th>\n<th>Hola</th>\n</tr>\n<tr>\n<td>Chau</td>\n<td>Chau</td>\n</tr>\n</tbody>\n</table>',
    benefit: [
      {
        iconBenefit: "lock_open",
        textBenefit: "Nuevo beneficio",
        _id: "KdgS6Cwn2WXZ"
      },
      {
        iconBenefit: "settings",
        textBenefit: "Otro beneficio",
        _id: "Qej8ONx89EWC"
      }
    ],
    productDetails: "Ejemplo de detalle",
    filename: [
      {
        filename: "1660135333988_0008657_FC_A-0008-00256372.pdf",
        url: "http://localhost:9001/api/product/files/1660135333988_0008657_FC_A-0008-00256372.pdf",
        title: "Manual prueba",
        deleted: true,
        state: false
      },
      {
        filename: "1660741138151_manual-tensoblock.pdf",
        url: "http://localhost:9001/api/product/files/1660741138151_manual-tensoblock.pdf",
        title: "Ficha técnica",
        deleted: true,
        state: true
      },
      {
        filename: "1661168072161_manual-tensoblock (1).pdf",
        url: "http://localhost:9001/api/product/files/1661168072161_manual-tensoblock (1).pdf",
        title: "Manual Técnico",
        deleted: false,
        state: true
      },
      {
        filename: "1661168092217_ficha-tecnica-tensoblock.pdf",
        url: "http://localhost:9001/api/product/files/1661168092217_ficha-tecnica-tensoblock.pdf",
        title: "Ficha Técnica",
        deleted: false,
        state: true
      },
      {
        filename: "1661168111305_ficha-tecnica-tensoblock-linea-liviana.pdf",
        url: "http://localhost:9001/api/product/files/1661168111305_ficha-tecnica-tensoblock-linea-liviana.pdf",
        title: "Ficha Técnica Línea Liviana",
        deleted: false,
        state: true
      },
      {
        filename: "1661192639314_Donac Di Bacco y Cia_1668449260608.pdf",
        url: "http://localhost:9001/api/product/files/1661192639314_Donac Di Bacco y Cia_1668449260608.pdf",
        title: "Probando manual",
        deleted: false,
        state: true
      },
      {
        filename: "1661192639314_Donac Di Bacco y Cia_1668449260608_1668449397389.pdf",
        url: "http://localhost:9001/api/product/files/1661192639314_Donac Di Bacco y Cia_1668449260608_1668449397389.pdf",
        title: "Hola",
        deleted: false,
        state: true
      }
    ],
    faqs: [
      {
        _id: 0.14725243520632336,
        question: "¿Cómo está compuesto el Grupo Tensolite??",
        answer:
          "<p>El Grupo Tensolite es el propietario de las siguientes unidades de negocio de rubros diversos.&nbsp;</p>\n<p>Es fabricante de productos de tecnolog&iacute;a de hormig&oacute;n pretensado y premoldeado. Entre las marcas de sus productos m&aacute;s destacados se encuentran: Tensobuild, constructora de obras p&uacute;blicas y privadas de toda envergadura. Se destaca por la ejecuci&oacute;n de proyectos viales y de viviendas.enfsbaduovbaosdbvioasdnvs</p>",
        video: "https://www.youtube.com/embed/PvRP25GcaDc"
      },
      {
        _id: 0.23730989797475877,
        question: "Probando",
        answer: "<p>Probando este faqs. Probando</p>",
        video: ""
      },
      {
        _id: 0.2597538991727655,
        question: "Ultimo intento",
        answer: "<p>Ultimo intento probando</p>",
        video: ""
      },
      {
        _id: 0.8674259991027604,
        question: "jhjhsxjkhfdkjhscjkdsagkhjsdajkhdsahjk",
        answer: "<p>sdfsafasfsawffsafzsazsfszfasfzacsfza</p>",
        video: ""
      },
      {
        _id: 0.6246115270337098,
        question: "hola sapo sin cola",
        answer:
          '<p>esto funciona</p>\n<table style="border-collapse: collapse; width: 100%; height: 44.7812px;" border="1"><colgroup><col style="width: 50.087%;"><col style="width: 50.087%;"></colgroup>\n<tbody>\n<tr style="height: 22.3906px;">\n<th style="height: 22.3906px;">ROMA</th>\n<th style="height: 22.3906px;">ROMA</th>\n</tr>\n<tr style="height: 22.3906px;">\n<td style="height: 22.3906px;">Saxon</td>\n<td style="height: 22.3906px;">Saxon</td>\n</tr>\n</tbody>\n</table>\n<h3>Mi t&iacute;tulo</h3>\n<p>Prueba...</p>',
        video: "xyz"
      }
    ],
    category: "T20",
    subproduct: [
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bc1ffbbeccba963465d",
        title: "Bloque de 13 cm. para muro",
        dimension: "13 x 19 x 25 cm",
        weight: "6,5 kg",
        colour: ["color-negro", "color-terracota"],
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [
          "http://localhost:9001/api/image/files/1659442547705_Logo-Isotipo_tensolite%20blanco.png"
        ],
        __v: 0,
        category: "T13",
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        quantity: "25",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        technicalProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        imageLogo:
          "http://localhost:9001/api/image/files/1659442547705_Logo-Isotipo_tensolite%20blanco.png",
        productTitle: "Tensoblock",
        subCategory: "T13 U",
        groupByCategory: [],
        created_date: "2022-07-25T19:05:27.145Z",
        visible: true
      },
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bfbffbbeccba9634667",
        title: "Bloque para Encadenado en muro de 20 cm.",
        dimension: "19 x 19 x 19 cm",
        category: "T20",
        weight: "8,5 kg",
        quantity: "25",
        colour: ["color-negro", "color-terracota"],
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [],
        __v: 0,
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        productTitle: "Tensoblock",
        subCategory: "T20 U",
        groupByCategory: [],
        created_date: "2022-07-25T14:17:16.800Z",
        visible: true
      }
    ],
    state: true,
    deleted: false,
    created_date: "2022-07-05T14:39:31.777Z",
    deleted_date: null,
    __v: 0,
    imageBackground: [
      {
        _id: "6504910de6ae40d4e85a30ae",
        title: "Ventajas y desventajas del hormigón",
        description: "explicación",
        filename: "y2mate_1694798092964.mp4",
        url: "http://localhost:9001/api/image/files/y2mate_1694798092964.mp4"
      }
    ],
    titleSubDescription: "Eficiencia en estructuras.",
    imageInstalation: ["http://168.197.48.253:9001/api/image/files/bloque_t13.png"],
    imageLogo: [
      {
        _id: "62e982bc6f628f3ed162933c",
        title: "Tensoblock-Logo3",
        description: "Tensoblock-Logo3",
        filename: "1659470524822_Logo-Isologo-Horizontal_tensoblock blanco.png",
        url: "http://localhost:9001/api/image/files/1659470524822_Logo-Isologo-Horizontal_tensoblock blanco.png"
      }
    ],
    top: "Unidades de un sistema constructivo más ágil, eficiente y económico que el tradicional. Al ser de hormigón vibrocomprimido de alta calidad, pueden trabajar como encofrados perdidos para construir columnas y vigas en su interior, además de poder insertar las instalaciones",
    imageProduct: [
      {
        _id: "6364060e5e197f5c81f80cc3",
        title: "Cercalite",
        description: "Cercalite",
        filename: "1667499534953_Calculadora.jpg",
        url: "http://localhost:9001/api/image/files/1667499534953_Calculadora.jpg"
      },
      {
        _id: "62e968c687c6122939bbfa43",
        title: "Slider-6",
        description: "Slider-6",
        filename: "1659463878397_tensolite-bloques-tensoblock-1.png",
        url: "http://localhost:9001/api/image/files/1659463878397_tensolite-bloques-tensoblock-1.png"
      }
    ],
    groupByCategory: [
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60becffbbeccba9634665",
        title: "Bloque p/ muro de 20 cm. Mitad Frente Debilitado",
        dimension: ": 19 x 19 x 19",
        weight: "9",
        colour: [null],
        productId: "62b5f50f4517ba2c12a50c62",
        state: true,
        url: [
          "http://168.197.48.253:9001/api/image/files/bloque_t20_mitad_simil_piedra.png",
          "http://168.197.48.253:9001/api/image/files/bloque_t20_mitad_simil_piedra_2.png"
        ],
        __v: 0,
        category: "T20",
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productTitle: "Tensoblock",
        quantity: "25",
        subCategory: "T20 MFD",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        groupByCategory: []
      },
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bfbffbbeccba9634667",
        title: "Bloque para Encadenado en muro de 20 cm.",
        dimension: "19 x 19 x 19 cm",
        category: "T20",
        weight: "8,5 kg",
        quantity: "25",
        colour: ["color-negro", "color-terracota"],
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [
          "http://localhost:3002/api/image/files/image-subproduct-tensoblock1.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_2.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_3.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_4.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_5.png"
        ],
        __v: 0,
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        productTitle: "Tensoblock",
        subCategory: "T20 U",
        groupByCategory: [],
        created_date: "2022-07-25T14:17:16.800Z",
        visible: true
      }
    ],
    imageIcon: [
      {
        _id: "62e92cb959003436d937b71d",
        title: "Tensoblock-ICON",
        description: "Tensoblock-ICON",
        filename: "1659448505039_bloques tensoblock lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448505039_bloques tensoblock lineal.svg"
      }
    ],
    type: "productoLinea",
    visible: true,
    instalationSteps: [
      {
        stepDescription:
          "<p>Se deben presentar los bloques sin mezcla para verificar la modulaci&oacute;n. Se puede marcar la ubica ci&oacute;n de cada uno de ellos mediante una tiza. cualquier c bloques sin meznte una tiza. cualquier correcci&oacute;n se hSe deben presentar los bloques sin mezcla para verificar la modulaci&oacute;n. Se puede marcar la ubicaci&oacute;n de cada uno de ellos mediante una tiza. cu</p>\n<p>alquier correcci&oacute;n se hSe deben presentar los bloques sin mezcla para verificar la modulaci&oacute;n. Se puede marcar la ubicaci&oacute;n de cada uno de ellos mediante una tiza. cualquier correcci&oacute;n se hSe deben presentar los bloques sin mezcla para verificar la modulaci&oacute;n. Se puede marcar la ubicaci&oacute;n de cada uno de ellos mediante una tiza. se h</p>",
        stepTitle: "La Primera Hilada",
        stepWarningText:
          "NUNCA utilizar ladrillos o bloques como base de los puntuales. Previo al hormigonado se debe verificar el correcto estado de apuntalamiento.",
        imageInstallation: [
          {
            _id: "62fcd791b9d0590863e252e7",
            title: "paso 1 Tensoblock",
            description: "Primera Hilada",
            filename: "1660737425666_paso1-tensoblock.png",
            url: "http://localhost:9001/api/image/files/1660737425666_paso1-tensoblock.png"
          }
        ],
        _id: "6377b59497a88d3916aa3f02"
      },
      {
        stepDescription:
          "<p>Colocar la mezcla, &uacute;nicamente,</p>\n<p>en los tabiques longitudinales.Probando</p>",
        stepTitle: "Juntas Horizontales",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f03"
      },
      {
        stepDescription:
          "<p>El mortero de las juntas verticales debe ser untado antes de colocar el bloque.</p>",
        stepTitle: "Juntas Verticales",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f04"
      },
      {
        stepDescription:
          "<p>Controlar con nivel de burbuja de 0,80 a 1,20 m de largo la nivelaci&oacute;n</p>\n<p>horizontal y vertical.</p>",
        stepTitle: "Control de Niveles",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f05"
      },
      {
        stepDescription:
          "<p>No debe quedar ninguna junta vac&iacute;a, tanto vertical como horizontal.</p>",
        stepTitle: "Control de Juntas",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f06"
      },
      {
        stepDescription:
          "<p>Rehundir todas las juntas ejerciendo presi&oacute;n con un tomador de juntas o con un hierrito liso de 12 mm doblado a fin de sellar posibles microfisuras que se formen al ir secando el mortero.</p>",
        stepTitle: "Tomado de Juntas",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f07"
      },
      {
        stepDescription:
          "<p>Rehundir todas las juntas ejerciendo presi&oacute;n con un tomador de juntas o con un hierrito liso de 12 mm doblado a fin de sellar posibles microfisuras que se formen al ir secando el mortero.</p>",
        stepTitle: "PAso1",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f08"
      },
      {
        stepDescription:
          "<p>Rehundir todas las juntas ejerciendo presi&oacute;n con un tomador de juntas o con un hierrito liso de 12 mm doblado a fin de sellar posibles microfisuras que se formen al ir secando el mortero.</p>",
        stepTitle: "Paso de prueba",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f09"
      },
      {
        stepDescription:
          "<p>Rehundir todas las juntas ejerciendo presi&oacute;n con un tomador de juntas o con un hierrito liso de 12 mm doblado a fin de sellar posibles microfisuras que se formen al ir secando el mortero.Rehundir todas las juntas ejerciendo presi&oacute;n con un tomador de juntas o con un hierrito liso de 12 mm doblado a fin de sellar posibles microfisuras que se formen al ir secando el mortero.</p>",
        stepTitle: "Proando2",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f0a"
      },
      {
        stepDescription:
          "<p>Rehundir todas las juntas ejerciendo presi&oacute;n con un tomador de juntas o con un hierrito liso de 12 mm doblado a fin de sellar posibles microfisuras que se formen al ir secando el mortero.</p>",
        stepTitle: "Nuevo",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f0b"
      },
      {
        stepDescription:
          "<p>Rehundir todas las juntas ejerciendo presi&oacute;n con un tomador de juntas o con un hierrito liso de 12 mm doblado a fin de sellar posibles microfisuras que se formen al ir secando el mortero.Rehundir todas las juntas ejerciendo presi&oacute;n con un tomador de juntas o con un hierrito liso de 12 mm doblado a fin de sellar posibles microfisuras que se formen al ir secando el mortero.</p>",
        stepTitle: "PAso1",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f0c"
      },
      {
        stepDescription: "",
        stepTitle: "",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "6377b59497a88d3916aa3f0d"
      }
    ],
    imageGallery: [
      {
        _id: "62e82d87f2c7ab70d66e1774",
        title: "Casa",
        description: "casa",
        filename: "1659383175013_123_202207141213575268.jpg",
        url: "http://localhost:9001/api/image/files/1659383175013_123_202207141213575268.jpg"
      },
      {
        _id: "62e9198e59003436d937b609",
        title: "Bovedilla-1",
        description: "Bovedilla-1",
        filename: "1659443598884_1.jpg",
        url: "http://localhost:9001/api/image/files/1659443598884_1.jpg"
      },
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      },
      {
        _id: "62f1312605b1dda7c213cdb0",
        title: "zxczxc",
        description: "xzczxc",
        filename: "1659973926083_descarga.png",
        url: "http://localhost:9001/api/image/files/1659973926083_descarga.png"
      },
      {
        _id: "62e92d7d59003436d937b72d",
        title: "Pasarela-ICON",
        description: "Pasarela-ICON",
        filename: "1659448701190_Tensolite pasarela lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448701190_Tensolite pasarela lineal.svg"
      },
      {
        _id: "62e9673987c6122939bbfa15",
        title: "Slider-4",
        description: "Slider-4",
        filename: "1659463481254_tensolite-fabrica-1.png",
        url: "http://localhost:9001/api/image/files/1659463481254_tensolite-fabrica-1.png"
      },
      {
        _id: "6364060e5e197f5c81f80cc3",
        title: "Cercalite",
        description: "Cercalite",
        filename: "1667499534953_Calculadora.jpg",
        url: "http://localhost:9001/api/image/files/1667499534953_Calculadora.jpg"
      },
      {
        _id: "62fcd8aab9d0590863e252f3",
        title: "paso6- Tomado de Juntas",
        description: "Tomado de Juntas",
        filename: "1660737706259_tensoblock-6-TomadodeJuntas.svg",
        url: "http://localhost:9001/api/image/files/1660737706259_tensoblock-6-TomadodeJuntas.svg"
      },
      {
        _id: "6512fbfd04db1c982b334898",
        title: "video-hormigon",
        description: "example",
        filename: "architecture_-_20901 (1080p)_1695742973200.mp4",
        url: "http://localhost:9001/api/image/files/architecture_-_20901 (1080p)_1695742973200.mp4"
      }
    ],
    imageInstallation: [
      "63728292c7b6b28e12f751b1",
      "6372816229764902289ade4e",
      "62fcd8aab9d0590863e252f3",
      "62fcd890b9d0590863e252f1",
      "62fcd86fb9d0590863e252ef"
    ],
    galleryEnabled: true,
    moduleConteiner: [],
    publish: true,
    faqsEnabled: true,
    instalationStepsEnabled: true,
    imageIconColor: [],
    imageLogoColor: [
      {
        _id: "62e9584b59003436d937bae8",
        title: "Tensoblock-Logo",
        description: "Tensoblock-Logo",
        filename: "1659459659510_calco-tensoblock.jpg",
        url: "http://localhost:9001/api/image/files/1659459659510_calco-tensoblock.jpg"
      }
    ],
    imageProductAlpha: [
      {
        _id: "636beab972403f5201e27d05",
        title: "icono svg",
        description: "Icono svg",
        filename: "1668016825487_Tensolite otros lineal.svg",
        url: "http://localhost:9001/api/image/files/1668016825487_Tensolite otros lineal.svg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "6512f5f804db1c982b3335aa",
        title: "example-video-3",
        description: "example description",
        filename: "pexels-pachon-in-motion-16003146 (1080p)_1695741432321.mp4",
        url: "http://localhost:9001/api/image/files/pexels-pachon-in-motion-16003146 (1080p)_1695741432321.mp4"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "6512f5d604db1c982b3335a8",
        title: "example-video-2",
        description: "example description",
        filename: "pexels-weldi-studio-design-8675556 (1080p)_1695741398500.mp4",
        url: "http://localhost:9001/api/image/files/pexels-weldi-studio-design-8675556 (1080p)_1695741398500.mp4"
      }
    ],
    altImageBackground: "Probando alt",
    top_mobile: "Descripción para vista mobile probando probando ....",
    imageCarousel: [
      {
        _id: "637d0a7c662dbe83a4690528",
        title: "Tensoblock Carousel",
        description: "icono",
        filename: "icon-tensoblock_1669139068438.svg",
        url: "http://localhost:9001/api/image/files/icon-tensoblock_1669139068438.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637d0aa5662dbe83a469052a",
        title: "Tensoblock carousel hover",
        description: "Icono de carousel",
        filename: "icon-tensoblock-hover_1669139109959.svg",
        url: "http://localhost:9001/api/image/files/icon-tensoblock-hover_1669139109959.svg"
      }
    ],
    iconBenefit: "done",
    position: [
      {
        product: 0
      }
    ]
  },
  {
    _id: "62e9354759003436d937b86e",
    title: "VIGAS",
    titleSubDescription: "",
    type: "productoEspecial",
    top: "El sistema de vigas pretensadas para puentes Tensolite permite una mayor eficiencia en la ejecución y puesta en servicio del mismo. Esto se logra gracias a los beneficios que ofrecen los elementos prefabricados, evitando gastos de tiempo y materiales en la ejecución de obra “in situ”.",
    benefit: [
      {
        iconBenefit: "lightbulb",
        textBenefit: "Beneficio brillante",
        _id: "FETQSChycIyN"
      }
    ],
    instalationSteps: [],
    filename: [
      {
        filename: "1659980894951_Plano vibroprensados V02.pdf",
        url: "http://localhost:9001/api/product/files/1659980894951_Plano vibroprensados V02.pdf",
        title: "titulo2",
        category: " category2"
      },
      {
        filename: "1660139541456_Plano vibroprensados V02.pdf",
        url: "http://localhost:9001/api/product/files/1660139541456_Plano vibroprensados V02.pdf",
        title: "titulo2",
        category: " category2",
        deleted: false,
        state: false
      },
      {
        filename: "1660139983047_Plano vibroprensados V02.pdf",
        url: "http://localhost:9001/api/product/files/1660139983047_Plano vibroprensados V02.pdf",
        title: "tituloEjemplo",
        category: " categoryEjemplo",
        deleted: false,
        state: false
      }
    ],
    imageBackground: [
      {
        _id: "62e9247559003436d937b6d5",
        title: "VIGAS-2",
        description: "VIGAS-2",
        filename: "1659446389905_2.jpg",
        url: "http://localhost:9001/api/image/files/1659446389905_2.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "62e9247559003436d937b6d5",
        title: "VIGAS-2",
        description: "VIGAS-2",
        filename: "1659446389905_2.jpg",
        url: "http://localhost:9001/api/image/files/1659446389905_2.jpg"
      }
    ],
    imageInstalation: [],
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-08-02T10:57:55.426Z",
    __v: 0,
    imageIcon: [
      {
        _id: "62e92da859003436d937b731",
        title: "Vigas-ICON",
        description: "Vigas-ICON",
        filename: "1659448744052_Tensolite vigas lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448744052_Tensolite vigas lineal.svg"
      }
    ],
    imageGallery: [
      {
        _id: "62e91e1b59003436d937b684",
        title: "TEJALITE-2",
        description: "TEJALITE-21",
        filename: "1659444763539_6.jpg",
        url: "http://localhost:9001/api/image/files/1659444763539_6.jpg"
      },
      {
        _id: "62e91e2c59003436d937b686",
        title: "TEJALITE-3",
        description: "TEJALITE-3",
        filename: "1659444780566_7.jpg",
        url: "http://localhost:9001/api/image/files/1659444780566_7.jpg"
      },
      {
        _id: "62e91e3f59003436d937b688",
        title: "TEJALITE-4",
        description: "TEJALITE-4",
        filename: "1659444799729_9.jpg",
        url: "http://localhost:9001/api/image/files/1659444799729_9.jpg"
      },
      {
        _id: "62e91e4e59003436d937b68a",
        title: "TEJALITE-5",
        description: "TEJALITE-5",
        filename: "1659444814141_tejalite-2-Colocacion.jpg",
        url: "http://localhost:9001/api/image/files/1659444814141_tejalite-2-Colocacion.jpg"
      }
    ],
    imageLogo: [],
    imageInstallation: [],
    galleryEnabled: true,
    deleted: false,
    moduleConteiner: [],
    publish: false,
    instalationStepsEnabled: true,
    imageIconColor: [],
    imageLogoColor: [],
    imageProductAlpha: [],
    imageBackground_mobile: [],
    imageBackground_tablet: [],
    altImageBackground:
      "El sistema de vigas pretensadas para puentes Tensolite permite una mayor eficiencia.",
    imageCarousel: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    position: [
      {
        product: 0
      }
    ],
    faqsEnabled: false
  },
  {
    technicalSheet: null,
    _id: "62e934ec59003436d937b864",
    title: "ESTRUCTURAS",
    titleSubDescription: "",
    type: "productoEspecial",
    top: "Las estructuras premoldeadas brindan una solución ágil para su empresa, llegando en algunos casos a un plazo de ejecución de hasta 15 días hábiles. Poseen una excelente terminación y una alta resistencia a los agentes climáticos (debido a la utilización de hormigones elaborados bajo estrictas normas de calidad) (al agua y al fuego).\n\nLa trayectoria y experiencia de nuestro equipo, le permitirá encontrar la solución más eficiente a su proyecto. Bajo las condiciones adecuadas, se pueden diseñar distintas alternativas, teniendo en cuenta diversos factores.",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Lorem ipsum generator",
        _id: "2zUJyVxETzbN"
      }
    ],
    instalationSteps: [],
    filename: [],
    imageBackground: [
      {
        _id: "651dbd30f710fe9078c8a437",
        title: "Probando video",
        description: "video",
        filename: "disparos_1696447792001.mp4",
        url: "http://localhost:9001/api/image/files/disparos_1696447792001.mp4"
      }
    ],
    imageInstalation: [],
    imageProduct: [],
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-08-02T10:57:55.426Z",
    __v: 0,
    imageIcon: [
      {
        _id: "62e92d5459003436d937b729",
        title: "Estructura-ICON",
        description: "Estructura-ICON",
        filename: "1659448660449_Tensolite Estructura lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448660449_Tensolite Estructura lineal.svg"
      }
    ],
    imageGallery: [
      {
        _id: "62e9663487c6122939bbf9f3",
        title: "Slider-2",
        description: "Slider-2",
        filename: "1659463220976_tensolite-viguetas-t21.png",
        url: "http://localhost:9001/api/image/files/1659463220976_tensolite-viguetas-t21.png"
      },
      {
        _id: "62e968c687c6122939bbfa43",
        title: "Slider-6",
        description: "Slider-6",
        filename: "1659463878397_tensolite-bloques-tensoblock-1.png",
        url: "http://localhost:9001/api/image/files/1659463878397_tensolite-bloques-tensoblock-1.png"
      },
      {
        _id: "62e968a587c6122939bbfa41",
        title: "Slider-5",
        description: "Slider-5",
        filename: "1659463845302_tensolite-fabrica-2.png",
        url: "http://localhost:9001/api/image/files/1659463845302_tensolite-fabrica-2.png"
      },
      {
        _id: "637e00fe0031279b2466dcac",
        title: "Otros carousel",
        description: "Icono carousel",
        filename: "icon-otros_1669202174957.svg",
        url: "http://localhost:9001/api/image/files/icon-otros_1669202174957.svg"
      },
      {
        _id: "637d0bb5662dbe83a469053a",
        title: "Tensoquines carousel hover",
        description: "Icono para carousel",
        filename: "icon-tensoquin-hover_1669139381357.svg",
        url: "http://localhost:9001/api/image/files/icon-tensoquin-hover_1669139381357.svg"
      },
      {
        _id: "637281b6b34d51807b7a7ad9",
        title: "sadas",
        description: "dasdas",
        filename: "descarga.jfif_1668448694545",
        url: "http://localhost:9001/api/image/files/descarga.jfif_1668448694545"
      },
      {
        _id: "62f1312605b1dda7c213cdb0",
        title: "zxczxc",
        description: "xzczxc",
        filename: "1659973926083_descarga.png",
        url: "http://localhost:9001/api/image/files/1659973926083_descarga.png"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      },
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      },
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageLogo: [],
    imageInstallation: [],
    deleted: false,
    moduleConteiner: [],
    publish: true,
    galleryEnabled: true,
    instalationStepsEnabled: true,
    imageIconColor: [],
    imageLogoColor: [],
    imageProductAlpha: [],
    imageBackground_mobile: [],
    imageBackground_tablet: [],
    altImageBackground: "Las estructuras premoldeadas brindan una solución ágil para su empresa.",
    imageCarousel: [
      {
        _id: "637e00c90031279b2466dca8",
        title: "Estructuras carousel",
        description: "Icono carousel",
        filename: "icon-estructuras_1669202121469.svg",
        url: "http://localhost:9001/api/image/files/icon-estructuras_1669202121469.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637e00e20031279b2466dcaa",
        title: "Estructuras",
        description: "Icono carousel",
        filename: "icon-estructuras-hover_1669202146653.svg",
        url: "http://localhost:9001/api/image/files/icon-estructuras-hover_1669202146653.svg"
      }
    ],
    top_mobile:
      "Las estructuras premoldeadas brindan una solución ágil para su empresa, llegando en algunos casos",
    position: [
      {
        product: 1
      }
    ]
  },
  {
    _id: "635a9297bbbf42fb2e1c9c32",
    title: "CERCALITE",
    titleSubDescription: "Cercalite tu producto de confianza",
    type: "productoLinea",
    top: "Este producto te permite cercar tu terreno o tu campo...",
    benefit: [
      {
        iconBenefit: "asdasdas87788787a",
        textBenefit: "asdasdasdas",
        _id: "JEWmJIg6MhCJ"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [
      {
        filename: "ficha_tecnica_cercalite_.pdf",
        url: "http://localhost:9001/api/product/files/ficha_tecnica_cercalite_.pdf",
        title: "Probando cercalite",
        deleted: false,
        state: true
      }
    ],
    imageBackground: [
      {
        _id: "62e9658f87c6122939bbf9ce",
        title: "Slider-1",
        description: "Slider-1",
        filename: "1659463055419_tensolite-fabrica-acopio.png",
        url: "http://localhost:9001/api/image/files/1659463055419_tensolite-fabrica-acopio.png"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "62fcd86fb9d0590863e252ef",
        title: "paso4- Control de Niveles",
        description: "Control de Niveles",
        filename: "1660737647355_tensoblock-4-ControldeNiveles.svg",
        url: "http://localhost:9001/api/image/files/1660737647355_tensoblock-4-ControldeNiveles.svg"
      },
      {
        _id: "62fcd84fb9d0590863e252ed",
        title: "paso3- Juntas Verticales",
        description: "Juntas Verticales",
        filename: "1660737615665_tensoblock-3-JuntasVerticales.svg",
        url: "http://localhost:9001/api/image/files/1660737615665_tensoblock-3-JuntasVerticales.svg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-10-27T14:07:33.540Z",
    deleted: false,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    imageCarousel: [
      {
        _id: "637d0cbe662dbe83a4690546",
        title: "Cercalite carousel hover",
        description: "Icono para carousel",
        filename: "Cercas-OnMouseover_1669139646429.svg",
        url: "http://localhost:9001/api/image/files/Cercas-OnMouseover_1669139646429.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637d17ab662dbe83a4690f8d",
        title: "Cercalite",
        description: "Cercalite",
        filename: "Cercas_1669142443987.png",
        url: "http://localhost:9001/api/image/files/Cercas_1669142443987.png"
      }
    ],
    position: [
      {
        product: 1
      }
    ]
  },
  {
    _id: "62e9345d59003436d937b84b",
    title: "PASARELAS",
    titleSubDescription: "",
    type: "productoEspecial",
    top: "Como respuesta a la necesidad de cruces peatonales sobre\nrutas, autopistas, canales, etc. Tensolite desarrolló los elementos de hormigón premoldeados para la construcción de pasarelas. El montaje se efectúa con una o más grúas, en un tiempo muy reducido, lo que permite habilitar el puente...",
    subdescription: "<p>Probando</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Nuevo beneficio",
        _id: "NYVAXncUvj8P"
      }
    ],
    instalationSteps: [],
    filename: [
      {
        filename: "1661194708685_1661168111305_ficha-tecnica-tensoblock-linea-liviana.pdf",
        url: "http://localhost:9001/api/product/files/1661194708685_1661168111305_ficha-tecnica-tensoblock-linea-liviana.pdf",
        title: "Ficha Técnica",
        deleted: true,
        state: false
      },
      {
        filename: "1661197692403_Donac Di Bacco y Cia.pdf",
        url: "http://localhost:9001/api/product/files/1661197692403_Donac Di Bacco y Cia.pdf",
        title: "Ficha Técnica",
        deleted: false,
        state: true
      }
    ],
    imageBackground: [
      {
        _id: "62e9198e59003436d937b609",
        title: "Bovedilla-1",
        description: "Bovedilla-1",
        filename: "1659443598884_1.jpg",
        url: "http://localhost:9001/api/image/files/1659443598884_1.jpg"
      }
    ],
    imageInstalation: [],
    imageProduct: [
      {
        _id: "62e919c059003436d937b615",
        title: "Bovedilla-2",
        description: "Bovedilla-2",
        filename: "1659443648137_2.jpg",
        url: "http://localhost:9001/api/image/files/1659443648137_2.jpg"
      },
      {
        _id: "62e82d87f2c7ab70d66e1774",
        title: "Casa",
        description: "casa",
        filename: "1659383175013_123_202207141213575268.jpg",
        url: "http://localhost:9001/api/image/files/1659383175013_123_202207141213575268.jpg"
      }
    ],
    faqs: [
      {
        _id: 0.46708215600812397,
        question: "Nueva faq",
        answer: "<p>Nueva respuesta faq</p>",
        video: ""
      }
    ],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-08-02T10:57:55.426Z",
    __v: 0,
    imageIcon: [
      {
        _id: "62e92d7d59003436d937b72d",
        title: "Pasarela-ICON",
        description: "Pasarela-ICON",
        filename: "1659448701190_Tensolite pasarela lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448701190_Tensolite pasarela lineal.svg"
      }
    ],
    imageGallery: [],
    imageLogo: [],
    imageInstallation: [],
    deleted: false,
    moduleConteiner: [],
    publish: true,
    faqsEnabled: true,
    galleryEnabled: true,
    instalationStepsEnabled: true,
    imageIconColor: [],
    imageLogoColor: [],
    imageProductAlpha: [],
    imageBackground_mobile: [],
    imageBackground_tablet: [
      {
        _id: "62e919c059003436d937b615",
        title: "Bovedilla-2",
        description: "Bovedilla-2",
        filename: "1659443648137_2.jpg",
        url: "http://localhost:9001/api/image/files/1659443648137_2.jpg"
      }
    ],
    altImageBackground: "El montaje se efectúa con una o más grúas, en un tiempo muy reducido..",
    imageCarousel: [
      {
        _id: "637e01700031279b2466dcb0",
        title: "Pasarelas carousel",
        description: "Icono carousel",
        filename: "icon-pasarelas_1669202288193.svg",
        url: "http://localhost:9001/api/image/files/icon-pasarelas_1669202288193.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637e018d0031279b2466dcb2",
        title: "Pasarelas carousel hover",
        description: "Icono carousel",
        filename: "icon-pasarelas-hover_1669202317404.svg",
        url: "http://localhost:9001/api/image/files/icon-pasarelas-hover_1669202317404.svg"
      }
    ],
    iconBenefit: "sensors",
    position: [
      {
        product: 2
      }
    ]
  },
  {
    _id: "637cdac3662dbe83a468f377",
    title: "BOVEDILLA",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "Probando description..",
    top_mobile: "New description",
    subdescription: "<p>Contenido de prueba</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "sdgfsaw",
        _id: "D1RplxG327XD"
      }
    ],
    instalationSteps: [
      {
        stepDescription: "<p>PAso1</p>",
        stepTitle: "Paso1",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "63728276c7b6b28e12f75189",
            title: "undefined",
            description: "undefined",
            filename: "EPS94.jpg_1668448886135",
            url: "http://localhost:9001/api/image/files/EPS94.jpg_1668448886135"
          }
        ],
        _id: "37377944786a5031657a314a"
      },
      {
        stepDescription: "<p>PAso2</p>",
        stepTitle: "Paso2",
        stepWarningText: "",
        imageInstallation: [],
        _id: "79477a77334e7a6e30324a35"
      },
      {
        stepDescription: "<p>PAso3</p>",
        stepTitle: "Paso3",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "63728133cf7762454ac78f0a",
            title: "asd",
            description: "asdas",
            filename: "dhormigon134.jpg_undefined",
            url: "http://localhost:9001/api/image/files/dhormigon134.jpg_undefined"
          }
        ],
        _id: "4874504d596e78344152446e"
      }
    ],
    instalationStepsEnabled: true,
    filename: [
      {
        filename: "pedido (3)_.pdf",
        url: "http://localhost:9001/api/product/files/pedido (3)_.pdf",
        title: "NICO",
        deleted: false,
        state: false
      }
    ],
    altImageBackground: "dasdasdas",
    imageBackground: [
      {
        _id: "63641250c9a02f608433c407",
        title: "Probando",
        description: "Probando descripcion",
        filename: "1667502672973_1659466086412_slider-ot-2.jpg",
        url: "http://localhost:9001/api/image/files/1667502672973_1659466086412_slider-ot-2.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "62e9673987c6122939bbfa15",
        title: "Slider-4",
        description: "Slider-4",
        filename: "1659463481254_tensolite-fabrica-1.png",
        url: "http://localhost:9001/api/image/files/1659463481254_tensolite-fabrica-1.png"
      },
      {
        _id: "62e9668387c6122939bbfa04",
        title: "Slider-3",
        description: "Slider-3",
        filename: "1659463299954_123_202207141213575268.jpg",
        url: "http://localhost:9001/api/image/files/1659463299954_123_202207141213575268.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [
      {
        _id: "637d0acb662dbe83a469052c",
        title: "Bovedilla carousel",
        description: "Icono para carousel",
        filename: "icon-bovedilla_1669139147280.svg",
        url: "http://localhost:9001/api/image/files/icon-bovedilla_1669139147280.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637d0ae4662dbe83a469052e",
        title: "Bovedilla carousel hover",
        description: "icono de carousel",
        filename: "icon-bovedilla-hover_1669139172125.svg",
        url: "http://localhost:9001/api/image/files/icon-bovedilla-hover_1669139172125.svg"
      }
    ],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-11-22T13:01:49.788Z",
    deleted: false,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 2
      }
    ]
  },
  {
    _id: "62e919ed17fb9fac8211ef7e",
    title: "VIGUETAS T21",
    subtitle: "SISTEMAS",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Nuevo beneficio",
        _id: "U2vceYojFMgi"
      }
    ],
    productDetails: "Ejemplo de detalle",
    filename: [
      {
        filename: "1659443832499_manual-tecnico-t21.pdf",
        url: "http://localhost:9001/api/product/files/1659443832499_manual-tecnico-t21.pdf",
        title: "Manual Técnico",
        state: true,
        deleted: false
      },
      {
        filename: "PostesP10Fichatecnica__.pdf",
        url: "http://localhost:9001/api/product/files/PostesP10Fichatecnica__.pdf",
        title: "Probando",
        deleted: false,
        state: true
      }
    ],
    faqs: [
      {
        _id: 0.8194715413226177,
        question: "Probando faqs",
        answer: "<p>las faqsdasdasd&nbsp;</p>",
        video: ""
      },
      {
        _id: 0.16860652152610056,
        question: "Otra prueba",
        answer: "<h3>Generador</h3>\n<p>Lorem ipsum generator...</p>",
        video: "xyz"
      }
    ],
    category: "T21",
    subproduct: [
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bc1ffbbeccba963465d",
        title: "Bloque de 13 cm. para muro",
        dimension: "13 x 19 x 19 cm",
        weight: "6,5 kg",
        colour: ["color-negro", "color-terracota"],
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [
          "http://168.197.48.253:9001/api/image/files/bloque_t13.png",
          "http://168.197.48.253:9001/api/image/files/bloque_t13_2.png",
          "http://168.197.48.253:9001/api/image/files/bloque_t13_3.png"
        ],
        __v: 0,
        category: "T13",
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        quantity: "25",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        technicalProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productTitle: "Tensoblock",
        subCategory: "T13 U",
        groupByCategory: [],
        created_date: "2022-07-25T19:05:27.145Z",
        visible: false
      },
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bfbffbbeccba9634667",
        title: "Bloque para Encadenado en muro de 20 cm.",
        dimension: "19 x 19 x 19 cm",
        category: "T20",
        weight: "8,5 kg",
        quantity: "25",
        colour: ["color-negro", "color-terracota"],
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [
          "http://localhost:3002/api/image/files/image-subproduct-tensoblock1.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_2.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_3.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_4.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_5.png"
        ],
        __v: 0,
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        productTitle: "Tensoblock",
        subCategory: "T20 U",
        groupByCategory: [],
        created_date: "2022-07-25T14:17:16.800Z",
        visible: true
      }
    ],
    state: true,
    created_date: "2022-07-05T14:39:31.777Z",
    deleted_date: null,
    __v: 0,
    imageBackground: [
      {
        _id: "62e91d3c59003436d937b671",
        title: "T21-4",
        description: "T21-4",
        filename: "1659444540370_9.jpg",
        url: "http://localhost:9001/api/image/files/1659444540370_9.jpg"
      }
    ],
    titleSubDescription: "Eficiencia en estructuras",
    imageInstalation: [],
    imageLogo: [
      {
        _id: "62e984df6f628f3ed16293a8",
        title: "T21-Logo2",
        description: "T21-Logo2",
        filename: "1659471071707_Logo-Isologo-Horizontal_t21 blanco.png",
        url: "http://localhost:9001/api/image/files/1659471071707_Logo-Isologo-Horizontal_t21 blanco.png"
      }
    ],
    top: "Losas más livianas ahorrando hormigón, encofrado y tiempo. De igual resistencia que la losa maciza. La vigueta T21 es un elemento semirresistente que conforma la armadura de la losa. Sola no es un elemento estructural, por eso, debe complementarse con la capa de compresión.",
    imageProduct: [
      {
        _id: "62e91d4e59003436d937b673",
        title: "T21-5",
        description: "T21-5",
        filename: "1659444558548_12.jpg",
        url: "http://localhost:9001/api/image/files/1659444558548_12.jpg"
      },
      {
        _id: "62e91d2b59003436d937b66f",
        title: "T21-3",
        description: "T21-3",
        filename: "1659444523535_8.jpg",
        url: "http://localhost:9001/api/image/files/1659444523535_8.jpg"
      }
    ],
    groupByCategory: [
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60becffbbeccba9634665",
        title: "Bloque p/ muro de 20 cm. Mitad Frente Debilitado",
        dimension: ": 19 x 19 x 19",
        weight: "9",
        colour: [null],
        productId: "62b5f50f4517ba2c12a50c62",
        state: true,
        url: [
          "http://168.197.48.253:9001/api/image/files/bloque_t20_mitad_simil_piedra.png",
          "http://168.197.48.253:9001/api/image/files/bloque_t20_mitad_simil_piedra_2.png"
        ],
        __v: 0,
        category: "T20",
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productTitle: "Tensoblock",
        quantity: "25",
        subCategory: "T20 MFD",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        groupByCategory: []
      },
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bfbffbbeccba9634667",
        title: "Bloque para Encadenado en muro de 20 cm.",
        dimension: "19 x 19 x 19 cm",
        category: "T20",
        weight: "8,5 kg",
        quantity: "25",
        colour: ["color-negro", "color-terracota"],
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [
          "http://localhost:3002/api/image/files/image-subproduct-tensoblock1.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_2.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_3.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_4.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_5.png"
        ],
        __v: 0,
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        productTitle: "Tensoblock",
        subCategory: "T20 U",
        groupByCategory: [],
        created_date: "2022-07-25T14:17:16.800Z",
        visible: true
      }
    ],
    imageIcon: [
      {
        _id: "62e92d1159003436d937b725",
        title: "T21-ICON",
        description: "T21-ICON",
        filename: "1659448593900_Viguetas T21 lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448593900_Viguetas T21 lineal.svg"
      }
    ],
    type: "productoLinea",
    visible: true,
    instalationSteps: [
      {
        stepDescription:
          "<p>asdasdddddddddddddddddddddddddddddddddddddddddddddddddddsad asd as das dsaddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaasdsd sa dsa dasaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</p>",
        stepTitle: "Paso 1",
        stepWarningText: "",
        imageInstallation: [],
        _id: "637e5a4be6298f6b29bb6a19"
      },
      {
        stepDescription: "<p>asdasdasdas</p>",
        stepTitle: "Proando2",
        stepWarningText: "",
        imageInstallation: [],
        _id: "637e5a4be6298f6b29bb6a1a"
      }
    ],
    deleted: false,
    imageGallery: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      },
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "6512fbfd04db1c982b334898",
        title: "video-hormigon",
        description: "example",
        filename: "architecture_-_20901 (1080p)_1695742973200.mp4",
        url: "http://localhost:9001/api/image/files/architecture_-_20901 (1080p)_1695742973200.mp4"
      }
    ],
    imageInstallation: ["62fcd86fb9d0590863e252ef", "63641250c9a02f608433c407"],
    galleryEnabled: true,
    moduleConteiner: [],
    publish: true,
    faqsEnabled: true,
    instalationStepsEnabled: true,
    imageIconColor: [],
    imageLogoColor: [
      {
        _id: "62f146c79f2f68b4d369312b",
        title: "2",
        description: "2",
        filename: "1659979463541_calco-tejalite.jpg",
        url: "http://localhost:9001/api/image/files/1659979463541_calco-tejalite.jpg"
      },
      {
        _id: "62fe559426509c7ee84c6117",
        title: "Bovedilla-23",
        description: "Bovedilla-23",
        filename: "1660835220723_m-producto.png",
        url: "http://localhost:9001/api/image/files/1660835220723_m-producto.png"
      }
    ],
    imageProductAlpha: [],
    imageBackground_mobile: [],
    imageBackground_tablet: [
      {
        _id: "62f1351505b1dda7c213ce07",
        title: "11",
        description: "11",
        filename: "1659974933860_3.jpg",
        url: "http://localhost:9001/api/image/files/1659974933860_3.jpg"
      }
    ],
    altImageBackground:
      "La vigueta T21 es un elemento semirresistente que conforma la armadura de la losa.",
    imageCarousel: [
      {
        _id: "637d0bd8662dbe83a469053c",
        title: "Viguetas T-21 carousel",
        description: "Icono para productos",
        filename: "icon-viguetaT21_1669139416896.svg",
        url: "http://localhost:9001/api/image/files/icon-viguetaT21_1669139416896.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637d1777662dbe83a4690ea1",
        title: "Vigueta T-21",
        description: "Icono carousel",
        filename: "icon-viguetaT21-active_1669142391854.svg",
        url: "http://localhost:9001/api/image/files/icon-viguetaT21-active_1669142391854.svg"
      }
    ],
    position: [
      {
        product: 3
      }
    ]
  },
  {
    _id: "62e9351559003436d937b869",
    title: "TRIBUNAS",
    titleSubDescription: "Mi título de prueba",
    type: "productoEspecial",
    top: "Constituyen una solución ideal y rápida para aquellos clubes que deseen brindar a su hinchada, un producto altamente seguro, confiable y de excelente calidad.",
    subdescription: "<p>hola que tal</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Mi nuevo beneficio",
        _id: "uMrA60YPkjXl"
      }
    ],
    instalationSteps: [],
    filename: [],
    imageBackground: [
      {
        _id: "62e9243f59003436d937b6cd",
        title: "TRIBUNAS-3",
        description: "TRIBUNAS-3",
        filename: "1659446335292_3.jpg",
        url: "http://localhost:9001/api/image/files/1659446335292_3.jpg"
      }
    ],
    imageInstalation: [],
    imageProduct: [
      {
        _id: "63769571e2338e2839744748",
        title: "Probando",
        description: "Probvando",
        filename: "Calculadora_1668715889604.jpg",
        url: "http://localhost:9001/api/image/files/Calculadora_1668715889604.jpg"
      },
      {
        _id: "637281b6b34d51807b7a7ad9",
        title: "sadas",
        description: "dasdas",
        filename: "descarga.jfif_1668448694545",
        url: "http://localhost:9001/api/image/files/descarga.jfif_1668448694545"
      }
    ],
    faqs: [
      {
        _id: 0.43990297724036886,
        question: "Probando",
        answer: "<p>Probando 1. Pro</p>",
        video: ""
      },
      {
        _id: 0.9327454941203066,
        question: "soy pregunata 2",
        answer:
          '<table style="border-collapse: collapse; width: 100%; height: 44.7812px; border-width: 0px; border-style: solid;" border="1"><caption>Caption</caption><colgroup><col style="width: 49.913%;"><col style="width: 49.913%;"></colgroup>\n<tbody>\n<tr style="height: 22.3906px;">\n<td style="height: 22.3906px; border-width: 0px;">&nbsp;</td>\n<td style="height: 22.3906px; border-width: 0px;">&nbsp;</td>\n</tr>\n<tr style="height: 22.3906px;">\n<td style="height: 22.3906px; border-width: 0px;">&nbsp;</td>\n<td style="height: 22.3906px; border-width: 0px;">&nbsp;</td>\n</tr>\n</tbody>\n</table>',
        video: ""
      },
      {
        _id: 0.11086271785098134,
        question: "gfhgh",
        answer: "<p>ewtwet</p>",
        video: ""
      }
    ],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-08-02T10:57:55.426Z",
    __v: 0,
    imageIcon: [
      {
        _id: "62e92d9459003436d937b72f",
        title: "Tribuna-ICON",
        description: "Tribuna-ICON",
        filename: "1659448724646_Tensolite tribuna lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448724646_Tensolite tribuna lineal.svg"
      }
    ],
    imageGallery: [],
    imageLogo: [],
    imageInstallation: [],
    deleted: false,
    moduleConteiner: [],
    publish: false,
    galleryEnabled: true,
    instalationStepsEnabled: true,
    imageIconColor: [],
    imageLogoColor: [],
    imageProductAlpha: [],
    imageBackground_mobile: [],
    imageBackground_tablet: [],
    altImageBackground: "Constituyen una solución ideal y rápida para aquellos clubes ",
    faqsEnabled: true,
    imageCarousel: [
      {
        _id: "637e01b40031279b2466dcb4",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas_1669202356215.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas_1669202356215.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    position: [
      {
        product: 3
      }
    ]
  },
  {
    technicalSheet: null,
    _id: "62b5f5e04517ba2c12a50c66",
    title: "POSTES",
    description: "Postes",
    state: true,
    __v: 0,
    imageIcon: [
      {
        _id: "62e92ceb59003436d937b721",
        title: "Poste-ICON",
        description: "Poste-ICON",
        filename: "1659448555052_Postes P10 lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448555052_Postes P10 lineal.svg"
      }
    ],
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "NuevoEd",
        _id: "JPm41oDfpYuN"
      }
    ],
    created_date: "2022-07-22T11:59:46.351Z",
    groupByCategory: [
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b6053d2184f35aeab18e24",
        title: "VIGUETAS",
        dimension: null,
        weight: null,
        colour: [],
        productId: "62b5f5f64517ba2c12a50c68",
        state: true,
        url: [null],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60ac2ffbbeccba963464f",
        title: "Tejalite cierre cumbrera",
        dimension: null,
        weight: null,
        colour: ["Negro", "Terracota"],
        productId: "62b5f4d94517ba2c12a50c60",
        state: true,
        url: [
          "esquina_negro_2.png",
          "esquina_negro_1.png",
          "esquina_terracota_2.png",
          "esquina_terracota_1.png"
        ],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60ae1ffbbeccba9634651",
        title: "Tejalite cierre lateral",
        dimension: null,
        weight: null,
        colour: ["Negro", "Terracota"],
        productId: "62b5f4d94517ba2c12a50c60",
        state: true,
        url: [
          "cierre_lateral_negro_1.png",
          "cierre_lateral_negro_3.png",
          'cierre_lateral_negro_4.png"',
          'cierre_lateral_negro_2.png"',
          "cierre_lateral_terracota_2.png",
          "cierre_lateral_terracota_1.png",
          "cierre_lateral_terracota_3.png"
        ],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60af9ffbbeccba9634653",
        title: "Tejalite cruce de 3 vias",
        dimension: null,
        weight: null,
        colour: ["Negro", "Terracota"],
        productId: "62b5f4d94517ba2c12a50c60",
        state: true,
        url: [
          "cruce_de_tres_vias_negro_1.png",
          "cruce_de_tres_vias_negro_2.png",
          "cruce_de_tres_vias_negro_3.png",
          "cruce_de_tres_vias_terracota_1.png",
          "cruce_de_tres_vias_terracota_2.png",
          "cruce_de_tres_vias_terracota_3.png"
        ],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60b18ffbbeccba9634655",
        title: "Tejalite cubrera",
        dimension: null,
        weight: null,
        colour: ["Negro", "Terracota"],
        productId: "62b5f4d94517ba2c12a50c60",
        state: true,
        url: [
          "cubrera_negro_1.png",
          "cubrera_negro_2.png",
          "cubrera_terracota_1.png",
          "cubrera_terracota_2.png"
        ],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60b56ffbbeccba9634657",
        title: "Tejalite",
        dimension: null,
        weight: null,
        colour: ["Negro", "Terracota"],
        productId: "62b5f4d94517ba2c12a50c60",
        state: true,
        url: [
          "tejalite_negro_1.png",
          "tejalite_negro_2.png",
          "tejalite_negro_3.png",
          "tejalite_terracota_1.png",
          "tejalite_terracota_2.png",
          "tejalite_terracota_3.png"
        ],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60bb3ffbbeccba963465b",
        title: "Tensoblocks T9",
        dimension: null,
        weight: null,
        colour: [null],
        productId: "62b5f50f4517ba2c12a50c62",
        state: true,
        url: ["bloque_t9_1.png", "bloque_t9_2.png", "bloque_t9_3.png"],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60bccffbbeccba963465f",
        title: "Tensoblocks T20",
        dimension: null,
        weight: null,
        colour: [null],
        productId: "62b5f50f4517ba2c12a50c62",
        state: true,
        url: ['"bloque_comun_1.png', '"bloque_comun_2.png', '"bloque_comun_3.png'],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60bd6ffbbeccba9634661",
        title: "Tensoblocks T20 Liviano",
        dimension: null,
        weight: null,
        colour: [null],
        productId: "62b5f50f4517ba2c12a50c62",
        state: true,
        url: ["bloque_t20_1.png", "bloque_t20_2.png", "bloque_t20_3.png"],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60be1ffbbeccba9634663",
        title: "Tensoblocks T20 Mitad",
        dimension: null,
        weight: null,
        colour: [null],
        productId: "62b5f50f4517ba2c12a50c62",
        state: true,
        url: ["bloque_t20_1.png", "bloque_t20_2.png", "bloque_t20_3.png"],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60c96ffbbeccba9634669",
        title: "Adoquín Holando",
        dimension: null,
        weight: null,
        colour: [null],
        productId: "62b5f5274517ba2c12a50c64",
        state: true,
        url: ["adoquin_holando.png", "adoquin_holando_3.png", "adoquin_holando_2.png"],
        __v: 0
      },
      {
        groupByCategory: [],
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60ca4ffbbeccba963466b",
        title: "Adoquín Universal",
        dimension: null,
        weight: null,
        colour: [null],
        productId: "62b5f5274517ba2c12a50c64",
        state: true,
        url: ["adoquin_universal_3.png", "adoquin_universal_2.png", "adoquin_universal_1.png"],
        __v: 0
      }
    ],
    imageBackground: [
      {
        _id: "62e91c9b59003436d937b664",
        title: "Postes-5",
        description: "Postes-5",
        filename: "1659444379164_7.jpg",
        url: "http://localhost:9001/api/image/files/1659444379164_7.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "62e91c8359003436d937b662",
        title: "Postes-4",
        description: "Postes-4",
        filename: "1659444355887_5.jpg",
        url: "http://localhost:9001/api/image/files/1659444355887_5.jpg"
      },
      {
        _id: "62e91b8359003436d937b64d",
        title: "Postes-1",
        description: "Postes-1",
        filename: "1659444099477_1.jpg",
        url: "http://localhost:9001/api/image/files/1659444099477_1.jpg"
      },
      {
        _id: "62e82d87f2c7ab70d66e1774",
        title: "Casa",
        description: "casa",
        filename: "1659383175013_123_202207141213575268.jpg",
        url: "http://localhost:9001/api/image/files/1659383175013_123_202207141213575268.jpg"
      }
    ],
    imageInstalation: [],
    subproduct: [],
    instalationSteps: [],
    faqs: [],
    visible: false,
    deleted: false,
    top: "Ideales para ser usados en el cercado de campos deportivos, áreas industriales y barrios cerrados. Los postes Tensolite se fabrican con hormigón pretensado y vibrado, obteniéndose así gran resistencia, además de la calidad y dimensiones uniformes.",
    filename: [
      {
        filename: "1659382523515_ficha-tecnica-postes-olimpicos-rectos.pdf",
        url: "http://localhost:9001/api/product/files/1659382523515_ficha-tecnica-postes-olimpicos-rectos.pdf",
        title: "Ficha postes olímpicos"
      },
      {
        filename: "1659382561999_ficha-tecnica-postes-240-agro-r.pdf",
        url: "http://localhost:9001/api/product/files/1659382561999_ficha-tecnica-postes-240-agro-r.pdf",
        title: "Ficha postes rurales"
      },
      {
        filename: "1659642796357_manual-tensoquines.pdf",
        url: "http://localhost:9001/api/product/files/1659642796357_manual-tensoquines.pdf",
        title: "ejemplo para guardar2",
        category: "Categoria2"
      }
    ],
    type: "productoLinea",
    imageLogo: [
      {
        _id: "62e983ef6f628f3ed1629357",
        title: "Postes-Logo2",
        description: "Postes-Logo2",
        filename: "1659470831799_Logo-Isologo-Horizontal_p10 blanco.png",
        url: "http://localhost:9001/api/image/files/1659470831799_Logo-Isologo-Horizontal_p10 blanco.png"
      }
    ],
    subtitle: "INSTALACIÓN",
    imageGallery: [
      {
        _id: "62e92cd459003436d937b71f",
        title: "Bovedilla-ICON",
        description: "Bovedilla-ICON",
        filename: "1659448532559_Bovedillas LT lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448532559_Bovedillas LT lineal.svg"
      }
    ],
    imageInstallation: ["62e8251af2c7ab70d66e1758"],
    galleryEnabled: true,
    moduleConteiner: [],
    publish: true,
    faqsEnabled: true,
    instalationStepsEnabled: false,
    imageIconColor: [],
    imageLogoColor: [
      {
        _id: "62e964a687c6122939bbf9a9",
        title: "Postes-Logo",
        description: "Postes-Logo",
        filename: "1659462822754_Logo-Isologo-Horizontal_p10 color.png",
        url: "http://localhost:9001/api/image/files/1659462822754_Logo-Isologo-Horizontal_p10 color.png"
      }
    ],
    imageProductAlpha: [],
    imageBackground_mobile: [],
    imageBackground_tablet: [],
    altImageBackground: "Probando el ALT en productos.",
    imageCarousel: [],
    imageCarouselHover: [],
    position: [
      {
        product: 4
      }
    ]
  },
  {
    _id: "62e9357a59003436d937b873",
    title: "OTROS",
    titleSubDescription: "",
    type: "productoEspecial",
    top: "Gran variedad de elementos pedidos a medida y realizados por Tensolite S.A. Algunos ejemplos: Comederos, Tetrápodos, Topes para estacionamiento, Pilotes, Monumentos, y mucho más.",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Soy un beneficio",
        _id: "hktZxnV1wtqV"
      }
    ],
    instalationSteps: [],
    filename: [
      {
        filename: "1659979926557_Plano vibroprensados V02.pdf",
        url: "http://localhost:9001/api/product/files/1659979926557_Plano vibroprensados V02.pdf",
        title: "Ejemplo titulo",
        category: "Ejemplo category"
      },
      {
        filename: "Manual de uso de SSFF - 1er Semestre 2023_.pdf",
        url: "http://localhost:9001/api/product/files/Manual de uso de SSFF - 1er Semestre 2023_.pdf",
        title: "Fanny",
        deleted: false,
        state: true
      }
    ],
    imageBackground: [
      {
        _id: "62e9247559003436d937b6d5",
        title: "VIGAS-2",
        description: "VIGAS-2",
        filename: "1659446389905_2.jpg",
        url: "http://localhost:9001/api/image/files/1659446389905_2.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "62e9243f59003436d937b6cd",
        title: "TRIBUNAS-3",
        description: "TRIBUNAS-3",
        filename: "1659446335292_3.jpg",
        url: "http://localhost:9001/api/image/files/1659446335292_3.jpg"
      }
    ],
    imageInstalation: [],
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-08-02T10:57:55.426Z",
    __v: 0,
    imageIcon: [
      {
        _id: "62e92d6759003436d937b72b",
        title: "Otros-ICON",
        description: "Otros-ICON",
        filename: "1659448679961_Tensolite otros lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448679961_Tensolite otros lineal.svg"
      }
    ],
    imageGallery: [],
    imageLogo: [],
    imageInstallation: [],
    deleted: false,
    moduleConteiner: [],
    publish: true,
    galleryEnabled: true,
    instalationStepsEnabled: true,
    imageIconColor: [],
    imageLogoColor: [],
    imageProductAlpha: [],
    imageBackground_mobile: [],
    imageBackground_tablet: [],
    altImageBackground:
      "Gran variedad de elementos pedidos a medida y realizados por Tensolite S.A. ",
    faqsEnabled: true,
    imageCarousel: [
      {
        _id: "637e00fe0031279b2466dcac",
        title: "Otros carousel",
        description: "Icono carousel",
        filename: "icon-otros_1669202174957.svg",
        url: "http://localhost:9001/api/image/files/icon-otros_1669202174957.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637e01190031279b2466dcae",
        title: "Otros carousel hover",
        description: "Icono carousel",
        filename: "icon-otros-hover_1669202201963.svg",
        url: "http://localhost:9001/api/image/files/icon-otros-hover_1669202201963.svg"
      }
    ],
    iconBenefit: "grade",
    position: [
      {
        product: 4
      }
    ]
  },
  {
    _id: "644bd91103e95b2932cbde36",
    title: "prueba 32",
    titleSubDescription: "dthmtyuhm",
    type: "productoLinea",
    top: " bla bla ",
    top_mobile: "swr",
    subdescription: "<p>yhmtrfyhmn&nbsp;</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "serdgtnb",
        _id: "O74Ll0k8Tc1x"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "uk.uyh",
    imageBackground: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-04-28T14:18:50.050Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 4
      }
    ]
  },
  {
    _id: "635fcc0a9736b8a1b93916bf",
    title: "POSTES P10",
    titleSubDescription: "",
    type: "productoLinea",
    top: "Descripción de prueba otra vez.",
    subdescription: "<p>Probando</p>",
    benefit: [
      {
        iconBenefit: "favorite",
        textBenefit: "Producto favorito por los clientes",
        _id: "jL6jPcx52nWT"
      }
    ],
    instalationSteps: [
      {
        stepDescription: "<p>Paso1</p>",
        stepTitle: "Paso1",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "63728276c7b6b28e12f75189",
            title: "undefined",
            description: "undefined",
            filename: "EPS94.jpg_1668448886135",
            url: "http://localhost:9001/api/image/files/EPS94.jpg_1668448886135"
          }
        ],
        _id: "43314356633977584c445045"
      },
      {
        stepDescription: "<p>Paso2</p>",
        stepTitle: "Paso22",
        stepWarningText: "",
        imageInstallation: [],
        _id: "51785a443341366734434131"
      },
      {
        stepDescription: "<p>Paso3&nbsp;</p>",
        stepTitle: "Paso3",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "636aa903acc9f287cfe1a685",
            title: "sasdsa",
            description: "sadas",
            filename: "1667934467910_Contacto.jpg",
            url: "http://localhost:9001/api/image/files/1667934467910_Contacto.jpg"
          }
        ],
        _id: "37306a66336c727578476b43"
      }
    ],
    instalationStepsEnabled: true,
    filename: [
      {
        filename: "manual_tecnico_Viguetas_T21__e (1)_.pdf",
        url: "http://localhost:9001/api/product/files/manual_tecnico_Viguetas_T21__e (1)_.pdf",
        title: "Hola",
        deleted: false,
        state: false
      }
    ],
    imageBackground: [
      {
        _id: "635aa4c5588b19b6627d6595",
        title: "Contacto",
        description: "Contacto",
        filename: "1666884805765_Contacto.jpg",
        url: "http://localhost:9001/api/image/files/1666884805765_Contacto.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63285af083ab3770ae8929ca",
        title: "aaa",
        description: "aaa",
        filename: "1663589104207_CapturaConId.PNG",
        url: "http://localhost:9001/api/image/files/1663589104207_CapturaConId.PNG"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "62f1315405b1dda7c213cdb7",
        title: "Bovedilla",
        description: "Bovedilla",
        filename: "1659973972782_3.jpg",
        url: "http://localhost:9001/api/image/files/1659973972782_3.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "635aa4c5588b19b6627d6595",
        title: "Contacto",
        description: "Contacto",
        filename: "1666884805765_Contacto.jpg",
        url: "http://localhost:9001/api/image/files/1666884805765_Contacto.jpg"
      },
      {
        _id: "63285af083ab3770ae8929ca",
        title: "aaa",
        description: "aaa",
        filename: "1663589104207_CapturaConId.PNG",
        url: "http://localhost:9001/api/image/files/1663589104207_CapturaConId.PNG"
      }
    ],
    imageProductAlpha: [
      {
        _id: "62fcdc78b9d0590863e2558f",
        title: "t13U",
        description: "tensoblock T13 U",
        filename: "1660738680880_T13 U.png",
        url: "http://localhost:9001/api/image/files/1660738680880_T13 U.png"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "62e984446f628f3ed1629372",
        title: "Tensoquines-Logo2",
        description: "Tensoquines-Logo2",
        filename: "1659470916073_Logo-Isologo-Horizontal_tensoquines blanco.png",
        url: "http://localhost:9001/api/image/files/1659470916073_Logo-Isologo-Horizontal_tensoquines blanco.png"
      }
    ],
    imageLogoColor: [
      {
        _id: "62f11f9b05b1dda7c213cc58",
        title: "Logo grupo tensolite",
        description: "Logo grupo tensolite",
        filename: "1659969435055_Logo-Isologo-Horizontal_grupo tensolite negro.png",
        url: "http://localhost:9001/api/image/files/1659969435055_Logo-Isologo-Horizontal_grupo tensolite negro.png"
      }
    ],
    imageIcon: [
      {
        _id: "62e92d7d59003436d937b72d",
        title: "Pasarela-ICON",
        description: "Pasarela-ICON",
        filename: "1659448701190_Tensolite pasarela lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448701190_Tensolite pasarela lineal.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-10-31T12:31:54.286Z",
    deleted: false,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    altImageBackground: "Inmobile",
    imageCarousel: [
      {
        _id: "637d0b04662dbe83a4690530",
        title: "Postes carousel",
        description: "Icono para carousel",
        filename: "icon-postes_1669139204143.svg",
        url: "http://localhost:9001/api/image/files/icon-postes_1669139204143.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637d0b21662dbe83a4690532",
        title: "Postes carousel hover",
        description: "Icono para carousel",
        filename: "icon-postes-hover_1669139233242.svg",
        url: "http://localhost:9001/api/image/files/icon-postes-hover_1669139233242.svg"
      }
    ],
    position: [
      {
        product: 5
      }
    ]
  },
  {
    _id: "645a3a32dbba09f1286b8f5e",
    title: "Roma",
    titleSubDescription: "Test",
    type: "productoEspecial",
    top: "1324",
    top_mobile: "1234",
    subdescription: "<p>12346</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Lorem",
        _id: "GB9x7DwJPkBd"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "",
    imageBackground: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-09T11:59:08.457Z",
    deleted: false,
    publish: true,
    position: [
      {
        product: 5
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "62e91b6417fb9fac8211ef7f",
    title: "VIGUETAS T180",
    subtitle: "SISTEMAS",
    subdescription: "<p>Probando el tiny</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Nuevo beneficio",
        _id: "GS3k5vIr0mH3"
      }
    ],
    productDetails: "Ejemplo de detalle",
    filename: [
      {
        filename: "1659444089944_manual-tecnico-t180.pdf",
        url: "http://localhost:9001/api/product/files/1659444089944_manual-tecnico-t180.pdf",
        title: "Manual Técnico"
      }
    ],
    faqs: [
      {
        _id: 0.70448211535675,
        question: "Nueva pregunta",
        answer: "<p>Nueva respuesta</p>",
        video: "glph"
      },
      {
        _id: 0.5573641642794493,
        question: "Nueva pregunta 2",
        answer: "<p>alkfjaldjfladfjlajdfljdlfjdlfj&ntilde;</p>",
        video: ""
      }
    ],
    category: "T21",
    subproduct: [
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bc1ffbbeccba963465d",
        title: "Bloque de 13 cm. para muro",
        dimension: "13 x 19 x 19 cm",
        weight: "6,5 kg",
        colour: ["color-negro", "color-terracota"],
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [
          "http://168.197.48.253:9001/api/image/files/bloque_t13.png",
          "http://168.197.48.253:9001/api/image/files/bloque_t13_2.png",
          "http://168.197.48.253:9001/api/image/files/bloque_t13_3.png"
        ],
        __v: 0,
        category: "T13",
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        quantity: "25",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        technicalProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productTitle: "Tensoblock",
        subCategory: "T13 U",
        groupByCategory: [],
        created_date: "2022-07-25T19:05:27.145Z",
        visible: false
      },
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bfbffbbeccba9634667",
        title: "Bloque para Encadenado en muro de 20 cm.",
        dimension: "19 x 19 x 19 cm",
        category: "T20",
        weight: "8,5 kg",
        quantity: "25",
        colour: ["color-negro", "color-terracota"],
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [
          "http://localhost:3002/api/image/files/image-subproduct-tensoblock1.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_2.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_3.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_4.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_5.png"
        ],
        __v: 0,
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        productTitle: "Tensoblock",
        subCategory: "T20 U",
        groupByCategory: [],
        created_date: "2022-07-25T14:17:16.800Z",
        visible: true
      }
    ],
    state: true,
    created_date: "2022-07-05T14:39:31.777Z",
    deleted_date: null,
    __v: 0,
    imageBackground: [
      {
        _id: "62e9663487c6122939bbf9f3",
        title: "Slider-2",
        description: "Slider-2",
        filename: "1659463220976_tensolite-viguetas-t21.png",
        url: "http://localhost:9001/api/image/files/1659463220976_tensolite-viguetas-t21.png"
      }
    ],
    titleSubDescription: "Eficiencia en estructuras",
    imageInstalation: [],
    imageLogo: [
      {
        _id: "62e985166f628f3ed16293af",
        title: "T180-Logo2",
        description: "T180-Logo2",
        filename: "1659471126846_Logo-Isologo-Horizontal_t180 blanco.png",
        url: "http://localhost:9001/api/image/files/1659471126846_Logo-Isologo-Horizontal_t180 blanco.png"
      }
    ],
    top: "Unidades de un sistema constructivo más ágil, eficiente y económico que el tradicional. Al ser de hormigón vibrocomprimido de alta calidad, pueden trabajar como encofrados perdidos para construir columnas y vigas en su interior, además de poder insertar las instalaciones logrando",
    imageProduct: [
      {
        _id: "62e91d9459003436d937b67b",
        title: "T180-4",
        description: "T180-4",
        filename: "1659444628910_4.jpg",
        url: "http://localhost:9001/api/image/files/1659444628910_4.jpg"
      },
      {
        _id: "62e91d7059003436d937b675",
        title: "T180-1",
        description: "T180-1",
        filename: "1659444592292_1.jpg",
        url: "http://localhost:9001/api/image/files/1659444592292_1.jpg"
      }
    ],
    groupByCategory: [
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        created_date: "2022-07-28T10:56:38.905Z",
        _id: "62b60becffbbeccba9634665",
        title: "Bloque p/ muro de 20 cm. Mitad Frente Debilitado",
        dimension: ": 19 x 19 x 19",
        weight: "9",
        colour: [null],
        productId: "62b5f50f4517ba2c12a50c62",
        state: true,
        url: [
          "http://168.197.48.253:9001/api/image/files/bloque_t20_mitad_simil_piedra.png",
          "http://168.197.48.253:9001/api/image/files/bloque_t20_mitad_simil_piedra_2.png"
        ],
        __v: 0,
        category: "T20",
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productTitle: "Tensoblock",
        quantity: "25",
        subCategory: "T20 MFD",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        groupByCategory: []
      },
      {
        technicalSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicalProductSheet:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        technicaProductManual:
          "http://168.197.48.253:9001/api/product/files/1657050968536-manual-tensoblock.pdf",
        _id: "62b60bfbffbbeccba9634667",
        title: "Bloque para Encadenado en muro de 20 cm.",
        dimension: "19 x 19 x 19 cm",
        category: "T20",
        weight: "8,5 kg",
        quantity: "25",
        colour: ["color-negro", "color-terracota"],
        imageLogo: "http://168.197.48.253:9001/api/image/files/Tensoblock.png",
        productId: "62c44d28a2d699d736aefc60",
        state: true,
        url: [
          "http://localhost:3002/api/image/files/image-subproduct-tensoblock1.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_2.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_3.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_4.png",
          "http://localhost:3002/api/image/files/bloque_t20_simil_piedra_5.png"
        ],
        __v: 0,
        description:
          "La nueva línea liviana se caracteriza por su mayor eficiencia: Gracias a mejoras en su diseño, se logra un producto más liviano, con un manipuleo más ágil, cortes más fáciles y ahorro en transporte del producto.",
        subdescription:
          "La utilización del Bloque de Hormigón, combinado con el empleo de elementos premoldeados, constituye un sistema de prefabricación completo ya que cumple con el objetivo de ser económico. El bloque hueco de hormigón es un elemento que satisface ampliamente las condiciones técnico-económicas necesarias para ser empleado en las construcciones de hoy.",
        productTitle: "Tensoblock",
        subCategory: "T20 U",
        groupByCategory: [],
        created_date: "2022-07-25T14:17:16.800Z",
        visible: true
      }
    ],
    imageIcon: [
      {
        _id: "62e92d2259003436d937b727",
        title: "T180-ICON",
        description: "T180-ICON",
        filename: "1659448610715_Viguetas T180 lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448610715_Viguetas T180 lineal.svg"
      }
    ],
    type: "productoLinea",
    visible: true,
    instalationSteps: [
      {
        stepDescription: "<p>Paso 1</p>",
        stepTitle: "Paso 1",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "62f132ee05b1dda7c213cdd1",
            title: "<",
            description: "<",
            filename: "1659974382711_m-producto.png",
            url: "http://localhost:9001/api/image/files/1659974382711_m-producto.png"
          }
        ],
        _id: "59376578546d746d34576432"
      },
      {
        stepDescription: "<p>Paso 2</p>",
        stepTitle: "Paso 2",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "63728133cf7762454ac78f0a",
            title: "asd",
            description: "asdas",
            filename: "dhormigon134.jpg_undefined",
            url: "http://localhost:9001/api/image/files/dhormigon134.jpg_undefined"
          }
        ],
        _id: "4e6f67536b744571446b7a64"
      },
      {
        stepDescription: "<p>Paso 3</p>",
        stepTitle: "Paso 3",
        stepWarningText: "",
        imageInstallation: [],
        _id: "6c3079495a6f444c5a6d7866"
      },
      {
        stepDescription: "<p>Paso4</p>",
        stepTitle: "Paso 4",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "636aa903acc9f287cfe1a685",
            title: "sasdsa",
            description: "sadas",
            filename: "1667934467910_Contacto.jpg",
            url: "http://localhost:9001/api/image/files/1667934467910_Contacto.jpg"
          }
        ],
        _id: "336378426d76617576686349"
      }
    ],
    deleted: false,
    imageGallery: [],
    imageInstallation: [],
    galleryEnabled: true,
    moduleConteiner: [],
    publish: true,
    faqsEnabled: true,
    instalationStepsEnabled: true,
    imageIconColor: [],
    imageLogoColor: [
      {
        _id: "62e9581c59003436d937bae4",
        title: "T180-Logo",
        description: "T180-Logo",
        filename: "1659459612177_calco-t180.jpg",
        url: "http://localhost:9001/api/image/files/1659459612177_calco-t180.jpg"
      }
    ],
    imageProductAlpha: [],
    imageBackground_mobile: [],
    imageBackground_tablet: [
      {
        _id: "62fcdc78b9d0590863e2558f",
        title: "t13U",
        description: "tensoblock T13 U",
        filename: "1660738680880_T13 U.png",
        url: "http://localhost:9001/api/image/files/1660738680880_T13 U.png"
      }
    ],
    altImageBackground:
      "Gracias a su perfil más robusto puede soportar ciertas cargas por sí sola. ",
    top_mobile:
      "Unidades de un sistema constructivo más ágil, eficiente y económico que el tradicional. Al ser de hormigón vibrocomprimido de alta calidad ahora",
    imageCarousel: [
      {
        _id: "637d0c16662dbe83a4690540",
        title: "Viguetas T-180 Carousel",
        description: "Icono para carousel",
        filename: "icon-viguetaT180_1669139478322.svg",
        url: "http://localhost:9001/api/image/files/icon-viguetaT180_1669139478322.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637d0c3e662dbe83a4690542",
        title: "Viguetas T-180 Carousel",
        description: "Icono para carousel",
        filename: "icon-viguetaT180-hover_1669139518909.svg",
        url: "http://localhost:9001/api/image/files/icon-viguetaT180-hover_1669139518909.svg"
      }
    ],
    iconBenefit: "subject",
    position: [
      {
        product: 6
      }
    ]
  },
  {
    _id: "64395e8768865a39dc105134",
    title: "Probo",
    titleSubDescription: "",
    type: "productoEspecial",
    top: "Descripcion de prueba",
    top_mobile: "bla bla bla...",
    subdescription: "",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "asf",
        _id: "zNzaJQPQtVnY"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-14T13:51:24.163Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 7
      }
    ]
  },
  {
    _id: "645a6cb4ac1657bb6ab19495",
    title: "Prueba 22",
    titleSubDescription: "fgh",
    type: "productoLinea",
    top: "descri",
    top_mobile: "descrip",
    subdescription: "<p>fgh</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "bedt",
        _id: "y7Ms335ozYup"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "poiu",
    imageBackground: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-09T15:15:24.975Z",
    deleted: false,
    publish: true,
    position: [
      {
        product: 7
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "645a7f70ac1657bb6ab1b010",
    title: "Prueba nro 3",
    titleSubDescription: "b",
    type: "productoEspecial",
    top: "b",
    top_mobile: "b",
    subdescription: "<p>b</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "b",
        _id: "tdexsFNyV2PD"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [
      {
        filename: "Presupuesto-13451 (1)_.pdf",
        url: "http://localhost:9001/api/product/files/Presupuesto-13451 (1)_.pdf",
        title: "Prueba de PDF",
        deleted: false,
        state: true
      }
    ],
    altImageBackground: "b",
    imageBackground: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageProduct: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-05-09T15:15:24.975Z",
    deleted: false,
    publish: true,
    position: [
      {
        product: 7
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "645cd786120010d697ce1803",
    title: "SITENSO POSTES",
    titleSubDescription: "Lorem",
    type: "productoLinea",
    top: "Lorem ipsum generator.",
    top_mobile: "Lorem ipsum generator.",
    subdescription: "<p>Esto es una prueba...</p>",
    benefit: [
      {
        iconBenefit: "grade",
        textBenefit: "Nuevo beneficio!!",
        _id: "d4g4brqhewpI"
      },
      {
        iconBenefit: "swap_horiz",
        textBenefit: "Otro!",
        _id: "ImvdeOlNfgRr"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "sitenso-postes",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageProduct: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageProductAlpha: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      },
      {
        _id: "637e00fe0031279b2466dcac",
        title: "Otros carousel",
        description: "Icono carousel",
        filename: "icon-otros_1669202174957.svg",
        url: "http://localhost:9001/api/image/files/icon-otros_1669202174957.svg"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageLogoColor: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageIcon: [
      {
        _id: "637e00fe0031279b2466dcac",
        title: "Otros carousel",
        description: "Icono carousel",
        filename: "icon-otros_1669202174957.svg",
        url: "http://localhost:9001/api/image/files/icon-otros_1669202174957.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageCarousel: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-11T11:41:18.970Z",
    deleted: true,
    publish: true,
    position: [
      {
        product: 7
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "645a31483e4c70b9a245219d",
    title: "Probando probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "asdasdas",
    top_mobile: "as das das",
    subdescription: "<p>d asdas das das</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "sad as das ",
        _id: "XvwQGs17xtGG"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "sadasdas ",
    imageBackground: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-09T11:38:33.172Z",
    deleted: true,
    publish: true,
    position: [
      {
        product: 8
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "645a6c21ac1657bb6ab191ed",
    title: "Prueba 1",
    titleSubDescription: "asd",
    type: "productoLinea",
    top: "des",
    top_mobile: "desc",
    subdescription: "<p>asdf</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "bedt",
        _id: "z0gYmFDXzCgR"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "prueba ",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-09T15:15:24.975Z",
    deleted: true,
    publish: true,
    position: [
      {
        product: 9
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "645a7f6eac1657bb6ab1aff0",
    title: "Prueba nro 2",
    titleSubDescription: "a",
    type: "productoLinea",
    top: "a",
    top_mobile: "a",
    subdescription: "<p>a</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "bedt",
        _id: "wN6WxTUxza69"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "a",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageProduct: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-09T15:15:24.975Z",
    deleted: true,
    publish: true,
    position: [
      {
        product: 9
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "645a37d2dbba09f1286b820e",
    title: "Asiria",
    titleSubDescription: "Mi subtítulo",
    type: "productoEspecial",
    top: "Lorem ipsum",
    top_mobile: "Lorem ipsum",
    subdescription: "<p>Esto es una prueba...</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "lorem ipsum generator",
        _id: "8AgfU69Veesp"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "meso",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-09T11:59:08.457Z",
    deleted: true,
    publish: true,
    position: [
      {
        product: 13
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "6442e173864fb36973624410",
    title: "Hola",
    titleSubDescription: "adfad",
    type: "productoLinea",
    top: "dlfkajdl",
    top_mobile: "adfad",
    subdescription: "<p>adfadf</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "flañdjflñadj",
        _id: "EP1NakHExvNB"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "",
    imageBackground: [
      {
        _id: "62e9668387c6122939bbfa04",
        title: "Slider-3",
        description: "Slider-3",
        filename: "1659463299954_123_202207141213575268.jpg",
        url: "http://localhost:9001/api/image/files/1659463299954_123_202207141213575268.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProductAlpha: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageLogoColor: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      },
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageCarousel: [
      {
        _id: "637e01b40031279b2466dcb4",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas_1669202356215.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas_1669202356215.svg"
      }
    ],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-21T19:15:47.031Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 15
      }
    ]
  },
  {
    _id: "64594dec4f3fd73bf8e8e9af",
    title: "Probandingsadas",
    titleSubDescription: "as d asd as ",
    type: "productoLinea",
    top: "d sadas das dasdsd",
    top_mobile: " asd asd as",
    subdescription: "<p>sa das das&nbsp;</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "asd asd as",
        _id: "QKln0N8br5WE"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "sd as dasdas",
    imageBackground: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-08T19:28:20.137Z",
    deleted: true,
    publish: true,
    position: [
      {
        product: 16
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "635ad0d5ed92c5566ffa53da",
    title: "Cercalite",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "Desde aqui probando",
    benefit: [],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    imageBackground: [
      {
        _id: "635aa4c5588b19b6627d6595",
        title: "Contacto",
        description: "Contacto",
        filename: "1666884805765_Contacto.jpg",
        url: "http://localhost:9001/api/image/files/1666884805765_Contacto.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "62f132bb05b1dda7c213cdcf",
        title: "aaa",
        description: "aaa",
        filename: "1659974331079_2.jpg",
        url: "http://localhost:9001/api/image/files/1659974331079_2.jpg"
      },
      {
        _id: "62f131c205b1dda7c213cdbe",
        title: "czxcz",
        description: "zxxxx",
        filename: "1659974082361_1.jpg",
        url: "http://localhost:9001/api/image/files/1659974082361_1.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2022-10-27T18:34:32.552Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    imageCarousel: [],
    imageCarouselHover: [],
    position: [
      {
        product: 18
      }
    ]
  },
  {
    _id: "637f5571e5fe219c9b99bde6",
    title: "CerTejalite",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "Probando",
    top_mobile: "",
    subdescription: "<p>Probando tiny</p>",
    benefit: [],
    instalationSteps: [
      {
        stepDescription: "<p>Paso 1</p>",
        stepTitle: "Paso 1 ",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "63728276c7b6b28e12f75189",
            title: "undefined",
            description: "undefined",
            filename: "EPS94.jpg_1668448886135",
            url: "http://localhost:9001/api/image/files/EPS94.jpg_1668448886135"
          }
        ],
        _id: "714269707859673961585035"
      },
      {
        stepDescription: "<p>Paso2&nbsp;</p>",
        stepTitle: "Paso 2",
        stepWarningText: "",
        imageInstallation: [],
        _id: "475267733069496650754a6e"
      },
      {
        stepDescription: "<p>Paso 3</p>",
        stepTitle: "Paso 3",
        stepWarningText: "",
        imageInstallation: [
          {
            _id: "62fcd835b9d0590863e252eb",
            title: "paso2- Juntas Horizontales",
            description: "Juntas Horizontales",
            filename: "1660737589920_tensoblock-2-JuntasHorizontales.svg",
            url: "http://localhost:9001/api/image/files/1660737589920_tensoblock-2-JuntasHorizontales.svg"
          }
        ],
        _id: "7967534e4455474743536b71"
      }
    ],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "",
    imageBackground: [
      {
        _id: "63769571e2338e2839744748",
        title: "Probando",
        description: "Probvando",
        filename: "Calculadora_1668715889604.jpg",
        url: "http://localhost:9001/api/image/files/Calculadora_1668715889604.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "636aa903acc9f287cfe1a685",
        title: "sasdsa",
        description: "sadas",
        filename: "1667934467910_Contacto.jpg",
        url: "http://localhost:9001/api/image/files/1667934467910_Contacto.jpg"
      },
      {
        _id: "6364060e5e197f5c81f80cc3",
        title: "Cercalite",
        description: "Cercalite",
        filename: "1667499534953_Calculadora.jpg",
        url: "http://localhost:9001/api/image/files/1667499534953_Calculadora.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [
      {
        _id: "637d0c3e662dbe83a4690542",
        title: "Viguetas T-180 Carousel",
        description: "Icono para carousel",
        filename: "icon-viguetaT180-hover_1669139518909.svg",
        url: "http://localhost:9001/api/image/files/icon-viguetaT180-hover_1669139518909.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637e00e20031279b2466dcaa",
        title: "Estructuras",
        description: "Icono carousel",
        filename: "icon-estructuras-hover_1669202146653.svg",
        url: "http://localhost:9001/api/image/files/icon-estructuras-hover_1669202146653.svg"
      }
    ],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2022-11-23T20:06:00.496Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 19
      }
    ]
  },
  {
    _id: "640b31e9ab194a99b97d286e",
    title: "Roma",
    titleSubDescription: "Lorem ipsum generator",
    type: "productoLinea",
    top: "Roma Invicta!",
    top_mobile: "Roma Invicta!",
    subdescription:
      '<p>Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta Roma Invicta.</p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<td>links</td>\n<td>links</td>\n</tr>\n<tr>\n<td>hola</td>\n<td>hola</td>\n</tr>\n</tbody>\n</table>',
    benefit: [
      {
        iconBenefit: "star_rate",
        textBenefit: "Beneficio personalizado Roma Invicta",
        _id: "K6Jz4BDy2Kzg"
      },
      {
        iconBenefit: "visibility_off",
        textBenefit: "Beneficio personalizado 3",
        _id: "R4E9zF1PwkFT"
      },
      {
        iconBenefit: "account_balance",
        textBenefit: "Alea iacta est",
        _id: "fCKZvTOeBgwE"
      },
      {
        iconBenefit: "settings",
        textBenefit: "Roma Aeterna",
        _id: "BU59ApKArs3f"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Roma",
    imageBackground: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageLogoColor: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageIcon: [
      {
        _id: "62e92d6759003436d937b72b",
        title: "Otros-ICON",
        description: "Otros-ICON",
        filename: "1659448679961_Tensolite otros lineal.svg",
        url: "http://localhost:9001/api/image/files/1659448679961_Tensolite otros lineal.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageCarousel: [
      {
        _id: "637e00fe0031279b2466dcac",
        title: "Otros carousel",
        description: "Icono carousel",
        filename: "icon-otros_1669202174957.svg",
        url: "http://localhost:9001/api/image/files/icon-otros_1669202174957.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637e01190031279b2466dcae",
        title: "Otros carousel hover",
        description: "Icono carousel",
        filename: "icon-otros-hover_1669202201963.svg",
        url: "http://localhost:9001/api/image/files/icon-otros-hover_1669202201963.svg"
      }
    ],
    galleryEnabled: true,
    faqs: [
      {
        _id: 0.30431730914909316,
        question: "Nueva pregunta",
        answer: "<p>adljfadlfjla&ntilde;d</p>",
        video: "adfdfdd"
      },
      {
        _id: 0.4801907892846662,
        question: "Más preguntas!",
        answer:
          '<h3>Hola</h3>\n<p>chau</p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<td>sdf</td>\n<td>ad</td>\n</tr>\n<tr>\n<td>ad</td>\n<td>ad</td>\n</tr>\n</tbody>\n</table>',
        video: ""
      }
    ],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-10T10:59:58.296Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    faqsEnabled: true,
    position: [
      {
        product: 20
      }
    ]
  },
  {
    _id: "640f0d96a6714d146d98f713",
    title: "Dacia",
    titleSubDescription: "Lorem ipsum generator",
    type: "productoEspecial",
    top: "Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum gene",
    top_mobile:
      "Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum generator Lorem ipsum ",
    subdescription:
      '<p>Bla bla bla... Bla bla bla... Bla bla bla... Bla bla bla... Bla bla bla... Bla bla bla...&nbsp;</p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<th>Hola</th>\n<th>Chau</th>\n</tr>\n<tr>\n<td>Bla bla bla...</td>\n<td>Bla bla bla...</td>\n</tr>\n</tbody>\n</table>',
    benefit: [
      {
        iconBenefit: "account_balance_wallet",
        textBenefit: "etytryet",
        _id: "9SeGzP9Wzm14"
      },
      {
        iconBenefit: "arrow_right_alt",
        textBenefit: "Otro beneficio",
        _id: "9RqnpWreTVsW"
      },
      {
        iconBenefit: "delete_forever",
        textBenefit: "Nuevo beneficio",
        _id: "njoXTiRophAT"
      },
      {
        iconBenefit: "star_rate",
        textBenefit: "Beneficio estrella",
        _id: "X6UWmpoEaSlR"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Dacia",
    imageBackground: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "637e01b40031279b2466dcb4",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas_1669202356215.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas_1669202356215.svg"
      }
    ],
    imageLogoColor: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageIcon: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageCarousel: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-03-13T10:57:44.617Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 21
      }
    ]
  },
  {
    _id: "640f725ea7809c4f07f47945",
    title: "Pichone",
    titleSubDescription: "asd",
    type: "productoEspecial",
    top: "bla bla",
    top_mobile: "bla ...",
    subdescription: "",
    benefit: [
      {
        iconBenefit: "app_registration",
        textBenefit: "pichichone",
        _id: "GtlUTElTiopU"
      },
      {
        iconBenefit: "move_up ",
        textBenefit: "de la vega",
        _id: "r0OMPnIilBkD"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "pichon",
    imageBackground: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "62e9157359003436d937b5a1",
        title: "Logotipo blanco",
        description: "logotipoblanco",
        filename: "1659442547705_Logo-Isotipo_tensolite blanco.png",
        url: "http://localhost:9001/api/image/files/1659442547705_Logo-Isotipo_tensolite blanco.png"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageProduct: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageProductAlpha: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageLogoColor: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageIcon: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageCarousel: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    imageCarouselHover: [
      {
        _id: "62ea7aa04a1b2971f2649b8f",
        title: "logo tensolite",
        description: "logo",
        filename: "1659533984456_redes.png",
        url: "http://localhost:9001/api/image/files/1659533984456_redes.png"
      }
    ],
    galleryEnabled: true,
    faqs: [
      {
        _id: 0.2647315290916137,
        question: "Como lo hacemos?",
        answer:
          '<p><span style="background-color: rgb(255, 255, 255);"><strong>Longorito</strong></span></p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<td>A</td>\n<td>B</td>\n</tr>\n<tr>\n<td>primero</td>\n<td>segundo</td>\n</tr>\n</tbody>\n</table>\n<p>bla bla bla ...</p>',
        video: "https://www.youtube.com/shorts/7J58Sa4YwB4"
      }
    ],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-03-13T18:30:17.657Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    faqsEnabled: true,
    position: [
      {
        product: 22
      }
    ]
  },
  {
    _id: "64120f4a77e4c24eca98edc3",
    title: "Constantino",
    titleSubDescription: "",
    type: "productoLinea",
    top: "bonito",
    top_mobile: "bonito",
    subdescription: "",
    benefit: [
      {
        iconBenefit: "star_half",
        textBenefit: "bla ...",
        _id: "ceHUKVIJq1sS"
      },
      {
        iconBenefit: "switch_access_shortcut",
        textBenefit: "bla bla ...",
        _id: "bl75CNgMJaZV"
      },
      {
        iconBenefit: "swipe_vertical",
        textBenefit: "bla bla bla ...",
        _id: "xxkI0t6xNNIJ"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "pequeñito",
    imageBackground: [
      {
        _id: "637281b6b34d51807b7a7ad9",
        title: "sadas",
        description: "dasdas",
        filename: "descarga.jfif_1668448694545",
        url: "http://localhost:9001/api/image/files/descarga.jfif_1668448694545"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "637d0bb5662dbe83a469053a",
        title: "Tensoquines carousel hover",
        description: "Icono para carousel",
        filename: "icon-tensoquin-hover_1669139381357.svg",
        url: "http://localhost:9001/api/image/files/icon-tensoquin-hover_1669139381357.svg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "62e9157359003436d937b5a1",
        title: "Logotipo blanco",
        description: "logotipoblanco",
        filename: "1659442547705_Logo-Isotipo_tensolite blanco.png",
        url: "http://localhost:9001/api/image/files/1659442547705_Logo-Isotipo_tensolite blanco.png"
      }
    ],
    imageProduct: [
      {
        _id: "637283660fc5e75563add6ce",
        title: "asdas",
        description: "dasdasdasd",
        filename: "Banner5_1668449126338.jpg",
        url: "http://localhost:9001/api/image/files/Banner5_1668449126338.jpg"
      }
    ],
    imageProductAlpha: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageLogoColor: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageIcon: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [
      {
        _id: "637e01190031279b2466dcae",
        title: "Otros carousel hover",
        description: "Icono carousel",
        filename: "icon-otros-hover_1669202201963.svg",
        url: "http://localhost:9001/api/image/files/icon-otros-hover_1669202201963.svg"
      }
    ],
    imageCarouselHover: [
      {
        _id: "637d0b78662dbe83a4690536",
        title: "Tejalite carousel",
        description: "Icono para carousel",
        filename: "icon-tejalite-hover_1669139320275.svg",
        url: "http://localhost:9001/api/image/files/icon-tejalite-hover_1669139320275.svg"
      }
    ],
    galleryEnabled: true,
    faqs: [
      {
        _id: 0.14589875456446233,
        question: "herhbsdh",
        answer:
          '<p><strong>fsdghshjhrtj</strong></p>\n<p>&nbsp;</p>\n<table style="border-collapse: collapse; width: 100%; height: 30px;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr style="height: 15px;">\n<td style="height: 15px;">&lt;asdv</td>\n<td style="height: 15px;">&lt;svbsd</td>\n</tr>\n<tr style="height: 15px;">\n<td style="height: 15px;">zfd</td>\n<td style="height: 15px;">szfd</td>\n</tr>\n</tbody>\n</table>',
        video: ""
      }
    ],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-03-15T18:23:37.614Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    faqsEnabled: true,
    position: [
      {
        product: 23
      }
    ]
  },
  {
    _id: "64184259a23c2d75a1dc5c82",
    title: "max",
    titleSubDescription: "fgh",
    type: "productoEspecial",
    top: "asd",
    top_mobile: "ASD",
    subdescription:
      "<p>&lt;h2&gt;primero&lt;h2&gt;</p>\n<p>&lt;h3&gt;segundo&lt;h3&gt;</p>\n<p>&lt;p&gt;parrafo&lt;p&gt;</p>\n<p>&nbsp;</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "fg",
        _id: "ucFA1oy47Xtu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "ñlk",
    imageBackground: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "637e018d0031279b2466dcb2",
        title: "Pasarelas carousel hover",
        description: "Icono carousel",
        filename: "icon-pasarelas-hover_1669202317404.svg",
        url: "http://localhost:9001/api/image/files/icon-pasarelas-hover_1669202317404.svg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-03-20T11:04:29.346Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 24
      }
    ]
  },
  {
    _id: "641842b4a23c2d75a1dc5ca0",
    title: "simon",
    titleSubDescription: "",
    type: "productoLinea",
    top: "prueba",
    top_mobile: "mnb",
    subdescription: "",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "ñ",
        _id: "LVv1Ogz7csUJ"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "cvb",
    imageBackground: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-03-20T11:04:29.346Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 25
      }
    ]
  },
  {
    _id: "642431b25ecf723f37296580",
    title: "Pichon",
    titleSubDescription: "",
    type: "productoLinea",
    top: "Bla Bla Bla...",
    top_mobile: "bla bla bla...",
    subdescription: "",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "bla",
        _id: "5RgN0vHyAdvm"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "bonito...",
    imageBackground: [
      {
        _id: "637e00fe0031279b2466dcac",
        title: "Otros carousel",
        description: "Icono carousel",
        filename: "icon-otros_1669202174957.svg",
        url: "http://localhost:9001/api/image/files/icon-otros_1669202174957.svg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "635aa4c5588b19b6627d6595",
        title: "Contacto",
        description: "Contacto",
        filename: "1666884805765_Contacto.jpg",
        url: "http://localhost:9001/api/image/files/1666884805765_Contacto.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63641250c9a02f608433c407",
        title: "Probando",
        description: "Probando descripcion",
        filename: "1667502672973_1659466086412_slider-ot-2.jpg",
        url: "http://localhost:9001/api/image/files/1667502672973_1659466086412_slider-ot-2.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "62e927aa59003436d937b6ff",
        title: "OTROS-4",
        description: "OTROS-4",
        filename: "1659447210911_5.jpg",
        url: "http://localhost:9001/api/image/files/1659447210911_5.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [
      {
        _id: 0.03125925847425148,
        question: "Sabias que:",
        answer:
          '<p>&nbsp;</p>\n<p><em><strong>Prueba</strong></em></p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<td>A</td>\n<td>B</td>\n</tr>\n<tr>\n<td>primero</td>\n<td>&nbsp;2do</td>\n</tr>\n</tbody>\n</table>'
      }
    ],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-03-29T12:29:07.628Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    faqsEnabled: true,
    position: [
      {
        product: 26
      }
    ]
  },
  {
    _id: "6425c38f3cc745aa018208e9",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:14:52.410Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 27
      }
    ]
  },
  {
    _id: "6425c3a58793d815c6fe3446",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:15:14.863Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 28
      }
    ]
  },
  {
    _id: "6425c3c2264e8deb7b000403",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:15:43.637Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 29
      }
    ]
  },
  {
    _id: "6425c3c2264e8deb7b000405",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:15:43.637Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 30
      }
    ]
  },
  {
    _id: "6425c3c8264e8deb7b000408",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:15:43.637Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 31
      }
    ]
  },
  {
    _id: "6425c3d1264e8deb7b00040b",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:15:43.637Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 32
      }
    ]
  },
  {
    _id: "6425c547b0601ae8d05a9e13",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:22:13.152Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 33
      }
    ]
  },
  {
    _id: "6425c547b0601ae8d05a9e15",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:22:13.152Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 34
      }
    ]
  },
  {
    _id: "6425c547b0601ae8d05a9e17",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:22:13.152Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 35
      }
    ]
  },
  {
    _id: "6425c547b0601ae8d05a9e19",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:22:13.152Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 36
      }
    ]
  },
  {
    _id: "6425c58c9170e55e7e9a44e1",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:23:16.521Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 37
      }
    ]
  },
  {
    _id: "6425c5b7d48ec0bd6b1876c5",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:24:04.718Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 38
      }
    ]
  },
  {
    _id: "6425c5b7d48ec0bd6b1876c8",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:24:04.718Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 39
      }
    ]
  },
  {
    _id: "6425c5c99b65aec4f0998946",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:24:22.950Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 40
      }
    ]
  },
  {
    _id: "6425c5c99b65aec4f0998948",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:24:22.950Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 41
      }
    ]
  },
  {
    _id: "6425c6341ad42830a6fbfbde",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:26:10.252Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 42
      }
    ]
  },
  {
    _id: "6425c6341ad42830a6fbfbe0",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:26:10.252Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 43
      }
    ]
  },
  {
    _id: "6425c67392a40c7dc51cc651",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:27:12.487Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 44
      }
    ]
  },
  {
    _id: "6425c67392a40c7dc51cc653",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:27:12.487Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 45
      }
    ]
  },
  {
    _id: "6425c68496daa84df09f3ab5",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:27:29.843Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 46
      }
    ]
  },
  {
    _id: "6425c68a96daa84df09f3ab8",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:27:29.843Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 47
      }
    ]
  },
  {
    _id: "6425c6abb9d7df3ec1beb075",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:28:09.358Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 48
      }
    ]
  },
  {
    _id: "6425c735000e02580f35cc70",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:30:21.291Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 49
      }
    ]
  },
  {
    _id: "6425c754b3a3efaac297ac0c",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:30:57.730Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 50
      }
    ]
  },
  {
    _id: "6425c754b3a3efaac297ac0e",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:30:57.730Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 51
      }
    ]
  },
  {
    _id: "6425c782b3a3efaac297ac11",
    title: "Probando",
    titleSubDescription: "Contenido prueba",
    type: "productoLinea",
    top: "adsasd asdasdas ",
    top_mobile: "dasdas das d as d",
    subdescription: "<p>asd asd asdasdasa asd</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Probando",
        _id: "fF8JZMGSnhYu"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:30:57.730Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 52
      }
    ]
  },
  {
    _id: "6425d3f6b3a3efaac297bc73",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T17:30:57.730Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 53
      }
    ]
  },
  {
    _id: "6425d5335968d1ef7c6b74ad",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:29:57.201Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 54
      }
    ]
  },
  {
    _id: "6425d5443fcb6e47f2f54e7d",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:30:25.892Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 55
      }
    ]
  },
  {
    _id: "6425d5443fcb6e47f2f54e7f",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:30:25.892Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 56
      }
    ]
  },
  {
    _id: "6425d54a3fcb6e47f2f54e82",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:30:25.892Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 57
      }
    ]
  },
  {
    _id: "6425d554d60f54d4cd6c369f",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:30:42.313Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 58
      }
    ]
  },
  {
    _id: "6425d554d60f54d4cd6c36a1",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:30:42.313Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 59
      }
    ]
  },
  {
    _id: "6425d559d60f54d4cd6c3720",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:30:42.313Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 60
      }
    ]
  },
  {
    _id: "6425d581010be4ea3bee8398",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:31:27.025Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 61
      }
    ]
  },
  {
    _id: "6425d582010be4ea3bee839b",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:31:27.025Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 62
      }
    ]
  },
  {
    _id: "6425d5902150fb858255473b",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:31:41.744Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 63
      }
    ]
  },
  {
    _id: "6425d5912150fb858255473e",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:31:41.744Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 64
      }
    ]
  },
  {
    _id: "6425d5cd8936d57375a103f8",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:32:41.865Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 65
      }
    ]
  },
  {
    _id: "6425d5f33660848123f411d8",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:33:20.787Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 66
      }
    ]
  },
  {
    _id: "6425d5f43660848123f411db",
    title: "Auditorio",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "asdasdasd as d asd as d",
    top_mobile: "asd asd sad asd as ",
    subdescription: "<p>sadas das dsa das da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Auditoria",
        _id: "0OdLysZiGbyg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Auditoria",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:33:20.787Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 67
      }
    ]
  },
  {
    _id: "6425d6403660848123f4134f",
    title: "das das das",
    titleSubDescription: "dasdas das das",
    type: "productoLinea",
    top: " das das ",
    top_mobile: " as das dsa ",
    subdescription: "<p>d as dsa das das dsa das das sa</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "asdasdasddasdasdas",
        _id: "1pPxLNWynpho"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: " da sdsa das ",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63285af083ab3770ae8929ca",
        title: "aaa",
        description: "aaa",
        filename: "1663589104207_CapturaConId.PNG",
        url: "http://localhost:9001/api/image/files/1663589104207_CapturaConId.PNG"
      },
      {
        _id: "6364060e5e197f5c81f80cc3",
        title: "Cercalite",
        description: "Cercalite",
        filename: "1667499534953_Calculadora.jpg",
        url: "http://localhost:9001/api/image/files/1667499534953_Calculadora.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:33:20.787Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 68
      }
    ]
  },
  {
    _id: "6425d6724cb41f377b95d984",
    title: "das das das",
    titleSubDescription: "dasdas das das",
    type: "productoLinea",
    top: " das das ",
    top_mobile: " as das dsa ",
    subdescription: "<p>d as dsa das das dsa das das sa</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "asdasdasddasdasdas",
        _id: "1pPxLNWynpho"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: " da sdsa das ",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63285af083ab3770ae8929ca",
        title: "aaa",
        description: "aaa",
        filename: "1663589104207_CapturaConId.PNG",
        url: "http://localhost:9001/api/image/files/1663589104207_CapturaConId.PNG"
      },
      {
        _id: "6364060e5e197f5c81f80cc3",
        title: "Cercalite",
        description: "Cercalite",
        filename: "1667499534953_Calculadora.jpg",
        url: "http://localhost:9001/api/image/files/1667499534953_Calculadora.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T18:35:21.819Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 69
      }
    ]
  },
  {
    _id: "6425e3f1a5b0f16d2bed8d24",
    title: "Probanding",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "sd as das das das",
    top_mobile: " das das das dsa d",
    subdescription: "<p>asd sa das das das&nbsp;</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "asdasdasdasd",
        _id: "CLNZgpyWm2xf"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "asd sa dsa sad ",
    imageBackground: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-03-30T19:30:53.305Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 70
      }
    ]
  },
  {
    _id: "642d8a4ec70a74691ffc4b9c",
    title: "Tejas - Lite",
    titleSubDescription: "dghdghdghdgh",
    type: "productoLinea",
    top: "hdghdg",
    top_mobile: "dghdg",
    subdescription: "<p>hgfddghdgfhdgh</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "adlfjadlfjldjf",
        _id: "MVhGtU4WFlJe"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "bvcxvbxvbxv",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProductAlpha: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageIcon: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      },
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageCarousel: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-04-05T14:45:17.844Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 71
      }
    ]
  },
  {
    _id: "6433fe2933631088bc61d85e",
    title: "Teja---lite",
    titleSubDescription: "Contenido prueba",
    type: "",
    top: "asd asdas das asd ",
    top_mobile: " asd asd asd asdas",
    subdescription: "<p>as d asd as das</p>",
    benefit: [
      {
        iconBenefit: "chec",
        textBenefit: "asdasdas asd asd",
        _id: "ou9ggoRsIifV"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-10T11:51:57.763Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 72
      }
    ]
  },
  {
    _id: "6433ffd233631088bc61d86f",
    title: "tejacerca ?",
    titleSubDescription: "sadas das das",
    type: "",
    top: "dasdasd asd asd as d",
    top_mobile: "asd as dasd asd ",
    subdescription: "<p>&nbsp;asd asdas das das d</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "dasd as das das ",
        _id: "FRi2KfhmF74z"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "asdasdas",
    imageBackground: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      },
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-10T11:51:57.763Z",
    deleted: true,
    publish: false,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 73
      }
    ]
  },
  {
    _id: "64369e4cb294b3fbfb9a37ce",
    title: "Nuevo producto",
    titleSubDescription: "",
    type: "productoLinea",
    top: "dfadfdfadf",
    top_mobile: "dafadfadad",
    subdescription: "<p>dfadfd</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "hola",
        _id: "XEP67FLzTsPJ"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "dfad",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProduct: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProductAlpha: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageLogoColor: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageIcon: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageCarousel: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-12T11:46:39.388Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 74
      }
    ]
  },
  {
    _id: "6436ae96b294b3fbfb9ab187",
    title: "Tejones",
    titleSubDescription: "dfd",
    type: "productoEspecial",
    top: "bla bla bla...",
    top_mobile: "blba",
    subdescription: "<p>dfdfdfdf</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "hola",
        _id: "EXzbdmRJAYtY"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "df",
    imageBackground: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProduct: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageProductAlpha: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageLogoColor: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageIcon: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageCarousel: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-12T11:46:39.388Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 75
      }
    ]
  },
  {
    _id: "6436b9a8ec4c9851144cd3f2",
    title: "Probando123",
    titleSubDescription: "sad as das",
    type: "productoLinea",
    top: "asdasdsa",
    top_mobile: "dasdsadas",
    subdescription: "<p>dasdsad</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "adas das d",
        _id: "PCmEnRfjXpK7"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "asdsa dsa d",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      },
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-12T12:08:26.489Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 76
      }
    ]
  },
  {
    _id: "6436fcccca47497ea5351cbb",
    title: "Jota jota",
    titleSubDescription: "gsfg",
    type: "productoLinea",
    top: "kakakaka",
    top_mobile: "gsfgsfdg",
    subdescription: "<p>xbxvbv</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "ddfadfad",
        _id: "ukqa67YBnxZg"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProduct: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProductAlpha: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageInstallation: [],
    imageLogo: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageLogoColor: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageIcon: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageCarousel: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-12T18:38:18.957Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 77
      }
    ]
  },
  {
    _id: "644a917a42ce709b734eb0b6",
    title: "Prueba 1",
    titleSubDescription: "",
    type: "productoLinea",
    top: "bla ...",
    top_mobile: "bla bla ...",
    subdescription: "",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "prueba ",
        _id: "qD7VlOWoZAuO"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "prueba #1",
    imageBackground: [
      {
        _id: "62fcd791b9d0590863e252e7",
        title: "paso 1 Tensoblock",
        description: "Primera Hilada",
        filename: "1660737425666_paso1-tensoblock.png",
        url: "http://localhost:9001/api/image/files/1660737425666_paso1-tensoblock.png"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "6364060e5e197f5c81f80cc3",
        title: "Cercalite",
        description: "Cercalite",
        filename: "1667499534953_Calculadora.jpg",
        url: "http://localhost:9001/api/image/files/1667499534953_Calculadora.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "62fe559426509c7ee84c6117",
        title: "Bovedilla-23",
        description: "Bovedilla-23",
        filename: "1660835220723_m-producto.png",
        url: "http://localhost:9001/api/image/files/1660835220723_m-producto.png"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [
      {
        _id: "62e91e4e59003436d937b68a",
        title: "TEJALITE-5",
        description: "TEJALITE-5",
        filename: "1659444814141_tejalite-2-Colocacion.jpg",
        url: "http://localhost:9001/api/image/files/1659444814141_tejalite-2-Colocacion.jpg"
      },
      {
        _id: "62e91e3f59003436d937b688",
        title: "TEJALITE-4",
        description: "TEJALITE-4",
        filename: "1659444799729_9.jpg",
        url: "http://localhost:9001/api/image/files/1659444799729_9.jpg"
      },
      {
        _id: "62e91e2c59003436d937b686",
        title: "TEJALITE-3",
        description: "TEJALITE-3",
        filename: "1659444780566_7.jpg",
        url: "http://localhost:9001/api/image/files/1659444780566_7.jpg"
      },
      {
        _id: "62e91e1b59003436d937b684",
        title: "TEJALITE-2",
        description: "TEJALITE-21",
        filename: "1659444763539_6.jpg",
        url: "http://localhost:9001/api/image/files/1659444763539_6.jpg"
      },
      {
        _id: "62e91e0259003436d937b682",
        title: "TEJALITE-1",
        description: "TEJALITE-1",
        filename: "1659444738923_1.jpg",
        url: "http://localhost:9001/api/image/files/1659444738923_1.jpg"
      }
    ],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-27T15:04:19.540Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 78
      }
    ]
  },
  {
    _id: "644a9a24d62ce2a2e6d86658",
    title: "Tenso Olmi",
    titleSubDescription: "cvbntysg",
    type: "productoLinea",
    top: "zad",
    top_mobile: "adf",
    subdescription:
      '<p>rgbhesdrfnbhse</p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<td>&nbsp;</td>\n<td>&nbsp;</td>\n</tr>\n<tr>\n<td>&nbsp;</td>\n<td>&nbsp;</td>\n</tr>\n</tbody>\n</table>',
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "dalfjdña",
        _id: "M7QzcQJ2sghT"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "dfadfd",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      },
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-04-27T15:33:22.263Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 79
      }
    ]
  },
  {
    _id: "644bd81203e95b2932cbd71d",
    title: "zdetjh & sg",
    titleSubDescription: "tydu",
    type: "productoEspecial",
    top: "abh",
    top_mobile: "fznb",
    subdescription: "<p>mdtyum</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "bedt",
        _id: "sPD0vJXVvYgD"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "rbghedtr",
    imageBackground: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "637e01fa0031279b2466dcb8",
        title: "Vigas carousel",
        description: "Icono carousel",
        filename: "icon-vigas_1669202426461.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas_1669202426461.svg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "637e01cd0031279b2466dcb6",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas-hover_1669202381311.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas-hover_1669202381311.svg"
      }
    ],
    imageProduct: [
      {
        _id: "637e01b40031279b2466dcb4",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas_1669202356215.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas_1669202356215.svg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-28T14:18:50.050Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 80
      }
    ]
  },
  {
    _id: "644bd97f1267f0c0a480b03d",
    title: "Nicolas",
    titleSubDescription: "as das das d",
    type: "",
    top: "dsad as dasd",
    top_mobile: " sad as ",
    subdescription: "<p>a sdas das das das</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "sadsa dasd",
        _id: "A5hwRgYSH8ix"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "asd asda",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-04-28T14:34:11.182Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 81
      }
    ]
  },
  {
    technicalSheet: null,
    _id: "644beaa2513ed7e7f192f904",
    title: "Prueba 31",
    titleSubDescription: "pppppppppppppppppppppp",
    type: "productoLinea",
    top: "---------------------------",
    top_mobile: "yyyyyyyyyyyyyyyyyyyyyyyyyyyyyy",
    subdescription: "<p>qqqqqqqqqqqqqqqqqqqqqqqqqqq</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "bedt",
        _id: "BYJVAY0PDBqX"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "xxxxxxxxxxxxxxxxxx",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-04-28T15:33:16.945Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 82
      }
    ]
  },
  {
    _id: "644bed05513ed7e7f1930e3b",
    title: "veryfunny",
    titleSubDescription: "sad asd sad sa ",
    type: "productoLinea",
    top: "dasdasdas",
    top_mobile: "dasdas",
    subdescription: "<p>d as das das as&nbsp;</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "sadasdasd as sad as ",
        _id: "lGiv3eJCfFU6"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "asdasd",
    imageBackground: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      },
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-04-28T15:33:16.945Z",
    deleted: true,
    publish: true,
    moduleConteiner: [],
    __v: 0,
    position: [
      {
        product: 83
      }
    ]
  },
  {
    _id: "64550b9361f39ff335135be8",
    title: "Turbo product",
    titleSubDescription: "XYZ",
    type: "productoLinea",
    top: "Lorem ipsum generator",
    top_mobile: "Lorem ipsum generator",
    subdescription: "<p>XDDF</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Lorem",
        _id: "glVgRVfGwAe9"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "vbn",
    imageBackground: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-05T13:23:41.852Z",
    deleted: true,
    publish: true,
    position: [
      {
        product: 84
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "64550ed761f39ff3351370d4",
    title: "OUTTENSO",
    titleSubDescription: "dfd",
    type: "",
    top: "DFDF",
    top_mobile: "dfdfd",
    subdescription: "<p>dfdf</p>",
    benefit: [
      {
        iconBenefit: "settings",
        textBenefit: "Run",
        _id: "KWrVPdMgfnsJ"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "dgew",
    imageBackground: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-05T13:23:41.852Z",
    deleted: false,
    publish: true,
    position: [
      {
        product: 85
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "645a6cb6ac1657bb6ab194b5",
    title: "Prueba 2",
    titleSubDescription: "fgh",
    type: "productoEspecial",
    top: "descri",
    top_mobile: "descrip",
    subdescription: "<p>fgh</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "bedt",
        _id: "y7Ms335ozYup"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "poiu",
    imageBackground: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-09T15:15:24.975Z",
    deleted: true,
    publish: true,
    position: [
      {
        product: 89
      }
    ],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "645a9dfd60953594c6727094",
    title: "Prueba",
    titleSubDescription: "s das das d",
    type: "",
    top: " das das d",
    top_mobile: "asd as ",
    subdescription: "<p>a sdasd as da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "d asd sad ",
        _id: "92V3lOYDHUmw"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "s das das",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-09T18:59:35.918Z",
    deleted: false,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "646e33f2d15a820be6bd0fd0",
    title: "Prueba 123",
    titleSubDescription: "1423465",
    type: "productoLinea",
    top: "123456",
    top_mobile: "123456789",
    subdescription: "<p>erdcftgbuhynim</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "rctfvgyuhbnjimk",
        _id: "8AadMeuOYm8A"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "prueba 123456",
    imageBackground: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "637e01190031279b2466dcae",
        title: "Otros carousel hover",
        description: "Icono carousel",
        filename: "icon-otros-hover_1669202201963.svg",
        url: "http://localhost:9001/api/image/files/icon-otros-hover_1669202201963.svg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [
      {
        _id: 0.16575973424652624,
        question: "",
        answer:
          '<p>w4ertcdfgbuhyni</p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<td>&nbsp;</td>\n<td>&nbsp;</td>\n</tr>\n<tr>\n<td>&nbsp;</td>\n<td>&nbsp;</td>\n</tr>\n</tbody>\n</table>\n<p>eswzxrdctfvgyuhjbn</p>'
      },
      {
        _id: 0.4233018414334673,
        question: "",
        answer: "<p>rextcfygvuhbinjomk</p>"
      }
    ],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-23T16:04:03.475Z",
    deleted: false,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "646e33f4d15a820be6bd1070",
    title: "Prueba 123",
    titleSubDescription: "1423465",
    type: "productoLinea",
    top: "123456",
    top_mobile: "123456789",
    subdescription: "<p>erdcftgbuhynim</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "rctfvgyuhbnjimk",
        _id: "8AadMeuOYm8A"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "prueba 123456",
    imageBackground: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "637e01190031279b2466dcae",
        title: "Otros carousel hover",
        description: "Icono carousel",
        filename: "icon-otros-hover_1669202201963.svg",
        url: "http://localhost:9001/api/image/files/icon-otros-hover_1669202201963.svg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [
      {
        _id: 0.16575973424652624,
        question: "",
        answer:
          '<p>w4ertcdfgbuhyni</p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<td>&nbsp;</td>\n<td>&nbsp;</td>\n</tr>\n<tr>\n<td>&nbsp;</td>\n<td>&nbsp;</td>\n</tr>\n</tbody>\n</table>\n<p>eswzxrdctfvgyuhjbn</p>'
      },
      {
        _id: 0.4233018414334673,
        question: "",
        answer: "<p>rextcfygvuhbinjomk</p>"
      }
    ],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-23T16:04:03.475Z",
    deleted: false,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "646e33f7d15a820be6bd1110",
    title: "Prueba 12",
    titleSubDescription: "1423465",
    type: "productoLinea",
    top: "123456",
    top_mobile: "123456789",
    subdescription: "<p>erdcftgbuhynim</p>",
    benefit: [
      {
        iconBenefit: "question_answer",
        textBenefit: "rctfvgyuhbnjimk",
        _id: "8AadMeuOYm8A"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "prueba 123456",
    imageBackground: [
      {
        _id: "63b2ec876c384c93fc33ab86",
        title: "Somos Tensolite",
        description: "Slider somos Tensolite",
        filename: "somos-tensolite_02_1672670343246.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_02_1672670343246.jpg"
      }
    ],
    imageBackground_tablet: [
      {
        _id: "63b2dbc66c384c93fc33a87f",
        title: "Sección Empresa",
        description: "Tensolite sección Empresa",
        filename: "somos-tensolite_01_1672666054401.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_01_1672666054401.jpg"
      }
    ],
    imageBackground_mobile: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageProduct: [
      {
        _id: "637e01190031279b2466dcae",
        title: "Otros carousel hover",
        description: "Icono carousel",
        filename: "icon-otros-hover_1669202201963.svg",
        url: "http://localhost:9001/api/image/files/icon-otros-hover_1669202201963.svg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [
      {
        _id: 0.16575973424652624,
        question: "",
        answer:
          '<p>w4ertcdfgbuhyni</p>\n<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 50%;"><col style="width: 50%;"></colgroup>\n<tbody>\n<tr>\n<td>&nbsp;</td>\n<td>&nbsp;</td>\n</tr>\n<tr>\n<td>&nbsp;</td>\n<td>&nbsp;</td>\n</tr>\n</tbody>\n</table>\n<p>eswzxrdctfvgyuhjbn</p>'
      },
      {
        _id: 0.4233018414334673,
        question: "",
        answer: "<p>rextcfygvuhbinjomk</p>"
      }
    ],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-23T16:04:03.475Z",
    deleted: false,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "646e33fbd15a820be6bd11b0",
    title: "Carmen",
    titleSubDescription: "Subtitulo Prueba",
    type: "productoLinea",
    top: "dsadasd ",
    top_mobile: "asdas das",
    subdescription: "<p>sada sd as da</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "sadsadas",
        _id: "ZFQdPVLayGiK"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [
      {
        filename: "pdf_pedido_nro_65_.pdf",
        url: "http://localhost:9001/api/product/files/pdf_pedido_nro_65_.pdf",
        title: "Prueba 3",
        deleted: false,
        state: false
      },
      {
        filename: "pdf_pedido_nro_59_.pdf",
        url: "http://localhost:9001/api/product/files/pdf_pedido_nro_59_.pdf",
        title: "Prueba 4",
        deleted: false,
        state: false
      }
    ],
    altImageBackground: "dasdasdas",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ece96c384c93fc33ab8a",
        title: "Somos Tensolite 5",
        description: "Imagen para Somos Tensolite 5",
        filename: "somos-tensolite_05d_1672670441934.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_05d_1672670441934.jpg"
      },
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageProductAlpha: [],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-05-23T16:04:03.475Z",
    deleted: false,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "64f76b0c85014f3254157068",
    title: "New Product",
    titleSubDescription: "",
    type: "productoLinea",
    top: "Example of a new product description, edition 3",
    top_mobile: "Example of a new product description",
    subdescription: "",
    benefit: [
      {
        iconBenefit: "example",
        textBenefit: "example text",
        _id: "AQOhHk7UKgpx"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "example",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "62fcdc3db9d0590863e2558b",
        title: "T3",
        description: "tensoblock T13",
        filename: "1660738621128_T13.png",
        url: "http://localhost:9001/api/image/files/1660738621128_T13.png"
      }
    ],
    imageProductAlpha: [
      {
        _id: "62fcdc3db9d0590863e2558b",
        title: "T3",
        description: "tensoblock T13",
        filename: "1660738621128_T13.png",
        url: "http://localhost:9001/api/image/files/1660738621128_T13.png"
      }
    ],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: true,
    groupByCategory: [],
    created_date: "2023-09-05T17:53:10.676Z",
    deleted: false,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "64fb3bbc212e6a22ec0f8b9c",
    title: "new product 2",
    titleSubDescription: "",
    type: "productoLinea",
    top: "this is an examples of a product descriptions.",
    top_mobile: "this is an examples of a product descriptions.",
    subdescription: "",
    benefit: [
      {
        iconBenefit: "icon example",
        textBenefit: "text example",
        _id: "yYvphzVHyI0j"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "example",
    imageBackground: [
      {
        _id: "6453cc20fc6fa65927bdd86d",
        title: "sample",
        description: "nnn",
        filename: "sample_1683213344227.jpeg",
        url: "http://localhost:9001/api/image/files/sample_1683213344227.jpeg"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2db0f6c384c93fc33a772",
        title: "Tensolite Empresa",
        description: "Imagen para sección empresa",
        filename: "somos-tensolite_03_foto_antigua_1672665871038.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_03_foto_antigua_1672665871038.jpg"
      }
    ],
    imageProductAlpha: [
      {
        _id: "62f1315405b1dda7c213cdb7",
        title: "Bovedilla",
        description: "Bovedilla",
        filename: "1659973972782_3.jpg",
        url: "http://localhost:9001/api/image/files/1659973972782_3.jpg"
      }
    ],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-09-08T15:12:56.280Z",
    deleted: true,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "653a82e3c99e0e7cc27d40c6",
    title: "Columnas",
    titleSubDescription: "Contenido prueba",
    type: "productoEspecial",
    top: "Columnas de alumbrado publico",
    top_mobile: "Columnas de alumbrado publico",
    subdescription: "<p>Probando esta seccion</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Columnas",
        _id: "MApOwMXqCzMI"
      },
      {
        iconBenefit: "check",
        textBenefit: "Hola",
        _id: "V36Sj7y4Dc8W"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProductAlpha: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [
      {
        _id: "637e01b40031279b2466dcb4",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas_1669202356215.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas_1669202356215.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-10-26T12:06:18.208Z",
    deleted: false,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  },
  {
    _id: "653a82e5c99e0e7cc27d4215",
    title: "Columnas",
    titleSubDescription: "Contenido prueba",
    type: "productoEspecial",
    top: "Columnas de alumbrado publico",
    top_mobile: "Columnas de alumbrado publico",
    subdescription: "<p>Probando esta seccion</p>",
    benefit: [
      {
        iconBenefit: "check",
        textBenefit: "Columnas",
        _id: "MApOwMXqCzMI"
      },
      {
        iconBenefit: "check",
        textBenefit: "Hola",
        _id: "V36Sj7y4Dc8W"
      }
    ],
    instalationSteps: [],
    instalationStepsEnabled: true,
    filename: [],
    altImageBackground: "Imagen producto nuevo",
    imageBackground: [
      {
        _id: "6453cc39fc6fa65927bdd86f",
        title: "Building",
        description: "lll",
        filename: "building_1683213369004.webp",
        url: "http://localhost:9001/api/image/files/building_1683213369004.webp"
      }
    ],
    imageBackground_tablet: [],
    imageBackground_mobile: [],
    imageProduct: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      },
      {
        _id: "64243e916b8f2119a245286b",
        title: "PRobando",
        description: "Probando",
        filename: "e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif",
        url: "http://localhost:9001/api/image/files/e24cbe00-2a2c-42bb-82da-2b567668214e_1680096913905.jfif"
      }
    ],
    imageProductAlpha: [
      {
        _id: "63b2ecbd6c384c93fc33ab88",
        title: "Somos Tensolite",
        description: "Somos Tensolite imagen slider",
        filename: "somos-tensolite_04_1672670397466.jpg",
        url: "http://localhost:9001/api/image/files/somos-tensolite_04_1672670397466.jpg"
      }
    ],
    imageInstallation: [],
    imageLogo: [],
    imageLogoColor: [],
    imageIcon: [
      {
        _id: "637e01b40031279b2466dcb4",
        title: "Tribunas carousel",
        description: "Icono carousel",
        filename: "icon-tribunas_1669202356215.svg",
        url: "http://localhost:9001/api/image/files/icon-tribunas_1669202356215.svg"
      }
    ],
    imageIconColor: [],
    imageGallery: [],
    imageCarousel: [
      {
        _id: "637e02130031279b2466dcba",
        title: "Vigas carousel hover",
        description: "Icono carousel",
        filename: "icon-vigas-hover_1669202451035.svg",
        url: "http://localhost:9001/api/image/files/icon-vigas-hover_1669202451035.svg"
      }
    ],
    imageCarouselHover: [],
    galleryEnabled: true,
    faqs: [],
    subproduct: [],
    state: true,
    visible: false,
    groupByCategory: [],
    created_date: "2023-10-26T12:06:18.208Z",
    deleted: false,
    publish: true,
    position: [],
    moduleConteiner: [],
    __v: 0
  }
];
