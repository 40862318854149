import React from "react";
import { Button } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import RecursoGallery from "./RecursoGallery";

const UploadImage = ({ allImages, onNewResourceClick }) => {
  const images = allImages;

  return (
    <div className="container-upload-image">
      <div className="sec-gallery-new-resource">
        <div className="title-section">
          <div className="title-section-add">
            <h1>Nuevo recurso multimedia</h1>
          </div>
          <Button onClick={onNewResourceClick}>
            <CloseIcon />
          </Button>
        </div>
        <div className="sec-form-new-resource">
          <RecursoGallery selectedImage={null} allImages={images} closeForm={onNewResourceClick} />
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
