import React, { useEffect, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ModalSlider from './ModalSlider';
import clienteAxios from '../../config/clienteAxios';
import ConfirmModal from '../confirmModal/ConfirmModal';
import toast from 'react-hot-toast';

function SliderForm() {
  const [modal, setModal] = useState(false);
  const [sliders, setSliders] = useState([]);
  const [selectedSlider, setSelectedSlider] = useState(null);
  const [reload, setReload] = useState(true);
  const [deletingItem, setDeletingItem] = useState('');
  const [confirmModalShow, setConfirmModalShow] = useState(false);

  const spanishTranslations = {
    'about': 'Nosotros',
    'products': 'Productos',
    'works': 'Obras',
    'contact': 'Contacto'
  };

  useEffect(() => {
    clienteAxios
      .get('/sliders')
      .then(({ data }) => {
        setSliders(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [reload]);

  const handleOpenDeleteConfirm = (sliderId) => {
    setConfirmModalShow(true);
    setDeletingItem(sliderId);
  };

  const handleDelete = async () => {
    try {
      await clienteAxios.delete(`/sliders/${deletingItem}`);
      toast.success('Slider eliminado con exito!')
      setReload(!reload);
    } catch {
      toast.error('Hubo un error al eliminar el slider');
    } finally {
      setConfirmModalShow(false);
    }
  };

  const handleEdit = (sliderId) => {
    const selected = sliders.find((slider) => slider._id === sliderId);
    setSelectedSlider(selected);
    activeModal();
  };

  function activeModal() {
    setModal(!modal);
  }
  console.log(sliders)
  return (
    <div className='sliders-home'>
      <div className='title-sections'>
        <div className='title-sections-add'>
          <HelpOutlineIcon sx={{ mr: 1 }} />
          <h1>Sliders Principales</h1>
        </div>
        <button className='btn-add-modal' type='button' onClick={activeModal}>
          <AddIcon />
        </button>
        {modal && (
          <ModalSlider
            slider={selectedSlider}
            closeModalSlider={() => activeModal()}
            setReload={() => setReload(!reload)}
            setSelectedSlider={() => setSelectedSlider(null)}
          />
        )}
      </div>
      <table className='table-main'>
        <thead>
          <tr>
            <th>N°</th>
            <th>Título</th>
            <th>Subtítulo</th>
            <th>Primer título</th>
            <th>Imagen</th>
            <th>Enlace a</th>
            <th>Texto Botón</th>
          </tr>
        </thead>
        <tbody>
          {sliders.map(
            (
              { _id, title, subtitle, firstTitle, coverItem, link, linkText },
              index
            ) => (
              <tr key={_id}>
                <td>{index + 1}</td>
                <td>{title}</td>
                <td>{subtitle}</td>
                <td>{firstTitle}</td>
                <td>
                  <img src={coverItem} alt='' />
                </td>
                <td>{spanishTranslations[link]}</td>
                <td> {linkText} </td>
                <td>
                  <div className='btn-container-E-D'>
                    <button
                      className='btn-edit-deletes'
                      onClick={() => handleEdit(_id)}
                      type='button'
                    >
                      <EditOutlinedIcon />
                    </button>
                    <button
                      className='btn-edit-deletes'
                      onClick={() => handleOpenDeleteConfirm(_id)}
                      type='button'
                    >
                      <DeleteOutlineOutlinedIcon />
                    </button>
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <ConfirmModal
        handleConfirm={handleDelete}
        handleCancel={() => setConfirmModalShow(false)}
        message={'¿Estás seguro de eliminar este producto?'}
        confirmLabel={'Sí'}
        cancelLabel={'No'}
        showModal={confirmModalShow}
        handleCloseModal={() => setConfirmModalShow(false)}
        modalTitle={'Eliminar Producto'}
      />
    </div>
  );
}

export default SliderForm;
