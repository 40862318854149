import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import SliderForm from "../../components/SliderHome/SliderForm";
import FormA from './components/FormA';
import FormB from "./components/FormB";
import Skeleton from "react-loading-skeleton";
import { fetchData } from "../../utilities/fetchData";

function Index() {
  const [sectionA,setSectionA] = useState([])
  const [sectionB,setSectionB] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData(setLoading, setSectionA, "/sectionTypeA");
    fetchData(setLoading, setSectionB, "/sectionTypeB");
  }, []);

  return (
  <>
   <Navbar />
   <Sidebar />
   <div className="main-edit-works">
    <div className="container-form-edit-home">
      {loading ? (
        <Skeleton count={4} height={250} width={600} direction="ltr" />
        ) : (
        <form className="main-edit-home">
          <SliderForm/>
          {sectionA?.map(section=>(
              <FormA key={section?._id} sec={section}/>
          ))}
          <div className="sec-prod-obras">
          { sectionB?.map((section, index)=>(
            <FormB key={section?._id} sect={section} className={`sec-${index}`}/>
        ))}
          </div>
        </form>
      ) }
    </div>
   </div>
      </>
  );
}

export default Index;