import { useState } from "react";

const useBasicForm = (initialForm = {}) => {
  const [formState, setFormState] = useState(initialForm);
  const [error, setError] = useState(false);

  const onInputChange = ({ target }) => {
    const { name, value, type, checked } = target;

    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const resetForm = () => {
    setFormState(initialForm);
  };

  const setSpecificField = ({ name, value }) => {
    setFormState({
      ...formState,
      [name]: value
    });
  };

  return {
    ...formState,
    formState,
    setFormState,
    onInputChange,
    resetForm,
    error,
    setError,
    setSpecificField
  };
};

export default useBasicForm;
