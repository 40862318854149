import React from "react";
import LogoVidrial from "../assets/img/Vidrial-Logo.png";

const Footer = ({ classText }) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer-main">
      <div className="footer-container">
        <div className="container-logo">
          <img src={LogoVidrial} alt="" />
        </div>
        <div className="text-by-sitenso">
          &copy; {currentYear} Powered by Sitenso
        </div>
      </div>
    </footer>
  );
};

export default Footer;
