import React from "react";
import { toast } from "react-hot-toast";
import { ErrorOutline } from "@mui/icons-material";
import clienteAxios from "../../config/clienteAxios";

const DeleteProductsForm = ({ closeModal, product, setReload, handleCloseModal }) => {
  const handleDelete = async id => {
    try {
      await clienteAxios.delete(`/products/${id}`);

      clearForm();
    } catch (error) {
      error?.response?.data?.data?.errors?.map(error => console.log(error));
    }

    handleCloseModal();
    toast.success("Se ha eliminado el producto con éxito");
  };

  const clearForm = () => {
    setReload(prevReload => !prevReload);
    closeModal();
  };

  return (
    <div className="flex-column">
      <div className="text-center error_icon">
        <ErrorOutline />
      </div>
      <div className="text-center my-2">¿Estás seguro de eliminar este producto?</div>
      <div className="d-flex justify-content-around">
        <button className="button-success" onClick={() => handleDelete(product._id)}>
          Sí
        </button>
        <button className="button-error" onClick={() => handleCloseModal()}>
          No
        </button>
      </div>
    </div>
  );
};

export default DeleteProductsForm;
