import React, { useEffect, useState } from 'react'
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import useBasicForm from '../../hooks/useBasicForm';
import usePageProductsValidator from '../../hooks/usePageProductsValidator';
import toast from 'react-hot-toast';
import clienteAxios from '../../config/clienteAxios';
import Gallery from './../../pages/Gallery/Index';
import { WallpaperOutlined } from '@mui/icons-material';
import ImagesModal from '../imagesModal/ImagesModal';
import { fetchData } from '../../utilities/fetchData';
import Skeleton from 'react-loading-skeleton';
import { WORKS_SECTION } from '../../utilities/constants';

function MainForm() {
  const [workSectionState, setWorkSectionState] = useState({})
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const openModal = (content, title) => {
    setShowModal(true);
    setContentModal(content);
    setModalTitle(title);
  };

  const { formState, onInputChange, setFormState, setSpecificField } = useBasicForm({
    title: workSectionState.title || '',
    body: workSectionState.body ? workSectionState.body : '',
    coverItem: "imagen.jpg",
    category: "cateogiry",
  });

  const notify = () => toast.error("No se pudo completar la carga");

  useEffect(() => {
    fetchData(setLoading, setWorkSectionState, `/sectionTypeC`, WORKS_SECTION)
  }, []);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const { title, body } = workSectionState
    setFormState({ title, body })
  }, [workSectionState])

  const { errors, validateForm, onBlurField } = usePageProductsValidator(formState);

  const handleImageSelection = (selectedImagePath) => {
    setSpecificField({ name: "coverItem", value: selectedImagePath.value });
    setShowModal(false)
  };

  const clearForm = () => {
    setReload(prevReload => !prevReload);
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    const { isValid } = validateForm({
      form: formState,
      errors,
      forceTouchErrors: true
    });

    if (!isValid) return toast.error("Hay campos obligatorios sin completar");

    try {
      const response = await clienteAxios.put(`/sectionTypeC/${workSectionState?._id}`, formState);
      clearForm();
      toast.success("Se han guardado los cambios con éxito");
    } catch (error) {
      toast.error("Error al guardar los cambios");
    }

  };

  return (
    <>
      <div className="container-form-edit-works">
        {loading ? (
          <Skeleton count={2} height={250} width={600} direction="ltr" />
        ) : (

          <form className="form main-edit-works" onSubmit={handleFormSubmit}>
            <div className="submit-field main-button">
              <button type="submit" className="btn-edit-works">
                GUARDAR LOS CAMBIOS
              </button>
            </div>

            <div className="sec-page-title">
              <div className="title-sections">
                <HelpOutlineIcon sx={{ mr: 1 }} />
                <h1>Banner Superior</h1>
              </div>

              <div className="form-field">
                <h3>Título de la página</h3>
                <input type="text" placeholder="Título ejemplo" name='title' onBlur={onBlurField} onChange={onInputChange} value={formState?.title} />
              </div>

              <div className="form-field">
                <h3>Imagen de fondo</h3>
                <button
                  type="button"
                  onBlur={onBlurField}
                  onChange={onInputChange}
                  name="coverItem"
                  className={(errors.coverItem.error ? "red-warning" : "") + " btn-gallery-picker-bg"}
                  onClick={() =>
                    openModal(
                      <>
                        <Gallery
                          editProductsView={true}
                          maxQuantity={1}
                          formState={formState}
                          onInputChange={onInputChange}
                          setSpecificField={handleImageSelection}
                          flagRender={false}
                        />
                      </>,
                      "Seleccionar imágenes"
                    )
                  }
                >
                  Elegir fondo
                  <WallpaperOutlined />
                </button>
              </div>
            </div>

            <div className="sec-page-description">
              <div className="title-sections">
                <HelpOutlineIcon sx={{ mr: 1 }} />
                <h1>Descripción</h1>
              </div>
              <div className="form-field">
                <h3>* Observaciones</h3>
                <textarea
                  cols="30"
                  rows="10"
                  placeholder="Introduce la descripción de la página"
                  name='body'
                  onBlur={onBlurField}
                  onChange={onInputChange}
                  value={formState?.body}
                ></textarea>
              </div>
            </div>
            <ImagesModal
              showModal={showModal}
              handleCloseModal={handleCloseModal}
              modalTitle={modalTitle}
            >
              {contentModal}
            </ImagesModal>
          </form>
        )}
      </div>
    </>
  )
}

export default MainForm
