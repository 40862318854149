import React, { useState } from 'react';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { grey, teal } from "@mui/material/colors";

function ChangePasswordForm() {
  const [VisibilityPass, setVisibilityPass] = useState(false);

  const toggleBoton = () => {
    setVisibilityPass(!VisibilityPass);
  };
  return (
    <div className="Formulario-log-main">
      <label htmlFor="" className="label-log">
        Nueva contraseña:
        <div className="input-pass">
          <input name="password" type={VisibilityPass ? "text" : "password"} required min={8} max={255} />
          <button type="button" onClick={toggleBoton} className="btn-visibility">
            {VisibilityPass ? <RemoveRedEyeIcon sx={{ color: teal[100] }} /> : <VisibilityOffIcon sx={{ color: grey[50] }} />}
          </button>
        </div>
      </label>
      <label htmlFor="" className="label-log">
        Repetir nueva contraseña:
        <div className="input-pass">
          <input name="passwordRepeated" type={VisibilityPass ? "text" : "password"} required min={8} max={255} />
          <button type="button" onClick={toggleBoton} className="btn-visibility">
            {VisibilityPass ? <RemoveRedEyeIcon sx={{ color: teal[100] }} /> : <VisibilityOffIcon sx={{ color: grey[50] }} />}
          </button>
        </div>
      </label>
    </div>
  )
}

export default ChangePasswordForm