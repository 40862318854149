import clienteAxios from "../config/clienteAxios";

const recoveryPasswordService = async (email, domain) => {
  return await clienteAxios.post("/auth/recovery-password", {
    email,
    domain,
  });
}

const verifyUserTokenService = async (token) => {
  return await clienteAxios.get(`/auth/verify-token?token=${token}`);
}

const changePasswordService = async (email, password) => {
  return await clienteAxios.post('/auth/change-password', {
    email,
    password,
  })
}

export {
  recoveryPasswordService,
  verifyUserTokenService,
  changePasswordService
}