import React, { useState } from 'react';
import LogoVidral from '../../../assets/img/Vidrial-Logo.png';
import RecoveryPasswordForm from '../../../components/recoveryPassword/RecoveryPasswordForm/RecoveryPasswordForm';
import toast from 'react-hot-toast';
import { recoveryPasswordService } from '../../../services/recoveryPasswordService';
const domain = window.location.origin;

function RecoveryPassword() {
  const [loading, setLoading] = useState(false);

  const handleRecoveryPassword = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const form = new FormData(e.currentTarget);
      const formData = Object.fromEntries(form.entries());
      await recoveryPasswordService(formData?.email, domain);
      toast.success('Si el email existe en nuestra base de datos te llegará un correo a tu email.');
    } catch (error) {
      toast.success(`Ocurrio un error: ${error}`);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <div className='main-log'>
      <form onSubmit={handleRecoveryPassword}>
        <img src={LogoVidral} alt="" className='logo-vidrial' />
        <RecoveryPasswordForm />
        <div className='buttonLinkContainer'>
          <button className='btn-enter' type='submit' disabled={loading}>Recuperar</button>
        </div>
      </form>
    </div>
  )
}

export default RecoveryPassword