import React from 'react'

function Introduction() {
  return (
    <section className="introduction section-padding">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="txt-cont">
            <h3>Bienvenido al Panel de Gestión Interna</h3>
            <p>
                Haz click en la tarjeta de la sección que deseas editar para ingresar a la misma
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Introduction