import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useBasicForm from '../../../hooks/useBasicForm';
import SaveIcon from '@mui/icons-material/Save';
import clienteAxios from '../../../config/clienteAxios';
import toast from 'react-hot-toast';

const FormB = ({ sect, className }) => {
  const { _id, section, title, subtitle } = sect;

  const { formState, onInputChange } = useBasicForm({
    title: title ? title : '',
    subtitle: subtitle ? subtitle : '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    clienteAxios
      .put(`/sectionTypeB/${_id}`, formState)
      .then(() => {
        toast.success('Cambios guardados con exito');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className={className}>
      <div className='title-sections'>
        <div className='title-detail'>
          <HelpOutlineIcon sx={{ mr: 1 }} />
          <h1>Sección {section}</h1>
        </div>
        <button onClick={handleSubmit}>
          <SaveIcon />
        </button>
      </div>
      <h3>Título</h3>
      <input
        name='title'
        onChange={onInputChange}
        value={formState.title}
        type='text'
        placeholder='Título de ejemplo'
      />
      <h3>Subtítulo</h3>
      <input
        name='subtitle'
        onChange={onInputChange}
        value={formState.subtitle}
        type='text'
        placeholder='Título de ejemplo'
      />
    </div>
  );
};

export default FormB;
