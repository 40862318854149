import React from 'react';

function RecoveryPasswordForm() {

  return (
    <div className='Formulario-log-main'>
      <label htmlFor="" className="label-log">
        Correo:
        <input name="email" type="email" required min={3} max={255} />
      </label>
    </div>
  )
}

export default RecoveryPasswordForm